export const capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ObjectToString = (obj) => {
  let string = "";
  Object.keys(obj).forEach((k) => (string += `${k}: ${obj[k]}`));
  return string;
};

export const formatNumber = (num) => {
  const formattedNum = num.toLocaleString("es-ES", {
    style: "decimal",
    minimumFractionDigits: Number.isInteger(num) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(num) ? 0 : 2,
    useGrouping: true,
  });
  //
  return formattedNum;
};

export const formatNumberOnTyping = (number) => {
  number += "";
  number = number.replaceAll(".", ""); //.
  let x = number.split(","); //,
  let x1 = x[0];
  let x2 = x.length > 1 ? "," + x[1] : ""; //,
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2"); //.
  }
  return x1 + x2;
};
