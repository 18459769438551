import { push } from "@lagunovsky/redux-react-router";
import { call, put, takeEvery } from "redux-saga/effects";
import { authApi } from "./api";
import { authActions } from "./redux";
import { getToken } from "src/modules/common/handler/helpers";
import { colaboradorApi } from "src/modules/colaborador/handlers/api";

function* loginSaga({ payload }) {
  try {
    const data = yield call(authApi().login, {
      user: payload.user,
      password: payload.password,
      empresa: payload.empresa,
    });
    //
    yield put(authActions.setUser({ user: data }));
    // datos de usuario se obtienen de cookies
    yield put(push("/inicio"));
    yield put(authActions.loginComplete());
  } catch (error) {
    console.log({ error });
    switch (error.request.status) {
      case 401:
        yield put(authActions.loginError("Usuario y contraseña no válidas."));
        break;
      case 0:
        yield put(
          authActions.loginError(
            "Error de servidor, por favor intente más tarde"
          )
        );
        break;
      default:
        yield put(
          authActions.loginError(
            "Ha ocurrido un error. Favor intente más tarde"
          )
        );
    }
  }
}

function* getUserSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).colaborador, { id: payload });
    yield put(authActions.setUser({ user: data }));
  } catch (error) {
    console.log({ error });
  }
}

function* getEmpresasSaga({ payload }) {
  try {
    const data = yield call(authApi().getEmpresas, {
      username: payload.username,
    });
    console.log("data", data);
    yield put(authActions.setEmpresas(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* authSagas() {
  yield takeEvery(authActions.login.type, loginSaga);
  yield takeEvery(authActions.getUser.type, getUserSaga);
  yield takeEvery(authActions.getEmpresas.type, getEmpresasSaga);
}
