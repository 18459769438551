import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const inicioApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    list: async () => {
      try {
        const data = await axios.get(`${API_URL}/inicio/`, {
          headers,
        });
        return data.data;
      } catch (error) {
        console.log("Inicio error", error);
        throw error;
      }
    },
  };
};
