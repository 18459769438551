import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../handlers/redux";
import banner from "../../../images/banner_wel.jpg";
import regional from "../../../images/regional_logo.png";
import taxit from "../../../images/taxit_logo.png";
import cadiem from "../../../images/cadiem_logo.png";
import toyotoshi from "../../../images/toyotoshi_logo.png";
import bancard from "../../../images/bancard_logo.png";
import audi from "../../../images/audi_logo.png";

import { ReactComponent as Logo2 } from "../../../images/logo2.svg";

function LoginPage() {
  const dispatch = useDispatch();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [empresa, setEmpresa] = useState(0);
  const empresas = useSelector((state) => state.auth.empresas);
  const error_message = useSelector((state) => state.auth.error_message);
  const logoEmpresas = [regional, taxit, cadiem, toyotoshi, bancard, audi];

  const onLoginPress = (event) => {
    event.preventDefault();
    if (!user || !password || !empresa) return;

    dispatch(authActions.login({ user, password, empresa }));
  };

  const renderEmpresas = () => {
    return (
      <div className="flex flex-row justify-center">
        <div className="flex flex-col p-4 gap-4">
          <div className="text-2xl font-bold">
            Empresas que se sumaron al cambio
          </div>
          <div className="flex flex-row gap-2">
            {logoEmpresas.map((logo, idx) => {
              return (
                <img
                  key={`logo-${idx}`}
                  className="border rounded-lg object-center object-contain w-40 h-40 p-4"
                  src={logo}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getEmpresas = () => {
    dispatch(authActions.getEmpresas({ username: user }));
  };

  const submitIsDisabled = () => {
    return !user || !password || !empresa;
  };

  const renderForm = () => {
    return (
      <div className="flex flex-col   items-center p-6 shadow-lg rounded-lg shadow/lg bg-white gap-4">
        <div className="text-4xl text-sky-400 p-8 px-20">Iniciar sesión</div>
        <form onSubmit={onLoginPress} className={"w-full flex flex-col gap-8"}>
          <div className="flex flex-col gap-6 text-gray-600">
            <div className="input flex flex-col gap-2">
              <span className="text-sm font-bold">Usuario</span>
              <input
                className="py-2 px-2 rounded border focus:border-sky-300 focus:outline-none"
                type="text"
                placeholder=""
                value={user}
                onChange={({ target }) => setUser(target.value)}
                onBlur={getEmpresas}
              />
            </div>
            <div className="input flex flex-col gap-2">
              <span className="text-sm font-bold">Contraseña</span>
              <input
                className="py-2 px-2 rounded border focus:outline-none"
                type="password"
                placeholder=""
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
            </div>
            {empresas.length > 0 && (
              <div className="input flex flex-col gap-2">
                <span className="text-sm font-bold">Empresa</span>
                <select
                  name="empresa"
                  id="empresa"
                  className="py-2 px-2 rounded border focus:outline-none"
                  placeholder="Seleccione una empresa"
                  value={empresa}
                  onChange={({ target }) => setEmpresa(target.value)}
                >
                  {/* <option value="">Seleccione una empresa</option> */}
                  {empresas.map((empres, idx) => {
                    return (
                      <option key={`empres-${idx}`} value={empres.id}>
                        {empres.nombre}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          {!!error_message && (
            <div className="flex justify-center bg-red-300 p-2 rounded-md font-semibold">
              <div className="text-red-700 text-sm">{error_message}</div>
            </div>
          )}
          <div className="input flex flex-col ">
            <button
              disabled={submitIsDisabled()}
              type="submit"
              className={`${
                submitIsDisabled()
                  ? "cursor-not-allowed bg-opacity-50"
                  : "bg-opacity-100 hover:rounded-lg hover:duration-75 hover:bg-green-400"
              } bg-sky-500 text-white font-semibold leading-6 text-md py-2 px-4 rounded transition-all ease-linear duration-400 `}
            >
              Ingresar
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="border-b  bg-white flex flex-row items-center justify-between px-4">
        <div className="flex h-12 flex-row gap-4 items-center">
          <Logo2 className="w-8"></Logo2>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="border-t  bg-white flex flex-row items-center justify-between px-4">
        <div className="flex h-12 flex-row gap-4 items-center">
          <Logo2 className="w-8"></Logo2>
        </div>
        <div className="text-sm flex flex-col items-center justify-between">
          <div className="">
            Loffice Bulnes- Bulnes 830 c/ Avda.España, Piso 1
          </div>
          <div className="flex justify-center text-sm">
            Copyright YOICA S.A. 2017. Todos los derechos reservados.
          </div>
        </div>
        <div className="">
          <a href="mailto:contacto@yoica.com.py" target="_top">
            <span className="font-bold text-md text-zinc-800">
              Contactar a ventas
            </span>
          </a>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flex flex-col gap-4 flex-grow">
        <div
          className="flex flex-col gap-4 p-8 bg-cover"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="flex flex-row justify-center">{renderForm()}</div>
        </div>
        {renderEmpresas()}
      </div>
    );
  };

  useEffect(() => {
    if (empresas.length > 0) setEmpresa(empresas[0]?.id);
  }, [empresas]);

  return (
    <div className="relative flex flex-col h-full ">
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </div>
  );
}

export default LoginPage;
