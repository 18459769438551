import React from "react";

function Input({ className = "", ...props }) {
  return (
    <input
      className={
        className + " rounded-lg placeholder-zinc-700 border py-2 px-2"
      }
      {...props}
    />
  );
}

export default Input;
