import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
export const kpiApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    asignados: async () => {
      try {
        const asignados = await axios.get(`${API_URL}/kpi/asignados/`, {
          headers,
        });
        return asignados.data;
      } catch (error) {
        console.log("asignados error", error);
        throw error;
      }
    },
    subordinados: async ({ subordinados_filter }) => {
      const {
        nombre = "",
        areaId = "",
        sucursalId = "",
        departamentoId = "",
      } = subordinados_filter;
      try {
        const subordinados = await axios.get(
          `${API_URL}/kpi/subordinados/?search=${nombre}&area_id=${areaId}&sucursal_id=${sucursalId}&departamento_id=${departamentoId}`,
          {
            headers,
          }
        );
        return subordinados.data;
      } catch (error) {
        console.log("subordinados error", error);
        throw error;
      }
    },
    subordinado: async ({ id }) => {
      try {
        const subordinado = await axios.get(
          `${API_URL}/kpi/subordinado/${id}/detail`,
          {
            headers,
          }
        );
        return subordinado.data;
      } catch (error) {
        console.log("subordinado error", error);
        throw error;
      }
    },
    subordinadoKpisNoVigentesList: async ({ id, colaboradorKpis_filter }) => {
      const {
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = colaboradorKpis_filter;
      const { page, pageSize } = pagination;
      const endpoint = `/kpi/subordinado/${id}/no-vigente?search=${nombre}&ordering=${ordering}
      &page=${page}&page_size=${pageSize}`;
      try {
        const kpis = await axios.get(API_URL + endpoint, {
          headers,
        });
        return kpis.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },

    create: async (kpi) => {
      try {
        const response = await axios.post(API_URL + "/kpi/create", kpi, {
          headers,
        });
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, ...kpi }) => {
      try {
        const data = await axios.put(
          API_URL + `/kpi/${id}/update`,
          { ...kpi },
          { headers }
        );
        return data;
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    detail: async ({ id }) => {
      try {
        const subordinado = await axios.get(`${API_URL}/kpi/${id}/detail`, {
          headers,
        });
        return subordinado.data;
      } catch (error) {
        console.log("subordinado error", error);
        throw error;
      }
    },
    kpiColaboradorHistorial: async ({ id }) => {
      try {
        const subordinado = await axios.get(`${API_URL}/kpi/${id}/history`, {
          headers,
        });
        return subordinado.data;
      } catch (error) {
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(API_URL + `/kpi/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    anotacionList: async ({ id, anotaciones_filter }) => {
      const { search = "" } = anotaciones_filter;
      try {
        const anotaciones = await axios.get(
          `${API_URL}/kpi/${id}/anotaciones?search=${search}`,
          {
            headers,
          }
        );
        return anotaciones.data;
      } catch (error) {
        console.log("anotacion list error", error);
        throw error;
      }
    },
    anotacionCreate: async (anotacion) => {
      try {
        const response = await axios.post(
          API_URL + "/kpi/anotacion/create",
          anotacion,
          {
            headers,
          }
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    anotacionUpdate: async ({ id, ...anotacion }) => {
      console.info("anotacionUpdate api", id, anotacion);
      try {
        const data = await axios.put(
          API_URL + `/kpi/anotacion/${id}/update`,
          { ...anotacion },
          { headers }
        );
        return data;
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    anotacionDelete: async ({ id }) => {
      console.info("anotacionDelete id ", id);
      try {
        const data = await axios.delete(
          API_URL + `/kpi/anotacion/${id}/delete`,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    anotacionesDelete: async ({ anotacionesIds }) => {
      console.info("anotacionesIds api", anotacionesIds);
      try {
        const data = await axios.post(
          API_URL + `/kpi/anotacion/delete`,
          { anotaciones_ids: anotacionesIds },
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    anotacionDetail: async ({ id }) => {
      try {
        const anotacion = await axios.get(
          `${API_URL}/kpi/anotacion/${id}/detail`,
          {
            headers,
          }
        );
        return anotacion.data;
      } catch (error) {
        console.log("anotacion error", error);
        throw error;
      }
    },
    //
    metricaslist: async () => {
      try {
        const metricas = await axios.get(API_URL + "/kpi/metricas", {
          headers,
        });
        return metricas.data;
      } catch (error) {
        console.log("Error", error);
        throw error;
      }
    },
    periodoslist: async () => {
      try {
        const periodos = await axios.get(API_URL + "/kpi/periodos", {
          headers,
        });
        return periodos.data;
      } catch (error) {
        console.log("Error", error);
        throw error;
      }
    },
    //
    colaboradorKpisUpdate: async ({ id, kpis, archivados }) => {
      try {
        const response = await axios.post(
          `${API_URL}/kpi/subordinado/${id}/update-kpis`,
          { kpis: kpis, archivados: archivados },
          { headers }
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
  };
};
