import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";
import { objetivoCargoActions } from "../handlers/redux";

const LoadingTable = () => {
  return (
    <div className="animate-pulse">
      <table>
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Modificación</th>
            <th>Campo Cambiado</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Spinner animation="border" />
            </td>
            <td>
              <Spinner animation="border" />
            </td>
            <td>
              <Spinner animation="border" />
            </td>
            <td>
              <Spinner animation="border" />
            </td>
            <td>
              <Spinner animation="border" />
            </td>
          </tr>
          {/* Puedes agregar más filas según sea necesario */}
        </tbody>
      </table>
    </div>
  );
};

function ObjetivoCargoHistorial({ id: objId }) {
  const dispatch = useDispatch();
  const historyData = useSelector(
    (state) => state.objetivoCargo.objetivo_cargo_historial
  );
  const objetivo_cargo_historial_loading = useSelector(
    (state) => state.objetivoCargo.objetivo_cargo_historial_loading
  );
  useEffect(() => {
    if (!!objId)
      dispatch(objetivoCargoActions.getObjetivoCargoHistorial({ id: objId }));
  }, [objId]);
  //
  return (
    <div>
      {!objetivo_cargo_historial_loading && historyData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Modificación</th>
              <th>Campo Cambiado</th>
            </tr>
          </thead>
          <tbody>
            {historyData.map((historyItem) => (
              <tr key={historyItem.history_id}>
                <td className="p-2">{historyItem.history_user.username}</td>
                <td className="p-2">
                  {dayjs(historyItem.history_date.slice(0, 10)).format(
                    "DD/MM/YYYY"
                  )}
                </td>
                <td className="p-2">
                  {new Date(historyItem.history_date).toLocaleTimeString()}
                </td>
                <td className="p-2">{historyItem.change_type}</td>
                <td className="p-2">
                  {historyItem.changed_fields.length > 0 &&
                    Object.keys(historyItem.changed_fields[0]).map((field) => (
                      <div key={field}>
                        {`${field}: ${historyItem.changed_fields[0][field][0]} -> ${historyItem.changed_fields[0][field][1]}`}
                      </div>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!!objetivo_cargo_historial_loading && <LoadingTable />}
    </div>
  );
}

export default ObjetivoCargoHistorial;
