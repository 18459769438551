import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "src/modules/app/components/Header";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { Card } from "src/modules/common/components/Card";
import DataTable from "src/modules/common/components/DataTable";
import { beneficioActions } from "../handlers/redux";
import { useSearchParams } from "react-router-dom";
import SelectModal from "src/modules/common/components/SelectModal";
import { BeneficioDetailView } from "../components/BeneficioDetailView";
import { commonActions } from "src/modules/common/handler/redux";
import SearchInput from "src/modules/common/components/SearchInput";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import { XLg } from "react-bootstrap-icons";
import Modal from "src/modules/common/components/Modal";

const initialBeneficio = {
  id: 6,
  nombre: "",
  descripcion: "",
  cargos: [],
  colaboradores: [],
  tipo_config_beneficio: {
    id: 1,
    nombre: "Beneficio",
  },
  requisitos: [],
  vigente: true,
};

export function BeneficioPage() {
  const dispatch = useDispatch();
  const beneficios = useSelector((state) => state.beneficio.beneficios);
  const beneficios_loading = useSelector(
    (state) => state.beneficio.beneficios_loading
  );
  const beneficios_filter = useSelector(
    (state) => state.beneficio.beneficios_filter
  );
  const beneficios_count = useSelector(
    (state) => state.beneficio.beneficios_count
  );
  const colaborador =
    useSelector((state) => state.colaborador.colaborador) || {};
  const [beneficioLocal, setBeneficioLocal] = useState(initialBeneficio);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const usuario = getUserFromCookie();

  const { nombre = "" } = beneficios_filter;

  useEffect(() => {
    dispatch(colaboradorActions.colaborador(usuario.colaborador_id));
    dispatch(beneficioActions.getBeneficios({}));
  }, []);

  const onChangeText = (e) => {
    let searchText = e.target.value;
    dispatch(
      beneficioActions.setBeneficiosFilter({
        ...beneficios_filter,
        nombre: searchText,
      })
    );
  };

  const onRowClick = (id) => {
    console.log("onRowClick", id);
  };

  const getColorEstado = (vigente) => {
    return vigente ? "bg-green-500" : "bg-red-500";
  };

  const openDetallesModal = (item) => {
    console.log("openDetallesModal", item);
    setBeneficioLocal(item);
    setOpenDetailModal(true);
  };

  const getNombreCargo = () => {
    if (colaborador) {
      if (colaborador.cargo) {
        return colaborador.cargo.nombre;
      } else {
        return "Sin cargo";
      }
    }
    return "-";
  };

  const datatableConfig = {
    headers: [
      {
        title: "Agregados",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
        contentRenderer: (item) => {
          return <div className="font-semibold">{item.nombre}</div>;
        },
      },
      {
        title: "Estado",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "vigente",
        contentRenderer: (item) => {
          return (
            <div className="w-5/12">
              <div
                className={`px-2 py-1 text-sm text-white ${getColorEstado(
                  item.vigente
                )} rounded-md flex flex-row justify-center`}
              >
                {item.vigente ? "Vigente" : "No vigente"}
              </div>
            </div>
          );
        },
      },
      {
        title: "Detalles",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div>
              <button
                onClick={() => openDetallesModal(item)}
                className="border border-black rounded-md px-2 py-1"
              >
                Ver detalles
              </button>
            </div>
          );
        },
      },
    ],
    rowActions: [],
  };

  return (
    <div>
      <Header
        titulo="Beneficios"
        subtitulo="Estos son los beneficios que aplican según tu cargo"
        className={`bg-white`}
      ></Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        <div className="flex items-center gap-2">
          <h2 className="font-semibold text-lg">
            Explora los beneficios de tu cargo:
          </h2>
          <h2 className="text-sky-500 font-semibold text-lg">
            {getNombreCargo()}
          </h2>
        </div>
        <Card className=" gap-4 p-4">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="text-lg font-bold border-b w-full">
                Beneficios disponibles
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <SearchInput
                containerClass={`w-1/2`}
                placeholder={"Buscar por nombre..."}
                value={nombre}
                onChange={onChangeText}
              ></SearchInput>
            </div>
          </div>
          <div className="max-h-[400px] overflow-auto custom-scrollbar mt-4">
            <DataTable
              containerClass={""}
              data={beneficios}
              loading={beneficios_loading}
              config={datatableConfig}
              noHeaders={false}
              totalRecords={beneficios_count}
              outerPageSize={999}
              checkable={false}
              // checkedItems={cargosCheckedLocal}
              // onItemCheck={onCheckCargoLocal}
              // onCheckAll={onCheckAll}
            />
          </div>
        </Card>
      </div>
      <div>
        <Modal isOpen={openDetailModal}>
          <div className={`rounded-lg bg-white w-6/12`}>
            <div className="flex flex-col gap-3 p-4">
              <div className="flex flex-row justify-between text-lg font-bold">
                <div>{beneficioLocal.tipo_config_beneficio.nombre}</div>
                <div onClick={() => setOpenDetailModal(false)}>
                  <XLg />
                </div>
              </div>
              <div className="">
                <BeneficioDetailView beneficio={beneficioLocal} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
