import React, { useEffect } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { Card } from "src/modules/common/components/Card";
import ColaboradorRow from "src/modules/common/components/ColaboradorRow";
import Input from "src/modules/common/components/Input";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { equipoActions } from "../handlers/redux";

function EquipoPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const colaboradorNombre = location.state?.colaboradorNombre;
  const params = useParams();
  const { id: colaboradorId } = params;
  const URL_BASE = process.env.REACT_APP_URL || "http://localhost:8000";
  const equipo = useSelector((state) => state.equipo?.equipo) || {};
  const subordinados = useSelector((state) => state.equipo?.subordinados) || [];
  const subordinados_filter =
    useSelector((state) => state.equipo?.subordinados_filter) || [];
  const { nombre = "" } = subordinados_filter;
  //
  const subordinados_colaborador =
    useSelector((state) => state.equipo?.subordinados_colaborador) || [];
  const subordinados_colaborador_filter =
    useSelector((state) => state.equipo?.subordinados_colaborador_filter) || [];
  const { nombre: nombreColaborador = "" } = subordinados_colaborador_filter;

  const onChangeText = (event) => {
    dispatch(
      equipoActions.setSubordinadosFilter({ nombre: event.target.value })
    );
  };

  const onChangeTextColaborador = (event) => {
    dispatch(
      equipoActions.setSubordinadosColaboradorFilter({
        colaboradorId: colaboradorId,
        nombre: event.target.value,
      })
    );
  };

  useEffect(() => {
    if (colaboradorId) {
      // dispatch(equipoActions.equipo());
      dispatch(equipoActions.subordinadosColaborador(colaboradorId));
    } else {
      dispatch(equipoActions.equipo());
      dispatch(equipoActions.subordinados());
    }
  }, []);

  const toDetail = (id) => {
    navigate(`/equipo/${id}`);
  };

  return (
    <>
      <Header
        titulo={"Mi equipo"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
      ></Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        {!colaboradorId && (
          <div className="flex flex-col gap-2 ">
            <div className="text-lg font-bold">Tu jefe directo:</div>
            <div className="flex flex-row gap-2  items-center">
              {!equipo.jefe_directo?.foto_empleado && (
                <ProfileImage className="h-12 w-12 p-2"></ProfileImage>
              )}

              {!!equipo.jefe_directo?.foto_empleado && (
                <div className="w-12 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-12 w-12 rounded-md"
                    src={equipo.jefe_directo?.foto_empleado}
                    alt={`Foto Jefe`}
                  />
                </div>
              )}
              <div className="flex flex-col flex-grow text-zinc-700">
                <div className="text-sm font-bold">
                  {equipo.jefe_directo &&
                    `${equipo.jefe_directo?.apellido}, ${equipo.jefe_directo?.nombre}`}
                  {!equipo.jefe_directo && `Sin especificar`}
                </div>
                <div className="text-sm">
                  Cargo:{" "}
                  {equipo.jefe_directo?.cargo?.nombre || "Sin especificar"}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* MI EQUIPO */}
        {!colaboradorId && (
          <Card className=" gap-4 p-4">
            <div className="flex flex-col gap-2">
              <div className="text-lg font-bold">Tu equipo</div>
              <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
            </div>
            <div className="flex flex-col gap-2">
              {/* <div className="text-lg font-bold">Histórico</div> */}
              <div>
                <div className="border-b bg-zinc-400"></div>
                {subordinados.length > 0 &&
                  subordinados.map((subordinado, idx) => (
                    <ColaboradorRow
                      key={`subordinado-${idx}`}
                      colaborador={subordinado}
                      onClick={() => {
                        toDetail(subordinado.id);
                      }}
                    ></ColaboradorRow>
                  ))}
                {subordinados.length < 1 && (
                  <div className="w-full text-center p-4">
                    No tiene colaboradores asignados.
                  </div>
                )}
              </div>
            </div>
          </Card>
        )}
        {/* EQUIPO DE UN COLABORADOR */}
        {colaboradorId && (
          <Card className=" gap-4 p-4">
            <div className="flex flex-col gap-2">
              <div className="text-lg ">
                <span className="font-bold">Equipo de </span>
                <span className="">{colaboradorNombre}</span>
              </div>

              <BuscarInput
                onChange={onChangeTextColaborador}
                value={nombreColaborador}
              ></BuscarInput>
            </div>
            <div className="flex flex-col gap-2">
              {/* <div className="text-lg font-bold">Histórico</div> */}
              <div>
                <div className="border-b bg-zinc-400"></div>
                {subordinados_colaborador.length > 0 &&
                  subordinados_colaborador.map((subordinado, idx) => (
                    <ColaboradorRow
                      key={`subordinado-${idx}`}
                      colaborador={subordinado}
                      onClick={() => {
                        toDetail(subordinado.id);
                      }}
                    ></ColaboradorRow>
                  ))}
                {subordinados_colaborador.length < 1 && (
                  <div className="w-full text-center p-4">
                    No tiene colaboradores asignados.
                  </div>
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

export default EquipoPage;
