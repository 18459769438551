import React from "react";
import { XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Modal from "src/modules/common/components/Modal";
import { commonActions } from "../handler/redux";

function SelectModal({ content = <></>, title = "", className = "", onClose }) {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.common.select_modal);
  const setOpen = (open) => {
    dispatch(commonActions.setSelectModal({ open }));
  };

  const onCloseLocal = () => {
    setOpen(false)
    if (!!onClose && typeof onClose === "function") {
      let shouldClose = onClose(); //igual se ejecutará onClose por más que no retorne nada
      if(shouldClose === false){ //que solo se ejecute cuando es false y no cuando es undefined
        setOpen(true)
      }
    }
  }

  return (
    <Modal isOpen={modal.open}>
      <div className={`rounded-lg bg-white w-10/12 ${className}`}>
        <div className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>{title}</div>
            <div onClick={onCloseLocal}>
              <XLg />
            </div>
          </div>
          <div className="">{content}</div>
        </div>
      </div>
    </Modal>
  );
}

export default SelectModal;
