import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import FormularioCalificaciones from "../components/FormularioCalificaciones";
import { evaluacionActions } from "../handlers/redux";
import {
  ESTADOS_EVALUACION,
  ESTADOS_FORMULARIO,
  ETAPAS,
  ETAPA_POR_ESTADOS,
  getEtapaAnteriorOSiguiente,
  getPuntuacionesCustom,
} from "../helpers/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function FormularioComentarioPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const ETAPA = ETAPAS.COMENTARIO;
  const usuario = getUserFromCookie();
  const { idEvaluacion, idFormulario } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector(
    (state) => state.evaluacion.formulario_loading
  );
  const getUltimaEtapa = () => {
    const {
      tiene_calibracion_superior,
      tiene_devolucion,
      tiene_comentarios_evaluado,
    } = evaluacion;
    let ultimaEtapa = 1;
    //
    if (formulario.relacion === "Jefe directo") {
      if (tiene_devolucion) {
        ultimaEtapa = 3;
      } else if (tiene_calibracion_superior) {
        ultimaEtapa = 2;
      }
    }
    //
    return ultimaEtapa;
  };
  const etapa = getUltimaEtapa();
  const modificarComentario = (field) => (e) => {
    const comentario = e.target.value;
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        [field]: comentario,
      })
    );
  };
  const onSubmit = () => {
    const { comentarios_evaluado } = formulario;
    if (!comentarios_evaluado) {
      ErrorToast({ message: "El colaborador debe agregar un comentario." });
      return;
    }
    console.log("submit comentario", comentarios_evaluado);
    const data = {
      comentarios_evaluado: comentarios_evaluado,
    };
    //
    dispatch(evaluacionActions.comentar(data));
  };
  const getInputComentarios = () => {
    const { tiene_calibracion_superior, tiene_devolucion } = evaluacion;
    let inputComentarios = {
      comentarios_evaluador: { show: true, editable: false },
      comentarios_calibrador: {
        show: tiene_calibracion_superior,
        editable: false,
      },
      plan_accion: { show: tiene_devolucion, editable: false },
      comentarios_evaluado: { show: true, editable: true },
    };
    const { comentarios_calibrador, plan_accion } = formulario;
    //
    if (!comentarios_calibrador) {
      inputComentarios.comentarios_calibrador.show = false;
    }
    if (!plan_accion) {
      inputComentarios.plan_accion.show = false;
    }
    return inputComentarios;
  };
  const sePuedeEditar = () => {
    const { estado: estadoEvaluacion } = evaluacion;
    if (estadoEvaluacion?.value === ESTADOS_EVALUACION.FINALIZADO) {
      return false;
    }
    const estado = formulario.estado.value;
    const { nombre, apellido, colaborador_id } = formulario.evaluado;
    // const nombreEvaluado = `${nombre} ${apellido}`;
    const usuarioColaboradorId = Number(usuario?.colaborador_id);
    // const esUsuarioEvaluado = usuario?.nombre_completo === nombreEvaluado;
    const esUsuarioEvaluado = usuarioColaboradorId === colaborador_id;
    const etapaAnterior = getEtapaAnteriorOSiguiente(
      evaluacion,
      ETAPA,
      "anterior",
      formulario
    );
    const estadoHabilitado = ETAPA_POR_ESTADOS[etapaAnterior];
    const estadoEditable =
      estado >= estadoHabilitado && estado <= ESTADOS_FORMULARIO.COMENTADO;
    return estadoEditable && esUsuarioEvaluado;
  };
  //
  useEffect(() => {
    console.log("FECom useEffect ");
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
  }, []);
  //
  return (
    <div className="flex flex-col gap-4 mt-4 px-8">
      <div>
        <div className="border-b pb-3">
          <span className="font-bold text-sky-500 text-xl">Etapa:</span>{" "}
          <span className="text-xl">Comentario</span>
        </div>
      </div>
      {/*  */}
      {!formulario_loading && (
        <>
          {Object.keys(formulario).length > 0 && (
            <FormularioCalificaciones
              formulario={formulario}
              etapa={etapa}
              inputComentarios={getInputComentarios()}
              modificarComentario={modificarComentario}
              disabledFields={["indicadores"]}
              onSubmit={onSubmit}
              verAnotaciones={false}
              esEditable={sePuedeEditar()}
              submitButtonLabel={"Finalizar comentarios"}
              puntuacionesCustom={getPuntuacionesCustom(evaluacion)}
              mostrarPesosIndicadores={
                evaluacion?.visualizar_pesos_indicadores_observacion
              }
            ></FormularioCalificaciones>
          )}
          {Object.keys(formulario).length < 1 && (
            <div className="flex justify-center border rounded-md p-2">
              No existe formulario
            </div>
          )}
        </>
      )}
      {!!formulario_loading && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando formulario, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
    </div>
  );
}
export default FormularioComentarioPage;
