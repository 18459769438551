import { createSlice } from "@reduxjs/toolkit";

const inicioSlice = createSlice({
  name: "Inicio",
  initialState: {
    inicios: [],
    inicios_loading: false,
  },
  reducers: {
    setInicios: (state, { payload }) => {
      state.inicios = payload.inicios;
      state.inicios_loading = false;
    },
    // list
    inicios: (state, { payload }) => {
      state.inicios_loading = true;
    },
  },
});

export const inicioActions = inicioSlice.actions;
export const inicioReducer = inicioSlice.reducer;
