import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FeedbackComentarios } from "../components/FeedbackComentarios";

export function FeedbackEtapaColaborador() {
  const params = useParams();
  const { idFeedback, idJefeDirecto, idColaborador } = params;

  const feedback_nomina = useSelector(
    (state) => state.feedback.feedback_nomina
  );
  const feedback_nomina_loading = useSelector(
    (state) => state.feedback.feedback_nomina_loading
  );
  const jefe_directo = useSelector((state) => state.feedback.jefe_directo);
  const jefe_directo_loading = useSelector(
    (state) => state.feedback.jefe_directo_loading
  );
  const colaborador = useSelector(
    (state) => state.feedback.colaborador_feedback
  );
  const colaborador_loading = useSelector(
    (state) => state.feedback.colaborador_feedback_loading
  );

  const { solicitante } = feedback_nomina;
  const tiene_permiso = solicitante === "colaborador";
  const estado = feedback_nomina.estado ? feedback_nomina.estado : 1;

  return (
    <div className="my-4">
      <FeedbackComentarios
        jefe_directo={jefe_directo}
        colaborador={colaborador}
        identificadores={{ idFeedback, idJefeDirecto, idColaborador }}
        editJefeDirecto={false}
        editColaborador={tiene_permiso && estado < 3}
        commentColaboradorView={true}
        buttonDeleteView={false}
        canSave={tiene_permiso && estado < 3} // Solo se pueden enviar feedback si la etapa no está finalizada
        etapa={2}
      />
    </div>
  );
}
