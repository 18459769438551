import { getToken } from "src/modules/common/handler/helpers";
import { beneficioActions } from "./redux";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { beneficioApis } from "./apis";

function* beneficioSaga() {
  const token = getToken();
  const beneficios_filter = yield select(
    (state) => state.beneficio.beneficios_filter
  );
  try {
    const data = yield call(beneficioApis(token).list, beneficios_filter);
    yield put(beneficioActions.setBeneficios(data.results));
    yield put(beneficioActions.setBeneficiosCount(data.count));
  } catch (error) {
    console.log(error);
    yield put(beneficioActions.setBeneficios([]));
    ErrorToast({ message: "Ocurrió un error" });
  }
}

export function* beneficiosSagas() {
  yield takeEvery(beneficioActions.getBeneficios.type, beneficioSaga);
  yield takeLatest(beneficioActions.setBeneficiosFilter.type, beneficioSaga);
}
