import React from "react";
import ColaboradoresPage from "src/modules/colaborador/pages/ColaboradoresPage";
import ColaboradorDetailPage from "../pages/ColaboradorDetailPage";

export const colaboradorRoutes = [
  {
    path: "/colaboradores/",
    element: <ColaboradoresPage />,
  },
  {
    path: "/colaboradores/:id",
    element: <ColaboradorDetailPage />,
  },
];
