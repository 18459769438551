import { createSlice } from "@reduxjs/toolkit";

const colaboradorSlice = createSlice({
  name: "colaborador",
  initialState: {
    colaboradores: { result: [] },
    colaboradores_filter: {},
    colaboradores_loading: false,
    colaborador: {},
    colaborador_loading: false,
    //
    nuevos_ingresos: { result: [] },
    nuevos_ingresos_loading: false,
    areas: { result: [] },
    sucursales: { result: [] },
    departamentos: { result: [] },
    areas_loading: false,
    sucursales_loading: false,
    departamentos_loading: false,
    //
    colaborador_evaluaciones: [],
    colaborador_evaluaciones_filter: {},
    colaborador_evaluaciones_loading: false,
    anhos_evaluaciones: [],
    //
    empresa: {},
    empresa_loading: false,
  },
  reducers: {
    getEmpresa: (state, { payload }) => {
      state.empresa_loading = true;
    },
    setEmpresa: (state, { payload }) => {
      state.empresa = payload;
    },
    setEmpresaComplete: (state, { payload }) => {
      state.empresa_loading = false;
    },
    colaboradores: (state, { payload }) => {
      state.colaboradores_loading = true;
    },
    setColaboradores: (state, { payload }) => {
      state.colaboradores = payload.results;
    },
    setColaboradoresFilter: (state, { payload = {} }) => {
      state.colaboradores_filter = payload;
    },
    //
    getNuevosIngresos: (state, { payload }) => {
      state.nuevos_ingresos_loading = true;
    },
    setNuevosIngresos: (state, { payload }) => {
      state.nuevos_ingresos = payload.results;
      state.nuevos_ingresos_loading = false;
    },
    //
    colaborador: (state, { payload }) => {
      console.info("colaborador redux", payload);
      state.colaborador_loading = true;
    },
    setColaborador: (state, { payload }) => {
      state.colaborador = payload;
      state.colaborador_loading = false;
    },
    colaboradorUpdate: (state, { payload = {} }) => {
      state.colaborador_loading = true;
    },
    colaboradorUpdateSuccess: (state) => {
      state.colaborador_loading = false;
    },
    //
    areas: (state, { payload }) => {
      state.areas_loading = true;
    },
    sucursales: (state, { payload }) => {
      state.sucursales_loading = true;
    },
    departamentos: (state, { payload }) => {
      state.departamentos_loading = true;
    },
    setAreas: (state, { payload }) => {
      state.areas = payload.results;
    },
    setSucursales: (state, { payload }) => {
      state.sucursales = payload.results;
    },
    setDepartamentos: (state, { payload }) => {
      state.departamentos = payload.results;
    },
    //
    getColaboradorEvaluaciones: (state, { payload = [] }) => {
      state.colaborador_evaluaciones_loading = true;
    },
    setColaboradorEvaluaciones: (state, { payload = [] }) => {
      state.colaborador_evaluaciones = payload;
    },
    setAnhosEvaluaciones: (state, { payload = [] }) => {
      state.anhos_evaluaciones = payload;
    },
    setColaboradorEvaluacionesFilter: (state, { payload = [] }) => {
      state.colaborador_evaluaciones_filter = payload;
      state.colaborador_evaluaciones_loading = true;
    },
    setColaboradorEvaluacionesComplete: (state) => {
      state.colaborador_evaluaciones_loading = false;
    },
  },
});

export const colaboradorActions = colaboradorSlice.actions;
export const colaboradorReducer = colaboradorSlice.reducer;
