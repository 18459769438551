import dayjs from "dayjs";
import { useSelector } from "react-redux";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { ETAPAS, getUltimaEtapaEvaluacion } from "../helpers/helpers";

function DatosPuntajes({
  formulario = {},
  mostrarPuntajeCalibracion = true,
  puntajeEvaluadorKey = "puntaje_obtenido",
}) {
  const { evaluado = {}, evaluador = {}, calibrador = {} } = formulario;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const { clasificaciones = [] } = evaluacion;
  const getClasificacion = (puntaje) => {
    let clasificacion = "Sin definir";
    const clasificacionObj = clasificaciones.find(
      (clasif, cIdx) =>
        puntaje >= clasif.puntaje_desde && puntaje <= clasif.puntaje_hasta
    );
    //
    if (!!clasificacionObj && Object.keys(clasificacionObj).length > 0) {
      clasificacion = clasificacionObj.nombre;
    }
    return clasificacion;
  };
  const getFechaEvaluacion = () => {
    const ultimaEtapa = getUltimaEtapaEvaluacion(evaluacion);
    let ultimaEvaluacionFechaKey = "";
    switch (ultimaEtapa) {
      case ETAPAS.EVALUACION:
        ultimaEvaluacionFechaKey = "fecha_evaluacion";
        break;
      case ETAPAS.CALIBRACION:
        ultimaEvaluacionFechaKey = "fecha_calibracion";
        break;
      case ETAPAS.DEVOLUCION:
        ultimaEvaluacionFechaKey = "fecha_devolucion";
        break;
      default:
        ultimaEvaluacionFechaKey = "fecha_evaluacion";
    }
    const fechas = {
      puntaje_evaluacion: formulario.fecha_evaluacion,
      puntaje_devolucion: formulario.fecha_devolucion,
      puntaje_obtenido: formulario[ultimaEvaluacionFechaKey],
    };
    const fechaFormateada = !!fechas[puntajeEvaluadorKey]
      ? dayjs(fechas[puntajeEvaluadorKey]?.slice(0, 10)).format("DD/MM/YYYY")
      : null;
    //
    return fechaFormateada || "Sin fecha";
  };
  const getRelacionColor = (relacion) => {
    let relacionColor = "bg-slate-200 text-black";
    const colores = {
      "Jefe directo": "bg-red-500",
      Par: "bg-orange-400",
      Subalterno: "bg-sky-500",
      Autoevaluación: "bg-black",
    };
    if (colores[relacion]) return colores[relacion];
    return relacionColor;
  };
  //
  return (
    <div className="flex gap-4 w-full">
      {/* Datos evaluado/evaluadores */}
      <div className="flex flex-colx flex-1">
        <div className="flex flex-1x gap-4 border rounded-md p-4">
          <div className="flex flex-col gap-4 justify-between border-2x">
            <div className="flex gap-5">
              {/* Colaborador */}
              <div className="flex flex-col gap-2">
                {!evaluado.foto_empleado && (
                  <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
                )}
                {!!evaluado.foto_empleado && (
                  <div className="w-28 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-28 w-28 rounded-md"
                      src={evaluado.foto_empleado}
                      alt={`Foto colaborador`}
                    />
                  </div>
                )}
                <h3 className="font-bold text-sm mt-2">Colaborador</h3>
                <div className="">
                  <div className="text-sm text-slate-600">
                    {`${formulario.evaluado?.nombre} ${formulario.evaluado?.apellido}`}
                  </div>
                  <div className="text-sm font-semibold text-slate-600">
                    {formulario.evaluado?.cargo?.nombre || "Sin cargo"}
                  </div>
                </div>
              </div>
              {/* Evaluadores */}
              <div className="flex flex-col gap-3">
                {/* evaluador */}
                <div className="flex flex-col gap-2">
                  <h3 className="font-bold text-sm">Evaluador asignado:</h3>
                  <div className="flex gap-2">
                    {!evaluador.foto_empleado && (
                      <ProfileImage className="h-10 w-10 p-2"></ProfileImage>
                    )}
                    {!!evaluador.foto_empleado && (
                      <div className="w-10 bg-slate-200 rounded-md">
                        <img
                          className="object-cover h-10 w-10 rounded-md"
                          src={evaluador.foto_empleado}
                          alt={`Foto colaborador`}
                        />
                      </div>
                    )}
                    <div>
                      <div className="text-sm text-slate-600">
                        {`${formulario.evaluador?.nombre} ${formulario.evaluador?.apellido}`}
                      </div>
                      <div className="text-sm font-semibold text-slate-600">
                        {formulario.evaluador?.cargo?.nombre || "Sin cargo"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <span
                      className={`${getRelacionColor(
                        formulario.relacion
                      )} rounded-md text-xs px-2 py-1 text-white`}
                    >
                      {formulario.relacion}
                    </span>
                  </div>
                </div>
                {/* calibrador */}
                {!!formulario.calibrador && (
                  <div>
                    <h3 className="font-bold mb-2 text-sm">
                      Calibrador asignado:
                    </h3>
                    <div className="flex gap-2">
                      {!calibrador.foto_empleado && (
                        <ProfileImage className="h-10 w-10 p-2"></ProfileImage>
                      )}
                      {!!calibrador.foto_empleado && (
                        <div className="w-10 bg-slate-200 rounded-md">
                          <img
                            className="object-cover h-10 w-10 rounded-md"
                            src={calibrador.foto_empleado}
                            alt={`Foto colaborador`}
                          />
                        </div>
                      )}
                      <div>
                        <div className="text-sm text-slate-600">
                          {`${formulario.calibrador?.nombre} ${formulario.calibrador?.apellido}`}
                        </div>
                        <div className="text-sm font-semibold text-slate-600">
                          {formulario.calibrador?.cargo?.nombre || "Sin cargo"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="text-xs">
              Si usted no es el evaluador o presenta algún error, notificar a su
              Jefe Directo o Gerente de RRHH.
            </div>
          </div>
        </div>
      </div>
      {/* Puntajes */}
      <div className="flex flex-col flex-1">
        <div className="flex flex-1 gap-4">
          {/* Evaluador */}
          <div className="flex flex-col flex-1x border rounded-md p-4">
            <div>
              <h3 className="text-sm font-bold">Puntaje evaluador</h3>
              <div className="text-xs">{getFechaEvaluacion()}</div>
            </div>
            <PercentageCircular
              strokeWidth={9}
              strokeColor={"blue"}
              value={formulario[puntajeEvaluadorKey]}
              content={
                <>
                  <div className="font-bold text-2xl">
                    {formulario[puntajeEvaluadorKey] || 0}%
                  </div>
                  <div className="text-sm text-red-400">
                    {getClasificacion(formulario[puntajeEvaluadorKey])}
                  </div>
                </>
              }
            ></PercentageCircular>
          </div>
          {/* Calibrador */}
          {!!mostrarPuntajeCalibracion && (
            <div className="flex flex-col border rounded-md p-4">
              <div>
                <h3 className="text-sm font-bold">Puntaje calibrador</h3>
                <div className="text-xs">
                  {formulario.fecha_calibracion
                    ? dayjs(formulario.fecha_calibracion?.slice(0, 10)).format(
                        "DD/MM/YYYY"
                      )
                    : "Sin fecha"}
                </div>
              </div>
              <PercentageCircular
                strokeWidth={9}
                strokeColor={"yellow"}
                value={formulario.puntaje_calibracion}
                content={
                  <>
                    <div className="font-bold text-2xl">
                      {" "}
                      {formulario.puntaje_calibracion || 0}%
                    </div>
                    <div className="text-sm text-red-400">
                      {getClasificacion(formulario.puntaje_calibracion || 0)}
                    </div>
                  </>
                }
              ></PercentageCircular>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default DatosPuntajes;
