import { useEffect, useState } from "react";
import { ChevronCompactRight } from "react-bootstrap-icons/dist";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import DataTable from "src/modules/common/components/DataTable";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import SearchInput from "src/modules/common/components/SearchInput";
import SelectModal from "src/modules/common/components/SelectModal";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import { commonActions } from "src/modules/common/handler/redux";
import RequisitoForm from "../components/RequisitoForm";
import { planCarreraActions } from "../handlers/redux";
import { FINTECH_ID } from "../helpers/constants";

function PlanCarreraHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const usuario = getUserFromCookie();
  const user = useSelector((state) => state.auth.user);
  //
  const plan_carreras = useSelector(
    (state) => state.plan_carrera.plan_carreras
  );
  const plan_carreras_loading = useSelector(
    (state) => state.plan_carrera.plan_carreras_loading
  );
  const plan_carreras_filter = useSelector(
    (state) => state.plan_carrera.plan_carreras_filter
  );
  const plan_carreras_count = useSelector(
    (state) => state.plan_carrera.plan_carreras_count
  );
  //
  const cargos = useSelector((state) => state.plan_carrera.cargos);
  const cargos_loading = useSelector(
    (state) => state.plan_carrera.cargos_loading
  );
  const cargos_filter = useSelector(
    (state) => state.plan_carrera.cargos_filter
  );
  const { nombre = "" } = cargos_filter;
  const cargos_count = useSelector((state) => state.plan_carrera.cargos_count);
  //
  const [cargoForm, setCargoForm] = useState({});
  const openRequisitosModal = (cargo) => () => {
    setCargoForm(cargo);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const closeRequisitosModal = () => {
    setCargoForm({});
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
      },
      {
        title: "Requisitos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        contentRenderer: (item) => {
          return (
            <div>
              {item.cantidad_requisitos > 0 && (
                <button
                  onClick={openRequisitosModal(item)}
                  className="border border-black rounded-md px-2 py-1"
                >
                  Ver requisitos
                </button>
              )}
              {item.cantidad_requisitos < 1 && (
                <button
                  onClick={openRequisitosModal(item)}
                  className="bg-red-500 text-white rounded-md p-1"
                >
                  Sin definir
                </button>
              )}
            </div>
          );
        },
      },
    ],
    rowActions: [
      //   {
      //     label: "Borrar requisitos",
      //     action: (item) => {
      //       borrarRequisitosCargo(item.id);
      //     },
      //   },
    ],
  };
  const onGoPlanDetalle = (plan) => () => {
    navigate(`/plan-carrera/${plan.id}`);
  };
  const onSearchCargo = (e) => {
    let searchText = e.target.value;
    dispatch(
      planCarreraActions.setCargosFilter({
        ...cargos_filter,
        nombre: searchText,
      })
    );
  };
  const [outerPageSize, setOuterPageSize] = useState(50);
  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      planCarreraActions.setCargosFilter({
        ...cargos_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSize(parseInt(value));
  };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      planCarreraActions.setCargosFilter({
        ...cargos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  //
  useEffect(() => {
    dispatch(planCarreraActions.getPlanCarreras());
    dispatch(planCarreraActions.getCargos());
  }, []);
  //
  if (parseInt(usuario.empresa_id) === FINTECH_ID) {
    return <div>No tiene los permisos necesarios</div>;
  }
  return (
    <div>
      <Header
        titulo={"Plan de carrera"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
      ></Header>
      <div className="px-12">
        <h3 className="font-semibold text-lg mt-4">
          Explora las oportunidades de crecimiento de la empresa
        </h3>
        <Card className="mt-4">
          <h3 className="font-semibold text-lg mt-4">Planes de carrera</h3>
          <div className="w-full px-6">
            <table className="w-full">
              <tbody>
                {!plan_carreras_loading &&
                  plan_carreras?.length > 0 &&
                  plan_carreras?.map((plan, pidx) => (
                    <tr
                      onClick={onGoPlanDetalle(plan)}
                      className="hover:bg-slate-100 hover:cursor-pointer"
                    >
                      <td className="border-b py-8 pl-4">
                        <div className="flex flex-col justify-center">
                          <span className="font-semibold">{plan.nombre}</span>
                          <span>{plan.descripcion}</span>
                        </div>
                      </td>
                      <td className="border-b">
                        {!!plan.se_encuentra && (
                          <div className="flex flex-row items-center gap-3">
                            <div className="pl-6">
                              {!user?.foto_empleado && (
                                <ProfileImage className="h-10 w-10 p-2"></ProfileImage>
                              )}

                              {!!user?.foto_empleado && (
                                <div className="w-10 bg-slate-200 rounded-md">
                                  <img
                                    className="object-cover h-10 w-10 rounded-md"
                                    src={user?.foto_empleado}
                                    alt={`Foto Jefe de Lider`}
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              <div className="font-semibold">{`${usuario?.nombre}`}</div>
                              <div className="text-sm">Te encuentras aquí</div>
                            </div>
                          </div>
                        )}
                      </td>
                      <td className="text-right border-b">
                        <div className="flex justify-end items-center">
                          <ChevronCompactRight color={"#0EA5E9"} size={21} />
                        </div>
                      </td>
                    </tr>
                  ))}
                {!plan_carreras_loading &&
                  plan_carreras.length < 1 &&
                  [1].map((plan, pidx) => (
                    <tr className="hover:bg-slate-100 hover:cursor-pointer">
                      <td className="border-b py-8 pl-4 w-1/3"></td>
                      <td className="border-b w-1/3">
                        Sin registros para mostrar
                      </td>
                      <td className="text-right border-b w-1/3"></td>
                    </tr>
                  ))}
                {!!plan_carreras_loading &&
                  [1, 2, 3].map(() => (
                    <tr className="animate-pulse">
                      <td className="py-8 pl-4">
                        <div className="flex flex-col justify-center gap-4">
                          <span className="w-24 h-2 bg-slate-200 rounded-md"></span>
                          <span className="w-20 h-2 bg-slate-200 rounded-md"></span>
                        </div>
                      </td>
                      <td></td>
                      <td>
                        <div className="flex justify-end items-center">
                          <div className="w-4 h-2 bg-slate-200 rounded-md"></div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Card>
        {/*  */}
        <Card className="mt-4">
          <h3 className="font-semibold text-lg mt-4">Cargos de la empresa</h3>
          <div className="flex gap-2 items-center">
            <SearchInput
              containerClass={`w-1/2`}
              placeholder={"Buscar por nombre..."}
              value={nombre}
              onChange={onSearchCargo}
            />
            <MostrarPaginas
              containerClass={"w-1/2x"}
              onChangePageSize={onChangePageSize}
            />
          </div>
          {/*  */}
          <DataTable
            containerClass={"my-8"}
            height="h-[400px]"
            data={cargos}
            loading={cargos_loading}
            config={datatableConfig}
            noHeaders={false}
            // pagination
            totalRecords={cargos_count}
            onPaginate={onPaginate}
            outerPageSize={outerPageSize}
            // // check config
            checkable={false}
            // onCheckAll={onCheckAll}
            // checkedItems={cargos_checked}
            // onItemCheck={onCheckCargo}
            // onRowClick={onRowClick}
          />
        </Card>
      </div>
      {/*  */}
      <SelectModal
        extraClass={`w-8/12 md:w-6/12`}
        title={`${cargoForm.nombre}`}
        content={
          <RequisitoForm
            cargo={cargoForm}
            onFinalizar={closeRequisitosModal}
            readOnly={true}
            // afterSubmit={setNextCargo}
          />
        }
      />
    </div>
  );
}
export default PlanCarreraHome;
