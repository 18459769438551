import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import { Card } from "src/modules/common/components/Card";
import { kpiActions } from "../../kpi/handlers/redux";
import { ProfileImage } from "../components/ProfileImage";
import { ArrowRight, Check, Crop, Search, X } from "react-bootstrap-icons";
import { KpiInfo } from "src/modules/kpi/components/KpiInfo";
import ImageCropper from "../components/ImageCropper";
import ImageInput from "../components/ImageInput";
import ImageUploadAlt from "../components/ImageUploadAlt";
import dataURLtoFile, { getUserFromCookie } from "../handler/helpers";
import BarChart from "../components/BarChart";
import PercentageCircular from "../components/PercentageCircular";
import { formatDate } from "../helpers/helpers";
import SkeletonLoaderRow from "../components/SkeletonLoaderRow";
import HistorialEvaluacionesHeader from "../components/HistorialEvaluacionesHeader";
import HistorialEvaluacionesTable from "../components/HistorialEvaluacionesTable";
import { authActions } from "src/modules/auth/handlers/redux";
import { equipoActions } from "src/modules/equipo/handlers/redux";
import { formatNumber } from "src/utils/helpers";
import backButton from "src/modules/common/components/images/back-arrow.png";

function ProfilePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id: colaboradorId } = params;
  const usuario = getUserFromCookie();
  const {
    permiso_kpi = true,
    permiso_objetivo = true,
    permiso_evaluacion = true,
    permiso_plan_carrera = true,
    permiso_beneficio = true,
    permiso_escala_salarial = true,
    permiso_feedback = true,
  } = usuario;
  const [kpisSubordinado, setKpisSubordinado] = useState([]);
  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const colaborador_loading = useSelector(
    (state) => state.colaborador.colaborador_loading
  );
  //kpis_colaborador
  const subordinado = useSelector((state) => state.kpi.subordinado);

  const kpisAsignados = useSelector((state) => state.kpi.kpis) || [];
  const {
    promedio_evaluaciones = 0,
    ultima_evaluacion = {},
    promedios_evaluaciones = [],
    salario,
  } = colaborador;
  //
  const colaborador_evaluaciones = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones
  );
  const colaborador_evaluaciones_filter = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones_filter
  );
  const anhos_evaluaciones = useSelector(
    (state) => state.colaborador.anhos_evaluaciones
  );
  const { nombre: nombreEvaluacion, ordering } =
    colaborador_evaluaciones_filter;
  const colaborador_evaluaciones_loading = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones_loading
  );
  //
  const esVistaSubordinado = () => {
    return !!colaboradorId;
  };
  const onChangeEvaluacionText = (event) => {
    const colaboradorId = usuario.colaborador_id;
    dispatch(
      colaboradorActions.setColaboradorEvaluacionesFilter({
        id: colaboradorId,
        nombre: event.target.value,
      })
    );
  };
  const filtrarPorAnho = (selectedOption) => {
    const anho = selectedOption;
    const colaboradorId = usuario.colaborador_id;
    //
    if (anho === "") {
      dispatch(
        colaboradorActions.setColaboradorEvaluacionesFilter({
          id: colaboradorId,
        })
      );
      return;
    }
    dispatch(
      colaboradorActions.setColaboradorEvaluacionesFilter({
        id: colaboradorId,
        anho_finalizacion: anho,
      })
    );
  };
  const getColaboradorId = () => {
    return colaboradorId ? colaboradorId : usuario.colaborador_id;
  };
  //
  useEffect(() => {
    if (!colaboradorId && permiso_kpi) {
      dispatch(kpiActions.kpis());
    }
    if (colaboradorId) {
      if (permiso_kpi) {
        dispatch(kpiActions.subordinado({ id: colaboradorId }));
      }
      dispatch(colaboradorActions.colaborador(colaboradorId));
      if (permiso_evaluacion) {
        dispatch(
          colaboradorActions.getColaboradorEvaluaciones({
            id: colaboradorId,
          })
        );
      }
    } else {
      dispatch(colaboradorActions.colaborador(usuario.colaborador_id));
      if (permiso_evaluacion) {
        dispatch(
          colaboradorActions.getColaboradorEvaluaciones({
            id: usuario.colaborador_id,
          })
        );
      }
    }
    //
  }, []);

  const getSexoDisplay = () => {
    const { sexo } = colaborador;
    if (sexo === "M") return "Masculino";
    if (sexo === "F") return "Femenino";
    return "Sin asignar";
  };
  const toMiEquipo = () => {
    let navigateToURL = "/equipo";
    if (colaboradorId) {
      navigateToURL = `/equipo/colaborador/${colaboradorId}`;
    }
    navigate(navigateToURL, {
      state: {
        colaboradorNombre: `${colaborador?.nombre} ${colaborador?.apellido}`,
      },
    });
  };
  // IMAGE CROPPER
  const [showImageInput, setShowImageInput] = useState(false);
  const [image, setImage] = useState("");
  const [imageStatus, setImageStatus] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setImageStatus("crop-img");
    // setHasChanged(true);
  };
  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );
      console.info("imageObj1", typeof imageObj1, imageObj1);
      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      setImageStatus("img-cropped");
    };
  };
  // Handle Cancel Button Click
  const onCropCancel = () => {
    setImageStatus("choose-img");
    setImage("");
  };
  const onDeleteImage = () => {
    setImageStatus("choose-img");
    setImage("");
  };
  const uploadLogo = (file) => {
    dispatch(
      colaboradorActions.setColaborador({ ...colaborador, foto_empleado: file })
    );
    setShowImageInput(false);
  };
  const saveImage = (e) => {
    e.preventDefault();
    console.info("saveImage", image);
    if (imgAfterCrop) {
      const type = imgAfterCrop.split(";")[0].split("/")[1];
      let imgObj = dataURLtoFile(
        imgAfterCrop,
        `${colaborador.nombre}-${colaborador.apellido}.${type}`
      );
      dispatch(
        colaboradorActions.setColaborador({
          ...colaborador,
          foto_empleado: imgObj,
        })
      );
      //
      // setImageStatus("choose-img");
    }
    // @ts-ignore
    dispatch(colaboradorActions.colaboradorUpdate());
  };
  const getHistorialBarChartData = () => {
    let data = [];
    if (promedios_evaluaciones.length) {
      let promediosEvaluacionesCopy = [...promedios_evaluaciones];
      // Ordenar por id en orden descendente
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.sort(
        (a, b) => b.id - a.id
      );
      // Tomar los primeros 6 elementos
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.slice(0, 6);
      data = promediosEvaluacionesCopy.map((p, idx) => ({
        x: idx + 1,
        y: p.puntaje,
        name: p.nombre,
      }));
    }
    //
    return data;
  };
  //
  useEffect(() => {
    if (!esVistaSubordinado()) {
      const colaboradorId = getColaboradorId();
      dispatch(authActions.getUser(colaboradorId));
    }
  }, [colaborador.foto_empleado]);

  useEffect(() => {
    setKpisSubordinado(subordinado.kpis_colaborador);
  }, [subordinado.kpis_colaborador]);

  const onBack = () => {
    return (
      <div
        onClick={() => navigate("/equipo")}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };
  //
  return (
    <>
      <Header
        titulo={`${
          !!esVistaSubordinado() ? "Perfil del colaborador" : "Mi perfil"
        }`}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
        LeftComponent={onBack}
      ></Header>
      {!colaborador_loading && (
        <>
          {/* ==================== DATOS PERSONALES Y LABORALES ==================== */}
          <div className="flex justify-between gap-4 px-12 py-4">
            {/* Foto y jefe */}
            <div className="flex flex-col gap-3">
              {!colaborador.foto_empleado && (
                <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
              )}
              {imageStatus !== "img-cropped" && !!colaborador.foto_empleado && (
                <div className="my-4 w-28 rounded-md">
                  <img
                    className="object-cover h-28 w-28 rounded-md"
                    src={colaborador.foto_empleado}
                    alt="Imagen del colaborador"
                  />
                </div>
              )}
              {/*  */}
              {imageStatus === "img-cropped" && (
                <div className="mb-2">
                  <div className="my-4 w-28 border border-slate-200 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-28 w-28 rounded-md"
                      src={imgAfterCrop}
                      alt="Imagen del colaborador temp"
                    />
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setImageStatus("crop-img");
                      }}
                      className="border border-black p-2 rounded-md"
                    >
                      <Crop size={18}></Crop>
                    </button>

                    <button
                      onClick={onDeleteImage}
                      className="border border-red-500 bg-red-500 p-2 rounded-md text-white"
                    >
                      <X size={24} color={"#FFF"}></X>
                    </button>
                    <button
                      className=" bg-green-500 rounded-md p-2"
                      onClick={saveImage}
                    >
                      <Check size={24} color={"#FFF"}></Check>
                    </button>
                  </div>
                </div>
              )}
              {/*  */}
              {!colaboradorId && (
                <div className="flex flex-col">
                  <div className="text-center p-2 border-2 border-sky-400 bg-white rounded-md">
                    <ImageInput
                      setImage={setImage}
                      onImageSelected={onImageSelected}
                    ></ImageInput>
                  </div>
                  <ImageUploadAlt
                    visible={showImageInput}
                    className={"mt-3 w-full"}
                    onSubmit={uploadLogo}
                  />
                </div>
              )}
              {/*  */}
              <div className="">
                <div className="font-bold">Jefe directo:</div>
                <div className="flex gap-2 text-gray-700">
                  {!colaborador.jefe_directo?.foto_empleado && (
                    <ProfileImage className="h-12 w-12 p-2"></ProfileImage>
                  )}
                  {!!colaborador.jefe_directo?.foto_empleado && (
                    <div className="w-12 bg-slate-200 rounded-md">
                      <img
                        className="object-cover h-12 w-12 rounded-md"
                        src={colaborador.jefe_directo?.foto_empleado}
                        alt={`Foto Lider`}
                      />
                    </div>
                  )}
                  <div className="flex flex-col">
                    <div>{colaborador.jefe_directo?.nombre || "Sin jefe"}</div>
                    <div>
                      {colaborador.jefe_directo?.cargo?.nombre || "Sin cargo"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-gray-700">
                <div>Email de acceso:</div>
                <div>{colaborador.email}</div>
              </div>
              <div>
                <div className="text-gray-700">Rol de acceso:</div>
                <span className="rounded-md bg-orange-500 text-white px-1">
                  {colaborador.rol}
                </span>
              </div>
            </div>
            {/* Datos personales */}
            <Card bgColor="bg-white" className="gap-4 p-4 bg-blue-4 flex-1">
              <div className="flex justify-between">
                <div className="fw-bold">Datos personales</div>
              </div>
              {/* data cols */}
              <div className="grid grid-cols-2 gap-4 text-gray-700 text-sm">
                <div>
                  <div>Nombre:</div>
                  <div className="font-medium">{colaborador?.nombre}</div>
                </div>
                <div>
                  <div>Apellido:</div>
                  <div className="font-medium">
                    {colaborador?.apellido || "Sin asignar"}
                  </div>
                </div>
                <div>
                  <div>Número de ID:</div>
                  <div className="font-medium">{colaborador?.ci}</div>
                </div>
                <div>
                  <div>Sexo:</div>
                  <div className="font-medium">{getSexoDisplay()}</div>
                </div>
                {colaborador?.ruc && (
                  <div>
                    <div>RUC:</div>
                    <div className="font-medium">
                      {colaborador?.ruc || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador?.nacionalidad && (
                  <div>
                    <div>Nacionalidad:</div>
                    <div className="font-medium">
                      {colaborador?.nacionalidad || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador?.celular && (
                  <div>
                    <div>Celular:</div>
                    <div className="font-medium">
                      {colaborador?.celular || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador?.telefono && (
                  <div>
                    <div>Teléfono:</div>
                    <div className="font-medium">
                      {colaborador?.telefono || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador?.ciudad_domicilio && (
                  <div>
                    <div>Ciudad:</div>
                    <div className="font-medium">
                      {colaborador?.ciudad_domicilio || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador?.domicilio && (
                  <div>
                    <div>Domicilio:</div>
                    <div className="font-medium">
                      {colaborador?.domicilio || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador?.grupo_sanguineo && (
                  <div>
                    <div>Grupo sanguíneo:</div>
                    <div className="font-medium">
                      {colaborador?.grupo_sanguineo || "Sin asignar"}
                    </div>
                  </div>
                )}
                {colaborador.fecha_nacimiento && (
                  <div>
                    <div>Cumpleaños:</div>
                    <div className="font-medium">
                      {colaborador.fecha_nacimiento
                        ? dayjs(
                            colaborador.fecha_nacimiento?.slice(0, 10)
                          ).format("DD/MM/YYYY")
                        : "Sin asignar"}
                    </div>
                  </div>
                )}
              </div>
            </Card>
            {/* ==================== DATOS LABORALES ==================== */}
            <div className="flex flex-col gap-2 flex-1">
              <Card bgColor="bg-white" className="gap-4 p-4 bg-blue-4">
                <div className="flex justify-between">
                  <div className="fw-bold">Datos laborales</div>
                </div>
                {/* data cols */}
                <div className="grid grid-cols-2 gap-4 text-gray-700 text-sm">
                  {colaborador.cargo && (
                    <div>
                      <div>Cargo:</div>
                      <div className="font-medium">
                        {colaborador.cargo?.nombre || "Sin asignar"}
                      </div>
                    </div>
                  )}
                  {colaborador.sucursal && (
                    <div>
                      <div>Sucursal:</div>
                      <div className="font-medium">
                        {colaborador.sucursal?.nombre || "Sin asignar"}
                      </div>
                    </div>
                  )}
                  {colaborador.area && (
                    <div>
                      <div>Área:</div>
                      <div className="font-medium">
                        {colaborador.area?.nombre || "Sin asignar"}
                      </div>
                    </div>
                  )}
                  {colaborador.departamento && (
                    <div>
                      <div>Departamento:</div>
                      <div className="font-medium">
                        {colaborador.departamento?.nombre || "Sin asignar"}
                      </div>
                    </div>
                  )}
                  {colaborador.fecha_ingreso && (
                    <div>
                      <div>Fecha de ingreso:</div>
                      <div className="font-medium">
                        {dayjs(colaborador.fecha_ingreso?.slice(0, 10)).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
              <Card bgColor="bg-white" className="gap-4 p-4 bg-blue-4">
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center justify-between">
                    <div className="text-lg font-bold">Equipo:</div>
                    <div
                      className="bg-sky-200 h-8 w-8 rounded-md hover:cursor-pointer"
                      onClick={toMiEquipo}
                    >
                      <ArrowRight color={"blue"} className="m-2"></ArrowRight>
                    </div>
                  </div>

                  <div className="flex flex-row gap-2  items-center">
                    {colaborador.colaboradores?.length > 0 &&
                      colaborador.colaboradores
                        ?.slice(0, 7)
                        .map((item, idx) => (
                          <>
                            {!item.foto_empleado && (
                              <ProfileImage
                                key={`pfp-${idx}`}
                                className="h-12 w-12 p-1"
                                title={`${item.nombre} ${item.apellido}`}
                              ></ProfileImage>
                            )}
                            {!!item.foto_empleado && (
                              <div
                                key={`pfp-${idx}`}
                                className="w-12 bg-slate-200 rounded-md"
                              >
                                <img
                                  className="object-cover h-12 w-12 rounded-md"
                                  src={item.foto_empleado}
                                  alt={`Foto Equipo`}
                                  title={`${item.nombre} ${item.apellido}`}
                                />
                              </div>
                            )}
                          </>
                        ))}
                    {colaborador.colaboradores?.length < 1 && (
                      <div className="text-center w-full p-1">
                        Sin subordinados asignados
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
          {/* ==================== KPIS ==================== */}
          {!!permiso_kpi && (
            <div className="flex flex-col gap-4 px-12 py-4">
              <Card bgColor="bg-zinc-100" className="gap-4 p-4 bg-blue-4">
                <div className="grid grid-cols-3 gap-3">
                  <div className="flex flex-row gap-2  items-center">
                    {!colaborador.foto_empleado && (
                      <div className="w-12 h-12 bg-zinc-200 rounded-md"></div>
                    )}
                    {!!colaborador.foto_empleado && (
                      <div className="w-312 bg-slate-200 rounded-md">
                        <img
                          className="object-cover h-12 w-12 rounded-md"
                          src={colaborador.foto_empleado}
                          alt={`Foto perfil`}
                        />
                      </div>
                    )}
                    <div className="flex flex-col flex-grow text-zinc-700">
                      <div className="text-sm font-bold">KPIs Asignados</div>
                      <div className="text-sm">
                        {!!colaboradorId
                          ? kpisSubordinado?.length
                          : kpisAsignados?.length}
                        KPIs vinculados
                      </div>
                    </div>
                  </div>
                </div>
                {!colaboradorId && (
                  <div className="grid grid-cols-2 gap-3">
                    {kpisAsignados?.length > 0 &&
                      kpisAsignados?.map((kpi, idx) => (
                        <Card key={`kpi-${idx}`} className="gap-3 p-4">
                          <KpiInfo kpi={kpi}></KpiInfo>
                        </Card>
                      ))}
                  </div>
                )}
                {!!colaboradorId && (
                  <div className="grid grid-cols-2 gap-3">
                    {kpisSubordinado?.length > 0 &&
                      kpisSubordinado?.map((kpi, idx) => (
                        <Card key={`kpi-${idx}`} className="gap-3 p-4">
                          <KpiInfo kpi={kpi}></KpiInfo>
                        </Card>
                      ))}
                  </div>
                )}
              </Card>
            </div>
          )}
          {/* ==================== IMAGE CROPPER ==================== */}
          {imageStatus !== "choose-img" && imageStatus !== "img-cropped" && (
            <div className="w-2/3 h-4/5 pb-4 absolute top-0 border border-blue-500 bg-[#7f7f7f]">
              {imageStatus === "crop-img" && (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                />
              )}
            </div>
          )}
          {/* ==================== RESUMEN GENERAL (SOLO JEFES Y ADMIN) ==================== */}
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-12">
            {/* Historial */}
            <div className="col-span-1 md:col-span-6 flex">
              <div className="border rounded-md p-3">
                <div className="font-bold">Historial de evaluaciones</div>
                <div className="flex max-h-72">
                  <BarChart data={getHistorialBarChartData()}></BarChart>
                </div>
              </div>
            </div>
            {/* Última evaluación */}
            <div className="col-span-1 md:col-span-3 flex">
              <div className="border rounded-md p-3 flex-1 flex">
                <div className="flex flex-col justify-between flex-1">
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <div className="font-bold">Última evaluación</div>
                      <div className="text-xs">{`${formatDate(
                        ultima_evaluacion.fecha_ultima_evaluacion
                      )}`}</div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-full flex-1 border-2x">
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"red"}
                      value={ultima_evaluacion.puntaje_ultima_evaluacion || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {ultima_evaluacion.puntaje_ultima_evaluacion || 0}%
                          </div>
                          <div className="text-xs text-red-400">{`${ultima_evaluacion.clasificacion_ultima_evaluacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
              </div>
            </div>
            {/* Promedio desempeño */}
            <div className="col-span-1 md:col-span-3 flex">
              <div className="border rounded-md p-3 flex-1 flex">
                <div className="flex flex-col justify-between flex-1">
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <div className="font-bold">Promedio desempeño</div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-full flex-1">
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_evaluaciones || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_evaluaciones > 0
                              ? parseFloat(promedio_evaluaciones).toFixed(2)
                              : 0}
                            %
                          </div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ==================== HISTORICO DE EVALUACIONES ==================== */}
          {permiso_evaluacion && (
            <div className="mx-12 mt-4 p-3 border rounded-md">
              <div className="font-bold">Histórico de evaluaciones</div>
              {/* Header */}
              <HistorialEvaluacionesHeader
                searchValue={nombreEvaluacion}
                onSearch={onChangeEvaluacionText}
                selectOptions={anhos_evaluaciones}
                onSelect={filtrarPorAnho}
              ></HistorialEvaluacionesHeader>
              {/* Table */}
              <HistorialEvaluacionesTable
                evaluaciones={colaborador_evaluaciones.filter(
                  (evaluacion) => evaluacion.estado.value === 2
                )}
                colaborador={{ id: getColaboradorId() }}
                loading={colaborador_evaluaciones_loading}
              ></HistorialEvaluacionesTable>
            </div>
          )}
          {/* ==================== ESCALA SALARIAL ==================== */}
          {!colaboradorId && (
            <div className="mx-8 mt-4 p-3">
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-4">
                  <div className="w-full flex flex-col gap-3 bg-white border border-slate-100 rounded-md p-4">
                    <h3 className="font-semibold">Salario actual</h3>
                    <div className="py-4 font-medium text-center flex flex-wrap w-full justify-center items-end gap-1">
                      <span className="text-2xl">Gs.</span>
                      <span className="text-4xl">
                        {formatNumber(salario?.salario_fijo ?? 0)}
                      </span>
                    </div>
                    <div className="text-sm">
                      <div>Última ajuste salarial:</div>
                      <div>
                        {!!salario?.ultima_modificacion
                          ? dayjs(
                              salario?.ultima_modificacion?.slice(0, 10)
                            ).format("DD/MM/YYYY")
                          : "Sin registro"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* LOADER */}
      {!!colaborador_loading && (
        <>
          <div className="grid grid-cols-12 gap-4 px-12 py-4 animate-pulse h-4/5">
            <div className="col-span-3 bg-slate-200 border rounded-md"></div>
            <div className="col-span-5 bg-slate-200 border rounded-md"></div>
            <div className="col-span-4 bg-slate-200 border rounded-md"></div>
          </div>
          <div className="bg-slate-200 border rounded-md mx-12 py-4 h-1/2"></div>
        </>
      )}
    </>
  );
}

export default ProfilePage;
