import { useEffect, useState } from "react";
import { GearWideConnected, Trash, TrashFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { planCarreraActions } from "../handlers/redux";
import { TIPOS_REQUISITOS } from "../helpers/constants";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";

const RequisitoList = ({
  title = "Académicos",
  tipo,
  requisitos = [],
  cargoLocal,
  setCargoLocal,
  readOnly = false,
}) => {
  const [requisitosLocal, setRequisitosLocal] = useState([]);
  //
  const addRequisito = () => {
    if (requisitos.length > 59) {
      return;
    }
    let requisito = {
      descripcion: "",
      tipo: tipo,
    };
    const new_requisitos = [...requisitosLocal, requisito];
    setRequisitosLocal(new_requisitos);
    let requisitosGlobalCopy = [...requisitos].filter(
      (r, rid) => r.tipo !== tipo
    );
    setCargoLocal({
      ...cargoLocal,
      requisitos: [...requisitosGlobalCopy, ...new_requisitos],
    });
  };
  const onChangeRequisito = (ridx) => (e) => {
    const new_requisitos = JSON.parse(JSON.stringify(requisitosLocal));
    new_requisitos[ridx].descripcion = e.target.value;
    setRequisitosLocal(new_requisitos);
    //
    let requisitosGlobalCopy = [...requisitos].filter(
      (r, rid) => r.tipo !== tipo
    );
    setCargoLocal({
      ...cargoLocal,
      requisitos: [...requisitosGlobalCopy, ...new_requisitos],
    });
  };
  const onDeleteRequisito = (ridx) => () => {
    const new_requisitos = JSON.parse(JSON.stringify(requisitosLocal));
    new_requisitos.splice(ridx, 1);
    setRequisitosLocal(new_requisitos);
    //
    let requisitosGlobalCopy = JSON.parse(JSON.stringify(requisitos)).filter(
      (r, rid) => r.tipo !== tipo
    );
    setCargoLocal({
      ...cargoLocal,
      requisitos: [...requisitosGlobalCopy, ...new_requisitos],
    });
  };
  useEffect(() => {
    setRequisitosLocal(requisitos.filter((r, rid) => r.tipo === tipo));
  }, [requisitos]);
  //
  return (
    <div className="flex mt-2">
      <div className="w-full mr-3">
        <label className="font-semibold text-slate-500">{title}</label>
        <div className="w-full flex flex-col gap-2 mt-2">
          {requisitosLocal.map((requisito, ridx) => (
            <div className="flex gap-1">
              <input
                type="text"
                className="w-full border rounded-md p-2"
                value={requisito.descripcion}
                onChange={onChangeRequisito(ridx)}
                placeholder={`Requisito ${ridx + 1}`}
                disabled={readOnly}
              />
              {!readOnly && (
                <button
                  onClick={onDeleteRequisito(ridx)}
                  className="border rounded-md px-2 py-1"
                >
                  <TrashFill />
                </button>
              )}
            </div>
          ))}
        </div>
        {!readOnly && (
          <button
            onClick={addRequisito}
            className="flex items-center text-sky-500 gap-1 mt-2"
          >
            <GearWideConnected />
            <span className="text-sm">Agregar nuevo requisito</span>
          </button>
        )}
      </div>
    </div>
  );
};

function RequisitoForm({ cargo, readOnly = false, onFinalizar, afterSubmit }) {
  const dispatch = useDispatch();
  const cargoDetail = useSelector((state) => state.plan_carrera.cargo);
  const cargo_loading = useSelector(
    (state) => state.plan_carrera.cargo_loading
  );
  const [cargoLocal, setCargoLocal] = useState({});
  const {
    requisitos = [],
    antiguedad_minima_cargo = 0,
    antiguedad_minima_empresa = 0,
  } = cargoLocal;
  const onChange = (field) => (e) => {
    setCargoLocal({
      ...cargoLocal,
      [field]: e.target.value,
    });
  };
  const onGuardarCargo = () => {
    let cargoLocalCopy = JSON.parse(JSON.stringify(cargoLocal));
    cargoLocalCopy.requisitos = cargoLocalCopy.requisitos.filter(
      (r) => !!r.descripcion
    );
    dispatch(planCarreraActions.updateRequisitosCargo(cargoLocalCopy));
    //
    if (!!afterSubmit) {
      afterSubmit(cargo);
    }
  };
  //
  useEffect(() => {
    dispatch(planCarreraActions.getCargo(cargo.id));
  }, [cargo]);
  useEffect(() => {
    setCargoLocal({
      ...cargoDetail,
      requisitos: cargoDetail.requisitos ?? [],
      antiguedad_minima_cargo: cargoDetail.antiguedad_minima_cargo ?? 0,
      antiguedad_minima_empresa: cargoDetail.antiguedad_minima_empresa ?? 0,
    });
  }, [cargoDetail]);
  //
  return (
    <>
      {!cargo_loading && (
        <div className="px-4">
          <h3 className="font-semibold">Factores a evaluar:</h3>
          <h3 className="font-semibold text-slate-500 my-2">Primarios</h3>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col justify-between w-full">
              <label htmlFor="" className="font-semibold">
                Antigüedad miníma en cargo
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  className="border p-2 rounded-md"
                  value={antiguedad_minima_cargo}
                  onChange={onChange("antiguedad_minima_cargo")}
                  disabled={readOnly}
                />
                <span>mes(es)</span>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col">
              <label htmlFor="" className="font-semibold">
                Antigüedad miníma en la empresa
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  className="border p-2 rounded-md"
                  value={antiguedad_minima_empresa}
                  onChange={onChange("antiguedad_minima_empresa")}
                  disabled={readOnly}
                />
                <span>mes(es)</span>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="flex flex-col overflow-auto custom-scrollbar max-h-[300px] mt-2 gap-2">
            <RequisitoList
              title={"Académicos"}
              tipo={TIPOS_REQUISITOS.ACADEMICO}
              requisitos={requisitos}
              cargoLocal={cargoLocal}
              setCargoLocal={setCargoLocal}
              readOnly={readOnly}
            />
            <RequisitoList
              title={"Técnicos o específicos"}
              tipo={TIPOS_REQUISITOS.TECNICO}
              requisitos={requisitos}
              cargoLocal={cargoLocal}
              setCargoLocal={setCargoLocal}
              readOnly={readOnly}
            />
            <RequisitoList
              title={"Personales"}
              tipo={TIPOS_REQUISITOS.PERSONAL}
              requisitos={requisitos}
              cargoLocal={cargoLocal}
              setCargoLocal={setCargoLocal}
              readOnly={readOnly}
            />
          </div>
          {!readOnly && (
            <div className="flex flex-col gap-2 w-full mt-2">
              <button
                onClick={onGuardarCargo}
                className="border border-black py-3 rounded-md"
              >
                Guardar y continuar
              </button>
              <button
                onClick={onFinalizar}
                className="bg-sky-500 py-3 text-white rounded-md"
              >
                Finalizar
              </button>
            </div>
          )}
        </div>
      )}
      {!!cargo_loading && (
        <div className="w-full flex items-center justify-center h-96">
          <LoadingSpinButton message={"Cargando, por favor espere"} />
        </div>
      )}
    </>
  );
}

export default RequisitoForm;
