import React from "react";
import ReactModal from "react-modal";

const customStyles = {
  overlay: { padding: 0, display: "flex", background: "transparent" },
  content: {
    position: "relative",
    inset: 0,
    border: 0,
    padding: 0,
    display: "flex",
    flexGrow: 1,
    height: "100%",
    background: "#00000033",
    justifyContent: "center",
    alignItems: "center",
  },
};

function Modal({ children, ...props }) {
  return (
    <ReactModal
      isOpen={true}
      {...props}
      ariaHideApp={false}
      style={customStyles}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
