export const TIPO_METRICAS = {
  PORCENTAJE: 1,
  NUMERICO: 2,
  SIMPLE: 3,
  OBSERVACION: 4,
};

export const PUNTUACIONES_OBSERVACION = [
  { label: "Siempre", value: 100 },
  { label: "Constantemente", value: 75 },
  { label: "Frecuentemente", value: 50 },
  { label: "En Ocasiones", value: 25 },
  { label: "Nunca", value: 0 },
  { label: "No aplica", value: -1 },
];
