import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const beneficioApis = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return {
    list: async (beneficios_filter) => {
      const { nombre = "" } = beneficios_filter;
      try {
        const response = await axios.get(
          `${URL_BASE}/beneficio/?ordering=nombre&search=${nombre}&page=1&page_size=999`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};
