import React from "react";
import ProfilePage from "src/modules/common/pages/ProfilePage";
import EquipoPage from "src/modules/equipo/pages/EquipoPage";

export const equipoRoutes = [
  {
    path: "/equipo/",
    element: <EquipoPage />,
  },
  {
    path: "/equipo/colaborador/:id",
    element: <EquipoPage />,
  },
  {
    path: "/equipo/:id",
    element: <ProfilePage />,
  },
];
