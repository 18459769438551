import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeedbackItem } from "./FeedbackItem";
import { feedbackActions } from "../handlers/redux";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { commonActions } from "src/modules/common/handler/redux";
import SelectModal from "src/modules/common/components/SelectModal";
import { ComentarioBox } from "./ComentarioBox";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

export function FeedbackComentarios({
  jefe_directo, // Datos del jefe directo
  colaborador, // Datos del colaborador
  identificadores, // Los ids del feedback, jefe directo y colaborador para la api
  newFeedback, // Cuando en etapa Jefe Directo se agrega un nuevo bloque de feedback se recibe acá
  editJefeDirecto, // Indica si el jefe directo puede agregar su comentario
  editColaborador, // Indica si el colaborador puede agregar su comentario
  commentColaboradorView, // Si el comentario del colaborador se puede mostrar o no
  buttonDeleteView, // Si el botón de eliminar se puede mostrar o no
  canSave, // Si los botones de 'Enviar feedback' y 'Guardar y continuar más tarde' se puede mostrar o no
  etapa, // La etapa desde donde se invoca a FeedbackComentarios, si es desde jefe-directo -> 1, evaluado -> 2 o finalizado -> 3
}) {
  const dispatch = useDispatch();

  const feedback_nomina = useSelector(
    (state) => state.feedback.feedback_nomina
  );
  const feedback_nomina_loading = useSelector(
    (state) => state.feedback.feedback_nomina_loading
  );
  const { feedback_detalles = [] } = feedback_nomina;
  const { idFeedback, idJefeDirecto, idColaborador } = identificadores;

  const [feedbacks, setFeedbacks] = useState(feedback_detalles);
  const [indexFeedback, setIndexFeedback] = useState(null);
  const [comentarioBox, setComentarioBox] = useState("");
  const [tipoComentario, setTipoComentario] = useState(null);
  const [errores, setErrores] = useState([]);

  useEffect(() => {
    setFeedbacks(feedback_detalles);
  }, [feedback_detalles]);

  useEffect(() => {
    if (newFeedback) {
      let cargarFeedback = true;
      // Se debe poder cargar UN solo Feedback General
      if (newFeedback.tipo === "General") {
        const feedbacksGenerales = feedbacks.filter(
          (feedback) => feedback.tipo === "General"
        );
        // Condición de unicidad
        cargarFeedback = feedbacksGenerales.length > 0 ? false : true;
      } else {
        // Dos feedback con el mismo nombre y descripción no se permite guardar
        const feedbacksCopy = feedbacks.filter(
          (feedback) =>
            feedback.tipo === newFeedback.tipo &&
            feedback.nombre === newFeedback.nombre &&
            feedback.descripcion === newFeedback.descripcion
        );
        cargarFeedback = feedbacksCopy.length > 0 ? false : true;
      }
      if (cargarFeedback) {
        let feedbacksCopy = [...feedbacks];
        feedbacksCopy.push(newFeedback);
        setFeedbacks(feedbacksCopy);
      }
    }
  }, [newFeedback]);

  const onOpenComentario = (feedback, idx, tipoComentario) => {
    setIndexFeedback(idx); // Índice del feedback que se quiere agregar comentario
    const value =
      tipoComentario === "jefe_directo"
        ? feedback.comentario_jefe_directo
        : feedback.comentario_colaborador;
    setComentarioBox(value);
    setTipoComentario(tipoComentario);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const onAddComment = (comentario) => {
    let feedbacksCopy = feedbacks.map((feedback, idx) => {
      if (idx === indexFeedback) {
        const updatedFeedback = JSON.parse(JSON.stringify(feedback));
        if (tipoComentario === "jefe_directo") {
          updatedFeedback.comentario_jefe_directo = comentario;
        } else if (tipoComentario === "colaborador") {
          updatedFeedback.comentario_colaborador = comentario;
        }
        return updatedFeedback;
      }
      return feedback; // Devuelve el objeto original si no es el índice deseado
    });
    setFeedbacks(feedbacksCopy);
    dispatch(commonActions.setSelectModal({ open: false }));
  };

  const onDelete = (idx) => {
    const deseaEliminar = window.confirm(
      "¿Esta seguro de eliminar este feedback?"
    );
    if (!!deseaEliminar) {
      const feedbacksCopy = [...feedbacks];
      feedbacksCopy.splice(idx, 1);
      setFeedbacks(feedbacksCopy);
    }
  };

  const RenderFeedbackGeneral = () => {
    return (
      <div className="flex flex-col gap-4 my-4">
        {feedbacks.map((feedback, idx) => (
          <div>
            {feedback.tipo === "General" && (
              <FeedbackItem
                id={idx}
                type={feedback.tipo}
                className={"bg-green-500 text-white"}
                jefe_directo={jefe_directo}
                colaborador={colaborador}
                commentJefeDirecto={feedback.comentario_jefe_directo}
                editJefeDirecto={editJefeDirecto}
                commentColaborador={feedback.comentario_colaborador}
                editColaborador={editColaborador}
                commentColaboradorView={commentColaboradorView}
                onDelete={() => onDelete(idx)}
                buttonDeleteView={buttonDeleteView}
                onClickJefeDirecto={() =>
                  onOpenComentario(feedback, idx, "jefe_directo")
                }
                onClickColaborador={() =>
                  onOpenComentario(feedback, idx, "colaborador")
                }
                error={errores.filter((e) => e.id === idx)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const RenderCompetenciasFeedback = () => {
    return (
      <div className="flex flex-col gap-4 my-4">
        {feedbacks.map((feedback, idx) => (
          <div>
            {feedback.tipo === "Competencia" && (
              <FeedbackItem
                id={idx}
                type={feedback.tipo}
                className={"bg-sky-500 text-white"}
                jefe_directo={jefe_directo}
                colaborador={colaborador}
                title={feedback.nombre}
                descripcion={feedback.descripcion}
                commentJefeDirecto={feedback.comentario_jefe_directo}
                editJefeDirecto={editJefeDirecto}
                commentColaborador={feedback.comentario_colaborador}
                editColaborador={editColaborador}
                commentColaboradorView={commentColaboradorView}
                onDelete={() => onDelete(idx)}
                buttonDeleteView={buttonDeleteView}
                onClickJefeDirecto={() =>
                  onOpenComentario(feedback, idx, "jefe_directo")
                }
                onClickColaborador={() =>
                  onOpenComentario(feedback, idx, "colaborador")
                }
                error={errores.filter((e) => e.id === idx)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const RenderKpisFeedback = () => {
    return (
      <div className="flex flex-col gap-4 my-4">
        {feedbacks.map((feedback, idx) => (
          <div>
            {feedback.tipo === "KPI" && (
              <FeedbackItem
                id={idx}
                type={feedback.tipo}
                className={"bg-yellow-500 text-white"}
                jefe_directo={jefe_directo}
                colaborador={colaborador}
                title={feedback.nombre}
                descripcion={feedback.descripcion}
                commentJefeDirecto={feedback.comentario_jefe_directo}
                editJefeDirecto={editJefeDirecto}
                commentColaborador={feedback.comentario_colaborador}
                editColaborador={editColaborador}
                commentColaboradorView={commentColaboradorView}
                onDelete={() => onDelete(idx)}
                buttonDeleteView={buttonDeleteView}
                onClickJefeDirecto={() =>
                  onOpenComentario(feedback, idx, "jefe_directo")
                }
                onClickColaborador={() =>
                  onOpenComentario(feedback, idx, "colaborador")
                }
                error={errores.filter((e) => e.id === idx)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const getErrores = (feedbacksCopy) => {
    let indicesInvalidos = [];
    feedbacksCopy.forEach((element, index) => {
      if ((etapa === 1 || etapa === 2) && !element.comentario_jefe_directo) {
        indicesInvalidos.push({ id: index, comentario_tipo: "jefe_directo" });
      }
      if (etapa === 2 && !element.comentario_colaborador) {
        indicesInvalidos.push({ id: index, comentario_tipo: "colaborador" });
      }
    });
    return indicesInvalidos;
  };

  const onSubmit = (esParcial) => {
    // Elimina los objetos que vengan vacíos {} en caso que existan (por si acaso)
    const feedbacksCopy = feedbacks.filter((feedback) => {
      return Object.keys(feedback).length > 0;
    });
    // En caso que existan los atributos de los comentarios, que se le elimine los espacios sobrantes
    let feedbacksCopy2 = feedbacksCopy.map((obj) => {
      return {
        ...obj,
        comentario_jefe_directo: obj.comentario_jefe_directo
          ? obj.comentario_jefe_directo.trim()
          : obj.comentario_jefe_directo,
        comentario_colaborador: obj.comentario_colaborador
          ? obj.comentario_colaborador.trim()
          : obj.comentario_colaborador,
      };
    });
    // Si es parcial (Guardar y continuar más tarde) que sí deje guardar
    if (!esParcial) {
      const erroresAux = getErrores(feedbacksCopy2);
      setErrores(erroresAux);
      if (erroresAux.length > 0) {
        ErrorToast({
          message:
            "Por favor, complete todos los campos obligatorios antes de enviar el feedback.",
        });
        return;
      }
    }
    const params = {
      ...feedback_nomina,
      feedback_detalles: feedbacksCopy2,
      es_parcial: esParcial, // esParcial indica si se envió el feedback o solo se le dió Guardar y continuar más tarde
      etapa: etapa,
    };
    dispatch(feedbackActions.setFeedbackNomina(params));
    dispatch(
      feedbackActions.createFeedbackNomina({
        idFeedback,
        idJefeDirecto,
        idColaborador,
      })
    );
  };

  return (
    <div className="my-4">
      {feedback_nomina_loading && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
      {!feedback_nomina_loading && (
        <div>
          {feedbacks.length === 0 && (
            <div className="w-full flex justify-center p-4 border rounded-md m-2">
              Sin registros para mostrar
            </div>
          )}
          {feedbacks.length > 0 && (
            <div>
              <RenderFeedbackGeneral />
              <RenderCompetenciasFeedback />
              <RenderKpisFeedback />
            </div>
          )}
          {canSave && (
            <div className="flex flex-col items-center gap-4">
              <button
                className="border border-slate-500 text-slate-500 rounded-md bg-white p-1 w-2/3"
                onClick={() => onSubmit(true)}
              >
                Guardar y continuar más tarde
              </button>
              <PrimaryButton
                type="button"
                className="w-2/3 mb-3"
                onClick={() => onSubmit(false)}
              >
                Enviar Feedback
              </PrimaryButton>
            </div>
          )}
        </div>
      )}
      <SelectModal
        title="Agregar comentario"
        content={
          <div>
            <ComentarioBox
              comentario={comentarioBox}
              onSubmit={onAddComment}
            ></ComentarioBox>
          </div>
        }
      />
    </div>
  );
}
