import React from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { ProfileImage } from "./ProfileImage";

function ColaboradorRow({ colaborador, onClick }) {
  return (
    <div
      key={`row-${colaborador.id}`}
      className="hover:cursor-pointer border-b"
      onClick={onClick}
    >
      <div className="flex items-center justify-between py-3 hover:bg-slate-100">
        <div className="flex flex-row gap-2 items-center">
          {!colaborador.foto_empleado && (
            <ProfileImage className="h-14 w-14 p-2"></ProfileImage>
          )}
          {!!colaborador.foto_empleado && (
            <div className="w-14 bg-slate-200 rounded-md">
              <img
                className="object-cover h-14 w-14 rounded-md"
                src={colaborador.foto_empleado}
                alt={`Foto`}
              />
            </div>
          )}
          <div className="flex flex-col flex-grow text-zinc-700">
            <div className="text-sm font-bold">
              {`${colaborador.nombre} ${colaborador.apellido}`}
            </div>
            <div className="text-sm">
              Cargo: {colaborador.cargo?.nombre || "Sin especificar"}
            </div>
          </div>
        </div>
        <div>
          <ChevronRight size={18} color={"#00F"}></ChevronRight>
        </div>
      </div>
    </div>
  );
}

export default ColaboradorRow;
