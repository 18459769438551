import React, { useState } from "react";
import Modal from "src/modules/common/components/Modal";
import { X } from "react-bootstrap-icons";
import { CompetenciasColaboradorSelect } from "../components/CompetenciasColaboradorSelect";
import { KpisColaboradorSelect } from "../components/KpisColaboradorSelect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FeedbackComentarios } from "../components/FeedbackComentarios";
import DatePicker from "react-datepicker";
import { feedbackActions } from "../handlers/redux";

export function FeedbackEtapaJefeDirecto() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idFeedback, idJefeDirecto, idColaborador } = params;

  const feedback_nomina = useSelector(
    (state) => state.feedback.feedback_nomina
  );
  const feedback_nomina_loading = useSelector(
    (state) => state.feedback.feedback_nomina_loading
  );
  const jefe_directo = useSelector((state) => state.feedback.jefe_directo);
  const jefe_directo_loading = useSelector(
    (state) => state.feedback.jefe_directo_loading
  );
  const colaborador = useSelector(
    (state) => state.feedback.colaborador_feedback
  );
  const colaborador_loading = useSelector(
    (state) => state.feedback.colaborador_feedback_loading
  );

  const { solicitante } = feedback_nomina;
  const tiene_permiso = solicitante === "jefe-directo";
  const estado = feedback_nomina.estado ? feedback_nomina.estado : 1;

  const [openCompetencias, setOpenCompetencias] = useState(false);
  const [openKpis, setOpenKpis] = useState(false);
  const [newFeedback, setNewFeedback] = useState({});

  const RenderButton = ({ id, label, className, onSubmit }) => {
    return (
      <button
        id={id}
        className={`rounded-md p-3 ${className}`}
        onClick={onSubmit}
      >
        {label}
      </button>
    );
  };

  const onOpenCompetencias = () => {
    setOpenCompetencias(true);
  };

  const onOpenKpis = () => {
    setOpenKpis(true);
  };

  const onAddFeedback = (tipo, feedback) => {
    console.log(tipo, feedback);
    if (tipo === "General") {
      setNewFeedback({
        id: 0,
        tipo: tipo,
      });
    } else {
      setNewFeedback({
        id: feedback.id,
        tipo: tipo,
        nombre: feedback.nombre,
        descripcion: feedback.descripcion,
      });
    }
    if (tipo === "Competencia") {
      setOpenCompetencias(false);
    } else if (tipo === "KPI") {
      setOpenKpis(false);
    }
  };

  const onChange = (field) => (e) => {
    let value = "";
    if (field.includes("fecha")) {
      value = e;
    }
    console.log(field, value);
    dispatch(
      feedbackActions.setFeedbackNomina({
        ...feedback_nomina,
        [field]: value,
      })
    );
  };

  return (
    <div className="my-4">
      {tiene_permiso && (
        <div className="flex flex-row gap-4">
          <RenderButton
            id={"btnCompetencia"}
            label="Agregar una Competencia"
            className="bg-blue-100 text-blue-500"
            onSubmit={onOpenCompetencias}
          />
          <RenderButton
            id={"btnKpi"}
            label="Agregar un KPI"
            className="bg-yellow-100 text-yellow-500"
            onSubmit={onOpenKpis}
          />
          <RenderButton
            id={"btnGeneral"}
            label="Feedback General"
            className="bg-green-100 text-green-500"
            onSubmit={() => onAddFeedback("General")}
          />
        </div>
      )}
      <div className="flex flex-col w-1/2 mt-4">
        <label className="font-bold text-base" htmlFor="">
          Fecha Conversación
        </label>
        <DatePicker
          className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-auto"
          locale="es"
          selected={Date.parse(feedback_nomina.fecha_conversacion)}
          onChange={onChange("fecha_conversacion")}
          dateFormat="dd/MM/yyyy"
          disabled={!tiene_permiso}
        />
      </div>
      <FeedbackComentarios
        jefe_directo={jefe_directo}
        colaborador={colaborador}
        identificadores={{ idFeedback, idJefeDirecto, idColaborador }}
        newFeedback={newFeedback}
        editJefeDirecto={tiene_permiso && estado < 3}
        commentColaboradorView={false}
        buttonDeleteView={true}
        canSave={tiene_permiso && estado < 3} // Solo se pueden enviar feedback si la etapa no está finalizada
        etapa={1}
      />
      <Modal isOpen={openCompetencias}>
        <div className="rounded-lg bg-white w-10/12 md:w-6/12 p-2 h-100 md:p-5 flex flex-col gap-5 scroll-auto h-1/2">
          <div className="modal-title">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-4">
                <h2 className="font-semibold text-xl">
                  Competencias del colaborador
                </h2>
              </div>
              <button
                onClick={() => {
                  setOpenCompetencias(false);
                }}
              >
                <X size={24} />
              </button>
            </div>
          </div>
          <div className="modal-body overflow-y-auto">
            <div className="">
              <CompetenciasColaboradorSelect
                idFeedback={idFeedback}
                idColaborador={idColaborador}
                onSubmit={onAddFeedback}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={openKpis}>
        <div className="rounded-lg bg-white w-10/12 md:w-6/12 p-2 h-100 md:p-5 flex flex-col gap-5 scroll-auto h-1/2">
          <div className="modal-title">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-4">
                <h2 className="font-semibold text-xl">KPIs del colaborador</h2>
              </div>
              <button
                onClick={() => {
                  setOpenKpis(false);
                }}
              >
                <X size={24} />
              </button>
            </div>
          </div>
          <div className="modal-body overflow-y-auto">
            <div className="">
              <KpisColaboradorSelect
                idColaborador={colaborador.id}
                onSubmit={onAddFeedback}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
