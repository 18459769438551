import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "src/modules/common/components/ToggleSwitch";
import { kpiActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import DecimalInput from "src/modules/common/components/DecimalInput";

function KpiForm({ kpiColaborador, onSave, ...props }) {
  const dispatch = useDispatch();
  const [kpiLocal, setKpiLocal] = useState(kpiColaborador);
  const anotaciones = useSelector((state) => state.kpi.anotaciones);
  const colaborador = useSelector((state) => state.kpi.subordinado);
  const metricas = useSelector((state) => state.kpi.metricas) || [];
  const periodos = useSelector((state) => state.kpi.periodos) || [];
  const [activarEscalaChecked, setActivarEscalaChecked] = useState(false);
  const metricasConMeta = [1, 2];
  //
  const checkRangos = () => {
    const tieneRangos = kpiLocal?.rangos?.length > 0;
    let rangos = [];
    //
    if (tieneRangos) {
      rangos = JSON.parse(JSON.stringify(kpiLocal.rangos));
      for (let i = 0; i < rangos.length; i++) {
        let estaVacio =
          !rangos[i].desde && !rangos[i].hasta && !rangos[i].resultado;
        if (estaVacio) {
          rangos.splice(i, 1);
          i--;
        }
      }
      //
      setKpiLocal({
        ...kpiLocal,
        rangos: rangos,
      });
    }
  };
  const ESTADO_OPTIONS = [
    { value: true, label: "Vigente" },
    { value: false, label: "No Vigente" },
  ];
  //
  const renderInput = (
    field,
    type,
    name,
    placeholder = null,
    description = null,
    options = [],
    disabled = false,
    noEmpySelectOption = false
  ) => {
    //
    let inputContainerClass = "flex flex-col gap-2";
    if (field === "meta") {
      inputContainerClass = checkMetaDisplay(kpiLocal["metrica"])
        ? inputContainerClass
        : "hidden";
    }
    if (type === "select") {
      return (
        <div className="flex flex-col gap-2">
          <label className="font-bold text-base" htmlFor="">
            {name}
            {!!name && ":"}
            {!name && <span>&nbsp;</span>}
          </label>
          <select
            name={`select-${field}`}
            id={`select-${field}`}
            disabled={disabled}
            value={kpiLocal[field]}
            onChange={onChange(field)}
            className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
          >
            {!noEmpySelectOption && (
              <option key={`opt-${field}-null`} value={null}>
                Seleccione una opción
              </option>
            )}
            {options.map((opt, idx) => (
              <option key={`opt-${field}-${idx}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
          {!!description && <div className="text-sm">{description}</div>}
        </div>
      );
    }
    //
    return (
      <div className={inputContainerClass}>
        <label className="font-bold text-base" htmlFor="">
          {name}
          {!!name && ":"}
          {!name && <span>&nbsp;</span>}
        </label>
        <input
          disabled={disabled}
          type={type}
          name={field}
          value={kpiLocal[field]?.toLocaleString("de-DE") || ""}
          onChange={onChange(field)}
          placeholder={placeholder || "Sin especificar"}
          className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
        />
        {!!description && <div className="text-sm">{description}</div>}
      </div>
    );
  };
  //
  const onChangeRango = (index, field, value) => {
    const tieneRangos = kpiLocal?.rangos?.length > 0;
    let rangos = [];
    let lastRangoIndex = 0;
    if (tieneRangos) {
      rangos = JSON.parse(JSON.stringify(kpiLocal.rangos));
    }
    // rellenamos posibles indexes vacios
    if (rangos.length < 5) {
      lastRangoIndex = rangos.length;
      for (let i = lastRangoIndex; i < 5; i++) {
        rangos[i] = { desde: "", hasta: "", resultado: "" };
      }
    }
    rangos[index][field] = value !== null ? Number(value) : value;
    //
    setKpiLocal({
      ...kpiLocal,
      rangos: rangos,
    });
  };
  const renderTablaEscala = () => {
    if (!activarEscalaChecked) {
      return <></>;
    }
    const inputClass =
      "border rounded placeholder-zinc-600 border-zinc-200 px-2x py-2 text-sm flex-1 mr-1x mb-1x";
    return (
      <>
        <div className="flex flex-col items-start justify-center w-full">
          <label
            className="flex items-center gap-2 font-semibold my-2"
            htmlFor="tiene_escala_dinamica"
          >
            <input
              className="w-4 h-4"
              type="checkbox"
              name="tiene_escala_dinamica"
              id="tiene_escala_dinamica"
              checked={kpiLocal?.tiene_escala_dinamica}
              onChange={onChange("tiene_escala_dinamica")}
            />
            <span>Escala dinámica</span>
          </label>
          <small className="text-xs text-slate-500 hidden md:block">
            Los rangos con Resultado en blanco tomarán el valor ingresado como
            Alcance en la Evaluación de Desempeño
          </small>
        </div>
        <div className="flex justify-around mr-6 my-2 w-full">
          <span className="font-bold">Desde</span>
          <span className="font-bold">Hasta</span>
          <span className="font-bold">Resultado</span>
        </div>
        <div className="col-span-1x md:col-span-2x flex flex-col gap-2 mr-6x my-2x  w-full">
          {[1, 2, 3, 4, 5].map((rango, index) => (
            <div
              key={`rango-${rango}`}
              className="flex justify-around w-full flex-row gap-2"
            >
              <DecimalInput
                className={inputClass}
                type="text"
                id={`desde-${rango}`}
                key={`desde-${rango}`}
                value={kpiLocal?.rangos ? kpiLocal?.rangos[index]?.desde : ""}
                onChange={(value) => onChangeRango(index, "desde", value)}
              />
              <DecimalInput
                className={inputClass}
                type="text"
                id={`hasta-${rango}`}
                key={`hasta-${rango}`}
                value={kpiLocal?.rangos ? kpiLocal?.rangos[index]?.hasta : ""}
                onChange={(value) => onChangeRango(index, "hasta", value)}
              />
              <DecimalInput
                className={inputClass}
                type="text"
                id={`resultado-${rango}`}
                key={`resultado-${rango}`}
                value={
                  kpiLocal?.rangos ? kpiLocal?.rangos[index]?.resultado : ""
                }
                onChange={(value) => onChangeRango(index, "resultado", value)}
              />
            </div>
          ))}
        </div>
      </>
    );
  };
  //
  const checkMetaDisplay = (metrica) => {
    if (activarEscalaChecked) {
      return false;
    }
    if (!metrica) return true;
    return metricasConMeta.includes(parseInt(metrica));
  };
  const switchChangeHandler = () => {
    setActivarEscalaChecked(!activarEscalaChecked);
    //resetamos valor de Meta
    setKpiLocal({
      ...kpiLocal,
      meta: 0,
      rangos: [],
    });
  };
  const onChange =
    (field) =>
    ({ target }) => {
      let text = "";
      let display = {};
      if (target.options) {
        text = target.options[target.selectedIndex].text;
      }
      if (field === "metrica") {
        display = { metrica_display: text };
      }
      if (field === "periodo") {
        display = { periodo_display: text };
      }
      let value = target.value;
      if (field === "tiene_escala_dinamica") {
        value = target.checked;
      } else {
        if (value.split(",").length > 2) {
          return;
        }
      }
      //
      if (field === "meta") {
        if (!value.endsWith(",")) {
          value = (value + "").replace(/\./g, "");
          if (value.includes(",")) {
            const parteEntera = value.slice(0, value.indexOf(","));
            const parteDecimal = value.slice(value.indexOf(","));
            value = Number(parteEntera).toLocaleString("de-DE") + parteDecimal;
          } else {
            value = Number(value).toLocaleString("de-DE");
          }
        }
      }
      //
      setKpiLocal({
        ...kpiLocal,
        [field]: value,
        ...display,
      });
    };
  //
  const onSubmit = (e) => {
    e.preventDefault();
    const {
      nombre,
      descripcion,
      metrica,
      periodo,
      meta,
      rangos,
      tiene_escala_dinamica = false,
    } = kpiLocal;
    let kpiLocalCopy = JSON.parse(JSON.stringify(kpiLocal));
    const fields = ["Nombre", "Descripción", "Métrica", "Período"];
    const values = [nombre, descripcion, metrica, periodo];
    const fieldErrors = [];
    //
    if (!activarEscalaChecked) {
      if (metricasConMeta.includes(parseInt(metrica))) {
        fields.push("Meta");
        let metaAux = 0;
        if (!meta.toString().endsWith(",")) {
          // Le sacamos los separadores de miles (puntos) y las comas de decimales los reemplazamos por puntos
          metaAux = Number((meta + "").replace(/\./g, "").replace(/\,/g, "."));
          values.push(metaAux);
        } else {
          fieldErrors.push("Meta");
        }
        //
        kpiLocalCopy = {
          ...kpiLocalCopy,
          meta: metaAux,
        };
      } else {
        kpiLocalCopy = {
          ...kpiLocalCopy,
          meta: null,
        };
      }
    } else {
      checkRangos();
    }
    //
    values.forEach((val, vidx) => {
      if (!val) {
        fieldErrors.push(fields[vidx]);
      }
    });
    if (fieldErrors.length) {
      ErrorToast({
        message: `Faltan campos obligatorios: ${fieldErrors.toString()}`,
      });
      return;
    }
    //
    if (onSave) {
      onSave(kpiLocalCopy);
    }
  };

  const tieneAnotaciones = () => {
    return anotaciones.length > 0;
  };
  //
  useEffect(() => {
    dispatch(kpiActions.metricas());
    dispatch(kpiActions.periodos());
  }, []);

  useEffect(() => {
    checkMetaDisplay(kpiLocal.metrica);
    if (kpiLocal.rangos?.length > 0) {
      setActivarEscalaChecked(true);
    }
    if (kpiLocal.id) dispatch(kpiActions.getAnotaciones(kpiLocal));
  }, [kpiLocal]);

  return (
    <form onSubmit={onSubmit}>
      {!!tieneAnotaciones() && (
        <div className="bg-yellow-300 p-3 rounded-md">
          El KPI ya cuenta con anotaciones. <b>Solo se podrá editar Meta.</b>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3">
        {/* nombre */}
        <div className="col-span-1 md:col-span-3 mr-6 my-2 ">
          {renderInput(
            "nombre",
            "text",
            "Nombre del Indicador a Evaluar",
            "Nombre del indicador",
            "",
            [],
            tieneAnotaciones()
          )}
        </div>
        {/* descripcion */}
        <div className="col-span-1 md:col-span-3 mr-6 my-2 ">
          {renderInput(
            "descripcion",
            "text",
            "Breve descripción del indicador",
            "Breve descripción del indicador",
            "",
            [],
            tieneAnotaciones()
          )}
        </div>
        {/* metrica */}
        <div className="col-span-1 mr-6 my-2 ">
          {renderInput(
            "metrica",
            "select",
            "Métricas a evaluar",
            "",
            "",
            metricas,
            tieneAnotaciones()
          )}
          <div className="flex items-centers mt-4 gap-4">
            <p>Activar escala</p>
            <ToggleSwitch
              className="mr-2"
              checked={activarEscalaChecked}
              onChange={switchChangeHandler}
            />
          </div>
        </div>
        {/* meta */}
        <div className="col-span-1 mr-6 my-2 ">
          {renderInput("meta", "text", "Meta")}
        </div>
        {/* estado */}
        {!!kpiLocal.id && (
          <div className="col-span-1 mr-6 my-2 ">
            {renderInput(
              "vigente",
              "select",
              "Estado",
              null,
              null,
              ESTADO_OPTIONS,
              false,
              true
            )}
          </div>
        )}
        {/* tabla de escala */}
        <div className="col-span-2"> {renderTablaEscala()}</div>
        {/* periodicidad */}
        <div className="col-span-1 md:col-span-3 mr-6 my-2">
          {renderInput(
            "periodo",
            "select",
            "Período o Frecuencia",
            "",
            "",
            periodos,
            tieneAnotaciones()
          )}
        </div>
        <div className="col-span-2 mt-4 flex justify-start">
          <button className="bg-sky-500 border-2 border-sky-500 rounded px-4 py-1 text-white">
            Guardar indicador
          </button>
        </div>
      </div>
    </form>
  );
}
export default KpiForm;
