import React from "react";
import Sidebar from "src/modules/app/components/Sidebar";
import RootRoutes from "src/root/rootRoutes";
import "react-datepicker/dist/react-datepicker.css";
import "../css/style.css";

function AppPage() {
  return (
    <div className="flex flex-1x flex-rowx h-screen">
      <Sidebar></Sidebar>
      <div className="flex flex-col w-full overflow-auto">
        <div className="flex-grow bg-zinc-50 overflow-autoxx pb-16">
          <RootRoutes></RootRoutes>
        </div>
      </div>
    </div>
  );
}

export default AppPage;
