import { ProfileImage } from "./ProfileImage";
import { ProfileImageMedium } from "./ProfileImageMedium";

function PersonPhoto({ image, size = 12 }) {
  return (
    <>
      {!image && <ProfileImage className="p-2" />}
      {!!image && (
        <div className={`w-${size} border bg-slate-200 rounded-md`}>
          <img
            className={`object-cover h-${size} w-${size} rounded-md`}
            src={image}
            alt={`Foto colaborador`}
          />
        </div>
      )}
    </>
  );
}

export default PersonPhoto;
