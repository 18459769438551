import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    showSidebar: true,
    sidebarCollapse: true,
    title: "",
    titleMenu: [],
    select_modal: { open: false },
  },
  reducers: {
    showSidebar: (state, { payload = {} }) => {
      state.showSidebar = payload;
      state.sidebarCollapse = true;
    },
    sidebarCollapse: (state, { payload = {} }) => {
      state.sidebarCollapse = payload;
    },
    setTitle: (state, { payload = {} }) => {
      state.title = payload;
      state.titleMenu = [];
    },
    setTitleMenu: (state, { payload = {} }) => {
      state.titleMenu = payload;
    },
    setSelectModal: (state, { payload = {} }) => {
      state.select_modal = payload;
    },
  },
});

export const commonActions = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
