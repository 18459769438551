import React, { useEffect, useState } from "react";
import { TrashFill } from "react-bootstrap-icons";
import Input from "src/modules/common/components/Input";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { beneficioActions } from "../handlers/redux";

export function BeneficioDetailView(props) {
  const {
    beneficio = {},
    setOpen = () => {},
    open = false,
    onSubmit = () => {},
    idx = null,
  } = props;
  const [beneficioLocal, setBeneficioLocal] = useState({ ...beneficio });
  const [checked, setChecked] = useState(!beneficioLocal.vigente);
  // Los campos de requisitos que iniciaran al mostrar el modal
  const [requisitos, setRequisitos] = useState([
    {
      descripcion: "",
    },
    {
      descripcion: "",
    },
  ]);

  const getColorEstado = (vigente) => {
    return vigente ? "bg-green-500" : "bg-red-500";
  };

  return (
    <>
      <div className="flex justify-center items-center w-full mt-1">
        <div className="flex flex-col w-full md:w-11/12">
          <div className="grid grid-cols-1 md:grid-cols-12 w-full">
            <div className="col-span-1 md:col-span-12 w-full">
              <div className="flex flex-row gap-x-6 border-b mb-4">
                <div className="text-lg font-semibold">Estado</div>
                <div
                  className={`px-2 py-1 text-sm text-white ${getColorEstado(
                    beneficioLocal.vigente
                  )} rounded-md mb-4 w-2/12 flex justify-center`}
                >
                  {beneficioLocal.vigente ? "Vigente" : "No vigente"}
                </div>
              </div>

              <form className="w-full">
                <div className="grid grid-cols-1 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="text-sm font-bold">Nombre</div>
                    <h1 className="px-2 py-3 text-sm w-full">
                      {beneficioLocal.nombre}
                    </h1>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="text-sm font-bold">Descripción</div>
                    <h1 className="px-2 py-3 text-sm w-full">
                      {beneficioLocal.descripcion}
                    </h1>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm mt-4 font-bold">Requisitos</div>{" "}
                  {beneficioLocal.requisitos.map((requisito, idx) => (
                    <div key={idx} className="flex flex-row gap-2 w-full">
                      <h1 className="px-2 py-3 text-sm w-full">
                        {requisito.descripcion || ""}
                      </h1>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
