import dayjs from "dayjs";

const getYear = (date) => {
  if (!date) {
    return "";
  }
  return dayjs(date.slice(0, 10)).year();
};

export const getAnhosEvaluaciones = (evaluaciones) => {
  if (!evaluaciones) {
    return [];
  }
  let anhos = [];
  const anhosEvaluaciones = evaluaciones.map((evaluacion, idx) =>
    getYear(evaluacion.fecha_finalizacion)
  );
  anhos = anhosEvaluaciones
    .filter((evalYear) => !!evalYear)
    .map((evalYear, idx) => ({
      value: evalYear,
      label: evalYear,
    }));
  const defaultOption = {
    value: "",
    label: "Todos los años",
  };
  // eliminanos duplicados
  anhos = [...new Map(anhos.map((item) => [item["value"], item])).values()];
  anhos.unshift(defaultOption);
  //
  return anhos;
};
