import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const objetivoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    asignados: async () => {
      try {
        const asignados = await axios.get(
          `${API_URL}/objetivo-cargo/asignados/`,
          {
            headers,
          }
        );
        return asignados.data;
      } catch (error) {
        console.log("asignados error", error);
        throw error;
      }
    },
    subordinados: async ({ subordinados_filter }) => {
      const {
        nombre = "",
        areaId = "",
        sucursalId = "",
        departamentoId = "",
      } = subordinados_filter;
      try {
        const subordinados = await axios.get(
          `${API_URL}/objetivo-cargo/subordinados/?search=${nombre}&area_id=${areaId}&sucursal_id=${sucursalId}&departamento_id=${departamentoId}`,
          {
            headers,
          }
        );
        return subordinados.data;
      } catch (error) {
        console.log("subordinados error", error);
        throw error;
      }
    },
    subordinado: async ({ id }) => {
      try {
        const subordinado = await axios.get(
          `${API_URL}/objetivo-cargo/subordinado/${id}/detail`,
          {
            headers,
          }
        );
        return subordinado.data;
      } catch (error) {
        console.log("subordinado error", error);
        throw error;
      }
    },
    colaboradorObjetivosUpdate: async ({ id, objetivos }) => {
      try {
        const response = await axios.post(
          `${API_URL}/objetivo-cargo/subordinado/${id}/update-objetivos`,
          { objetivos: objetivos },
          { headers }
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    objetivoCargoColaboradorHistorial: async ({ id }) => {
      try {
        const response = await axios.get(
          API_URL + `/objetivo-cargo/${id}/history`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
  };
};
