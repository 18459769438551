import React from "react";
import { ProfileImageSmall } from "./ProfileImageSmall";

export const ProfileInfoCard = ({
  key,
  name = "Nombre del colaborador",
  description = "",
  children,
  image,
}) => {
  return (
    <div
      className="flex flex-row gap-2 items-center flex-1 profile-namex"
      title={name}
    >
      {!image && <ProfileImageSmall />}
      {!!image && (
        <div className="w-14 bg-slate-200 rounded-md">
          <img
            className="object-cover h-14 w-14 rounded-md"
            src={image}
            alt={`pfp-${key}`}
          />
        </div>
      )}
      <div className="flex flex-col gap-1 profile-namex">
        <div className="text-sm font-bold w-36 h-12x whitespace-nowrap overflow-hidden text-ellipsis">
          {name}
        </div>
        {!!description && (
          <div className="text-sm w-36 whitespace-nowrap overflow-hidden text-ellipsis">
            {description}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
