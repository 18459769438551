import { createSlice } from "@reduxjs/toolkit";

const kpiSlice = createSlice({
  name: "kpi",
  initialState: {
    kpis: [],
    kpis_loading: false,
    //
    colaboradorKpisNoVigentes: [],
    colaboradorKpisNoVigentes_checked: [],
    colaboradorKpisNoVigentes_count: 0,
    colaboradorKpisNoVigentes_filter: {},
    colaboradorKpisNoVigentes_loading: false,
    //
    subordinados: { result: [] },
    subordinados_checked: [],
    subordinados_filter: {},
    subordinados_delete: [],
    subordinados_loading: false,
    //
    subordinado: {},
    kpi: {},
    kpi_loading: false,
    kpi_create_loading: false,
    kpi_update_loading: false,
    kpi_delete_loading: false,
    //
    kpi_historial: [],
    kpi_historial_loading: false,
    //
    kpi_anotaciones: {},
    anotacion: {},
    anotacion_loading: false,
    anotaciones: [],
    anotaciones_filter: {},
    anotaciones_loading: false,
    anotaciones_checked: [],
    //
    metricas: [],
    periodos: [],
    metricas_loading: false,
    periodos_loading: false,
  },
  reducers: {
    resetCheckKpiNoVigente: (state) => {
      state.colaboradorKpisNoVigentes_checked = [];
    },
    setKpiNoVigentesChecked: (state, { payload }) => {
      state.colaboradorKpisNoVigentes_checked = payload;
    },
    checkKpiNoVigente: (state, { payload: { kpi, checked = true } }) => {
      state.colaboradorKpisNoVigentes_checked =
        state.colaboradorKpisNoVigentes_checked.filter(
          (kpinv) => kpinv.id !== kpi.id
        );
      if (!!checked) {
        state.colaboradorKpisNoVigentes_checked.push(kpi);
      }
    },
    getKpiHistorial: (state, { payload }) => {
      state.kpi_historial_loading = true;
    },
    setKpiHistorial: (state, { payload }) => {
      state.kpi_historial = payload;
      state.kpi_historial_loading = false;
    },
    getKpi: (state, { payload }) => {
      state.kpi_loading = true;
    },
    setKpi: (state, { payload }) => {
      state.kpi = payload;
      state.kpi_loading = false;
    },
    kpiCreate: (state, { payload = {} }) => {
      state.kpi_create_loading = true;
    },
    kpiCreateSuccess: (state) => {
      state.kpi_create_loading = false;
    },
    kpiUpdate: (state, { payload = {} }) => {
      state.kpi_update_loading = true;
    },
    kpiUpdateSuccess: (state) => {
      state.kpi_update_loading = false;
    },
    kpiDelete: (state, { payload = {} }) => {
      state.kpi_delete_loading = true;
    },
    kpiDeleteSuccess: (state) => {
      state.kpi_delete_loading = false;
    },
    // NO VIGENTES
    getColaboradorKpisNoVigentes: (state, { payload }) => {
      state.colaboradorKpisNoVigentes_loading = true;
    },
    setColaboradorKpisNoVigentesFilter: (state, { payload }) => {
      state.colaboradorKpisNoVigentes_filter = payload;
    },
    setColaboradorKpisNoVigentes: (state, { payload }) => {
      state.colaboradorKpisNoVigentes = payload.results;
      state.colaboradorKpisNoVigentes_count = payload.count;
      state.colaboradorKpisNoVigentes_loading = false;
    },
    setColaboradorKpisNoVigentesSimple: (state, { payload }) => {
      const { results, count } = payload;
      state.colaboradorKpisNoVigentes = results;
      state.colaboradorKpisNoVigentes_count = count;
    },

    // ANOTACION
    setAnotacion: (state, { payload }) => {
      state.anotacion = payload;
    },
    setAnotacionesFilter: (state, { payload }) => {
      state.anotaciones_filter = payload;
    },
    createAnotacion: (state) => {
      state.anotacion_loading = true;
    },
    createAnotacionComplete: (state) => {
      state.anotacion_loading = false;
      state.anotacion = { valor: "", metrica: "", anotacion: "" };
    },
    updateAnotacion: (state) => {
      state.anotacion_loading = true;
    },
    updateAnotacionComplete: (state) => {
      state.anotacion_loading = false;
      state.anotacion = { id: null, valor: "", metrica: "", anotacion: "" };
    },
    getAnotaciones: (state, { payload }) => {
      state.kpi_anotaciones = payload;
      state.anotaciones_loading = true;
    },
    setAnotaciones: (state, { payload }) => {
      state.anotaciones = payload;
      state.anotaciones_loading = false;
    },
    checkAnotacion: (state, { payload: { anotacion, checked = true } }) => {
      state.anotaciones_checked = state.anotaciones_checked.filter(
        (id) => id !== anotacion.id
      );
      if (!!checked) {
        // @ts-ignore
        state.anotaciones_checked.push(anotacion.id);
      }
      // state.colaboradores_loading = false;
    },
    resetCheckAnotaciones: (state) => {
      state.anotaciones_checked = [];
      // state.colaboradores_loading = false;
    },
    deleteAnotacion: (state) => {
      state.anotacion_loading = true;
      state.anotaciones_checked = [];
    },
    deleteAnotaciones: (state) => {
      state.anotaciones_loading = true;
    },
    deleteAnotacionComplete: (state, { payload }) => {
      state.anotacion_loading = false;
      state.anotacion = [];
    },
    deleteAnotacionesComplete: (state, { payload }) => {
      state.anotaciones_loading = false;
      state.anotaciones_checked = [];
    },
    // KPIS
    kpis: (state, { payload = {} }) => {
      state.kpis_loading = true;
    },
    setKpis: (state, { payload = {} }) => {
      state.kpis = payload.data.results;
    },
    //
    subordinados: (state, { payload = {} }) => {
      state.subordinados_loading = true;
    },
    setSubordinados: (state, { payload = {} }) => {
      state.subordinados = payload.data.results;
    },
    setSubordinadosFilter: (state, { payload = {} }) => {
      state.subordinados_filter = payload;
    },
    subordinado: (state, { payload = {} }) => {
      state.subordinado = payload;
    },
    setSubordinado: (state, { payload = {} }) => {
      state.subordinado = payload;
    },
    metricas: (state) => {
      state.metricas_loading = true;
    },
    //
    periodos: (state, { payload }) => {
      state.periodos_loading = true;
    },
    setMetricas: (state, { payload }) => {
      state.metricas = payload.metricas;
      state.metricas_loading = false;
    },
    setPeriodos: (state, { payload }) => {
      state.periodos = payload.periodos;
      state.periodos_loading = false;
    },
    //
    colaboradorUpdateKpis: (state, { payload = {} }) => {
      // state.colaboradorKpis_loading = true;
    },
    colaboradorUpdateKpisComplete: (state, { payload }) => {
      // state.colaboradorKpis_loading = false;
    },
  },
});
export const kpiActions = kpiSlice.actions;
export const kpiReducer = kpiSlice.reducer;
