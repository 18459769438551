import { createSlice } from "@reduxjs/toolkit";

const objetivoCargoSlice = createSlice({
  name: "objetivoCargo",
  initialState: {
    objetivosCargo: [],
    objetivosCargo_loading: false,
    objetivo_cargo_historial: [],
    objetivo_cargo_historial_loading: false,
    //
    subordinados: { result: [] },
    subordinados_checked: [],
    subordinados_filter: {},
    subordinados_delete: [],
    subordinados_loading: false,
    //
    subordinado: {},
  },
  reducers: {
    getObjetivosCargo: (state, { payload = {} }) => {
      state.objetivosCargo_loading = true;
    },
    setObjetivosCargo: (state, { payload = {} }) => {
      state.objetivosCargo = payload.data.results;
      state.objetivosCargo_loading = false;
    },
    getObjetivoCargoHistorial: (state, { payload }) => {
      state.objetivo_cargo_historial_loading = true;
    },
    setObjetivoCargoHistorial: (state, { payload }) => {
      state.objetivo_cargo_historial = payload;
      state.objetivo_cargo_historial_loading = false;
    },
    //
    getSubordinados: (state, { payload = {} }) => {
      state.subordinados_loading = true;
    },
    setSubordinados: (state, { payload = {} }) => {
      state.subordinados = payload.data.results;
    },
    setSubordinadosFilter: (state, { payload = {} }) => {
      state.subordinados_filter = payload;
    },
    getSubordinado: (state, { payload = {} }) => {
      state.subordinado = payload;
    },
    setSubordinado: (state, { payload = {} }) => {
      state.subordinado = payload;
    },
    colaboradorUpdateObjetivos: (state, { payload = {} }) => {
      // state.colaboradorObjetivos_loading = true;
    },
    colaboradorUpdateObjetivosComplete: (state, { payload }) => {
      // state.colaboradorObjetivos_loading = false;
    },
  },
});

export const objetivoCargoActions = objetivoCargoSlice.actions;
export const objetivoCargoReducer = objetivoCargoSlice.reducer;
