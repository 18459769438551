// @ts-nocheck
import React, { useState } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

const frecuenciasTemplate = [
  {
    nombre: "Siempre",
    descripcion:
      "En todas las ocasiones se observa esta conducta. Equivalente a excelente.",
  },
  {
    nombre: "Constantemente",
    descripcion: "La mayoría de las veces se observa. Equivalente a muy buena",
  },
  {
    nombre: "Frecuentemente",
    descripcion: "Algunas veces se observa esta conducta. Equivalente a buena",
  },
  {
    nombre: "En Ocasiones",
    descripcion: "Pocas veces se observa la conducta. Equivalente a regular",
  },
  {
    nombre: "Nunca",
    descripcion: "No se observa la conducta.",
  },
];

export const EvaluacionTips = ({
  calibrador = {},
  estado = "",
  frecuencias = frecuenciasTemplate,
  extraClass = "",
  antiguedad = "6",
}) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className={`border border-zinc-200 rounded ${extraClass}`}>
      {/* collapse header */}
      <div className="flex flex-row items-center bg-zinc-200 bg-opacity-50 p-3 gap-3">
        <div className="flex-grow">
          <div className="text-md">Tips e indicaciones antes de evaluar</div>
        </div>
        <div
          className="w-3  cursor-pointer "
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {!!collapse && <CaretDownFill />}
          {!collapse && <CaretUpFill />}
        </div>
      </div>
      {/* collapse body */}
      {!collapse && (
        <div className="flex flex-col gap-5 px-6 py-4">
          {/* lista frecuencias */}
          <div className="flex flex-col gap-3">
            <div className="text-sm font-bold">Tipo de frecuencia</div>
            <div className="ml-4">
              <ul className="list-none text-sm flex flex-col gap-4 ">
                {frecuencias.map((frecuencia) => (
                  <li key={frecuencia.id}>
                    <FrecuenciaItem frecuencia={frecuencia} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* lista tips */}
          <div className="flex flex-col gap-3">
            <div className="text-sm font-bold">Indicaciones</div>
            <div className="ml-4">
              <ul className="list-decimal text-sm flex flex-col gap-4 ">
                <li>
                  <div className="text-zinc-500 text-md">
                    Evalúe el rendimiento de la persona, no la importancia del
                    puesto, ni características personales de la misma.
                  </div>
                </li>
                <li>
                  <div className="text-zinc-500 text-md">
                    Revise que las personas evaluadas, las áreas o departamentos
                    a los que pertenecen, sus cargos, y sus indicadores de
                    gestión, correspondan a las mismas.
                  </div>
                </li>
                <li>
                  <div className="text-zinc-500 text-md">
                    Sólo formarán parte de este evaluación, los colaboradores
                    con un mínimo de {antiguedad} meses de antigüedad en la
                    empresa.
                  </div>
                </li>
                {!!calibrador.nombre && (
                  <li>
                    <div className="text-zinc-500 text-md">
                      Este formulario tiene como calibrador asignado a{" "}
                      {calibrador.nombre}. (Si usted cree que esta o cualquier
                      otra información del formulario está configurada de forma
                      incorrecta, o no le compete a su cargo, NO GUARDE EL
                      FORMULARIO!, y cómuniquese con RRHH para la generación de
                      un nuevo formulario correcto.)
                    </div>
                  </li>
                )}
                <li>
                  <div className="text-zinc-500 text-md">
                    No deje de marcar ningún ítem!
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {!!estado && (
            <div className="flex flex-row gap-3">
              <button className="rounded bg-sky-500 text-white px-3 py-1 text-sm">
                Editar
              </button>
              <button className="rounded bg-red-500 text-white px-3 py-1  text-sm">
                Eliminar
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const FrecuenciaItem = ({ frecuencia }) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="text-zinc-500 text-md">{frecuencia.nombre}: </div>
      <div className="">{frecuencia.descripcion}</div>
    </div>
  );
};
