import { Search } from "react-bootstrap-icons";

function HistorialEvaluacionesHeader({
  searchValue = "",
  onSearch,
  selectOptions = [],
  onSelect,
}) {
  const onSelectLocal = (e) => {
    const value = e.target.value;
    if (onSelect) {
      onSelect(value);
    }
  };
  //
  return (
    <div className="flex items-center justify-between gap-4 mt-4">
      <div className="flex justify-between items-center bg-slate-200 rounded-md p-2 flex-1">
        <input
          type="search"
          placeholder="Buscar por nombre..."
          className="bg-slate-200 outline-none w-full"
          value={searchValue}
          onChange={onSearch}
        />
        <Search></Search>
      </div>
      <div>
        <select
          onChange={onSelectLocal}
          className="border rounded-md p-2 bg-white"
        >
          {selectOptions.length > 0 &&
            selectOptions.map((opt, idx) => (
              <option key={`opt-${idx}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default HistorialEvaluacionesHeader;
