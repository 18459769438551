import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const feedbackApis = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    feedbacksJefeDirecto: async (feedbacks_filter) => {
      const {
        nombre = "",
        ordering = "estado",
        pagination = {},
      } = feedbacks_filter;
      const { page = 1, pageSize = 50 } = pagination;
      let url = `${URL_BASE}/feedback/feedbacks-jefe-directo?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (ordering) {
        url = url + `&ordering=${ordering}`;
      }
      try {
        const response = await axios.get(url, {
          headers,
        });
        console.log("feedbacks list api", response);
        return response.data;
      } catch (error) {
        throw console.error();
      }
    },
    feedbacksColaborador: async (feedbacks_filter) => {
      const {
        nombre = "",
        ordering = "estado",
        pagination = {},
      } = feedbacks_filter;
      const { page = 1, pageSize = 50 } = pagination;
      let url = `${URL_BASE}/feedback/feedbacks-colaborador?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (ordering) {
        url = url + `&ordering=${ordering}`;
      }
      try {
        const response = await axios.get(url, {
          headers,
        });
        console.log("feedbacks list api", response);
        return response.data;
      } catch (error) {
        throw console.error();
      }
    },
    feedback: async (idFeedback) => {
      let url = `${URL_BASE}/feedback/${idFeedback}/detail`;
      try {
        const response = await axios.get(url, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw console.error();
      }
    },
    createFeedback: async (params) => {
      const url = URL_BASE + "/feedback/create";
      try {
        const response = await axios.post(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    updateFeedback: async (params) => {
      const url = URL_BASE + "/feedback/update";
      try {
        const response = await axios.post(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    detailColaboradorFeedback: async (idColaboradorFeedback) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/colaborador/${idColaboradorFeedback}/detail`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detailFeedbackNomina: async (params) => {
      const { idFeedback, idJefeDirecto, idColaborador } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/feedback/${idFeedback}/jefe_directo/${idJefeDirecto}/colaborador/${idColaborador}/detail`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    createFeedbackNomina: async (params) => {
      const { idFeedback, idJefeDirecto, idColaborador, feedback_nomina } =
        params;
      try {
        const response = await axios.post(
          URL_BASE +
            `/feedback/${idFeedback}/jefe_directo/${idJefeDirecto}/colaborador/${idColaborador}/create`,
          feedback_nomina,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    deleteFeedbackNomina: async (idFeedbackNomina) => {
      try {
        const response = await axios.delete(
          URL_BASE + `/feedback/feedback_nomina/${idFeedbackNomina}/delete`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    competenciasGenerales: async (idFeedback) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/${idFeedback}/competencias-generales`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasAreas: async (params) => {
      const { idFeedback, idColaborador } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/feedback/${idFeedback}/colaborador/${idColaborador}/competencias-areas`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasCargos: async (params) => {
      const { idFeedback, idColaborador } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/feedback/${idFeedback}/colaborador/${idColaborador}/competencias-cargos`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    kpisColaborador: async (idColaborador) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/colaborador/${idColaborador}/kpis-colaborador`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    subordinadosSeleccionados: async (idFeedback) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/${idFeedback}/subordinados_seleccionados`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
  };
};
