import React, { useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import AppPage from "src/modules/app/pages/AppPage";
import { authRoutes } from "src/modules/auth/handlers/routes";
import Screen from "src/modules/common/components/Screen";
import { browserHistory, rootStore } from "src/root/rootStore";
import "../css/App.css";
import ScrollToTop from "../components/ScrollToTop";
import ProtectedRoute from "./ProtectedRoute";

const routes = [...authRoutes];

function App() {
  //
  return (
    <ReduxRouter
      history={browserHistory}
      store={rootStore}
      children={
        <Screen>
          <ScrollToTop>
            <Routes>
              {routes.map((route, idx) => (
                <Route key={idx} {...route} />
              ))}
              <Route element={<ProtectedRoute />}>
                <Route path="/*" element={<AppPage />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </Screen>
      }
    ></ReduxRouter>
  );
}

export default App;
