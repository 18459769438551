import React from "react";
import KPIsPage from "src/modules/kpi/pages/KPIsPage";
import ColaboradorDetailPage from "../pages/ColaboradorDetailPage";

export const kpiRoutes = [
  {
    path: "/kpis/",
    element: <KPIsPage />,
  },
  {
    path: "/kpis/colaborador/:id",
    element: <ColaboradorDetailPage />,
  },
];
