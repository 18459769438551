import React from "react";

export const Card = ({
  bgColor = "bg-white",
  className = "",
  children,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col gap-2 border border-zinc-300 rounded-md ${bgColor} p-3 ${className}`}
    >
      {children}
    </div>
  );
};
