import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { Card } from "src/modules/common/components/Card";
import ColaboradorRow from "src/modules/common/components/ColaboradorRow";
import Input from "src/modules/common/components/Input";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { colaboradorActions } from "../handlers/redux";

const CustomSelect = ({ onChange, options = [], title }) => {
  return (
    <select
      className="w-full p-2 bg-white border rounded-lg"
      onChange={onChange}
    >
      <option value={""}>{title}: Todos</option>
      {options.length > 0 &&
        options.map((item, index) => (
          <option key={`dop-${index}`} value={item.id}>
            {item.nombre}
          </option>
        ))}
    </select>
  );
};

function ColaboradoresPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState("");
  const [areaId, setAreaId] = useState("");
  const [sucursalId, setSucursalId] = useState("");
  const [departamentoId, setDepartamentoId] = useState("");
  const colaboradores =
    useSelector((state) => state.colaborador?.colaboradores) || [];
  const nuevosIngresos =
    useSelector((state) => state.colaborador?.nuevos_ingresos) || [];
  const nuevos_ingresos_loading =
    useSelector((state) => state.colaborador?.nuevos_ingresos_loading) || [];
  const colaboradores_filter =
    useSelector((state) => state.colaborador?.colaboradores_filter) || [];
  const { nombre = "" } = colaboradores_filter;
  const areas = useSelector((state) => state.colaborador?.areas) || [];
  const sucursales =
    useSelector((state) => state.colaborador?.sucursales) || [];
  const departamentos =
    useSelector((state) => state.colaborador?.departamentos) || [];
  const onChangeText = (event) => {
    setNameFilter(event.target.value);
  };

  const onChangeArea = (e) => {
    setAreaId(e.target.value);
  };
  const onChangeSucursal = (e) => {
    setSucursalId(e.target.value);
  };
  const onChangeDepartamento = (e) => {
    setDepartamentoId(e.target.value);
  };

  useEffect(() => {
    dispatch(colaboradorActions.colaboradores());
    dispatch(colaboradorActions.getNuevosIngresos());
    dispatch(colaboradorActions.areas());
    dispatch(colaboradorActions.sucursales());
    dispatch(colaboradorActions.departamentos());
  }, []);

  useEffect(() => {
    dispatch(
      colaboradorActions.setColaboradoresFilter({
        nombre: nameFilter,
        areaId: areaId,
        sucursalId: sucursalId,
        departamentoId: departamentoId,
      })
    );
  }, [nameFilter, areaId, sucursalId, departamentoId]);

  const toDetail = (id) => {
    navigate(`/colaboradores/${id}`);
  };

  return (
    <>
      <Header
        titulo={"Colaboradores de la empresa"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
      ></Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        <div className="flex flex-col gap-2 ">
          <div className="text-lg font-bold">Nuevos ingresos:</div>
          <div className="flex flex-row gap-2  items-center">
            {nuevosIngresos.length > 0 &&
              nuevosIngresos.map((ni, idx) => (
                <>
                  {!ni.foto_empleado && (
                    <ProfileImage
                      key={`ni-${idx}`}
                      className="h-12 w-12 p-2"
                      title={`${ni.nombre} ${ni.apellido}`}
                    ></ProfileImage>
                  )}
                  {!!ni.foto_empleado && (
                    <div
                      key={`ni-${idx}`}
                      className="w-12 bg-slate-200 rounded-md"
                    >
                      <img
                        className="object-cover h-12 w-12 rounded-md"
                        src={ni.foto_empleado}
                        alt={`Foto Colaborador`}
                        title={`${ni.nombre} ${ni.apellido}`}
                      />
                    </div>
                  )}
                </>
              ))}
            {nuevosIngresos.length < 1 && (
              <div className="bg-white p-2 rounded border border-slate-200">
                Sin nuevos ingresos
              </div>
            )}
          </div>
        </div>
        <Card className=" gap-4 p-4">
          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">Colaboradores</div>

            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-1">
                <BuscarInput
                  onChange={onChangeText}
                  value={nombre}
                  placeholder={"Buscar por nombre o cargo"}
                ></BuscarInput>
              </div>
              <div className="col-span-1">
                <CustomSelect
                  onChange={onChangeSucursal}
                  options={sucursales}
                  title={"Sucursales"}
                />
              </div>
              <div className="col-span-1">
                <CustomSelect
                  onChange={onChangeArea}
                  options={areas}
                  title={"Áreas"}
                />
              </div>
              <div className="col-span-1">
                <CustomSelect
                  onChange={onChangeDepartamento}
                  options={departamentos}
                  title={"Departamentos"}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <div className="border-b bg-zinc-400"></div>
              {colaboradores.length > 0 &&
                colaboradores.map((colaborador, idx) => (
                  <ColaboradorRow
                    key={`colab-${idx}`}
                    colaborador={colaborador}
                    onClick={() => toDetail(colaborador.id)}
                  ></ColaboradorRow>
                ))}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default ColaboradoresPage;
