import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { feedbackActions } from "./redux";
import { feedbackApis } from "./apis";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { push } from "@lagunovsky/redux-react-router";

function* feedbacksJefeDirectoSaga({ payload }) {
  const token = getToken();
  const feedbacks_jefe_directo_filter = yield select(
    (state) => state.feedback.feedbacks_jefe_directo_filter
  );
  try {
    const data = yield call(
      feedbackApis(token).feedbacksJefeDirecto,
      feedbacks_jefe_directo_filter
    );
    yield put(feedbackActions.setFeedbacksJefeDirecto(data.results));
    yield put(feedbackActions.setFeedbacksJefeDirectoCount(data.count));
  } catch (error) {
    console.log(error);
    yield put(feedbackActions.setFeedbacksJefeDirecto([]));
    yield put(feedbackActions.setFeedbacksJefeDirectoCount(0));
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* feedbacksColaboradorSaga({ payload }) {
  const token = getToken();
  const feedbacks_colaborador_filter = yield select(
    (state) => state.feedback.feedbacks_colaborador_filter
  );
  try {
    const data = yield call(
      feedbackApis(token).feedbacksColaborador,
      feedbacks_colaborador_filter
    );
    yield put(feedbackActions.setFeedbacksColaborador(data.results));
    yield put(feedbackActions.setFeedbacksColaboradorCount(data.count));
  } catch (error) {
    console.log(error);
    yield put(feedbackActions.setFeedbacksColaborador([]));
    yield put(feedbackActions.setFeedbacksColaboradorCount(0));
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* feedbackDetailSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).feedback, payload);
    yield put(feedbackActions.setFeedback(data));
  } catch (error) {
    console.log(error);
    yield put(feedbackActions.setFeedback({}));
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* createFeedbackSaga({ payload }) {
  const token = getToken();
  const feedback = yield select((state) => state.feedback.feedback);
  try {
    const data = yield call(feedbackApis(token).createFeedback, feedback);
    yield put(feedbackActions.setFeedback({}));
    SuccessToast({ message: "Feedback creado correctamente!" });
    yield put(push("/feedback"));
  } catch (error) {
    console.log(error);
    yield put(feedbackActions.setFeedback({}));
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* updateFeedbackSaga({ payload }) {
  const token = getToken();
  const feedback = yield select((state) => state.feedback.feedback);
  try {
    const data = yield call(feedbackApis(token).updateFeedback, feedback);
    yield put(feedbackActions.setFeedback({}));
    SuccessToast({ message: "Feedback actualizado correctamente!" });
    yield put(push("/feedback"));
  } catch (error) {
    console.log(error);
    yield put(feedbackActions.setFeedback({}));
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* deleteFeedbackNominaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).deleteFeedbackNomina, payload);
    yield put(feedbackActions.getFeedbacksJefeDirecto({}));
    SuccessToast({ message: "Feedback eliminado correctamente!" });
  } catch (error) {
    console.log(error);
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* getJefeDirectoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      feedbackApis(token).detailColaboradorFeedback,
      payload
    );
    yield put(feedbackActions.setJefeDirecto(data));
  } catch (error) {
    yield put(feedbackActions.setJefeDirecto({}));
  }
}

function* getColaboradorFeedbackSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      feedbackApis(token).detailColaboradorFeedback,
      payload
    );
    yield put(feedbackActions.setColaboradorFeedback(data));
  } catch (error) {
    yield put(feedbackActions.setColaboradorFeedback({}));
  }
}

function* getFeedbackNominaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).detailFeedbackNomina, payload);
    yield put(feedbackActions.setFeedbackNomina(data));
  } catch (error) {
    yield put(feedbackActions.setFeedbackNomina({}));
  }
}

function* createFeedbackNominaSaga({ payload }) {
  const token = getToken();
  const feedback_nomina = yield select(
    (state) => state.feedback.feedback_nomina
  );
  if (feedback_nomina.feedback_detalles.length === 0) {
    ErrorToast({ message: "Se debe agregar al menos un feedback a realizar." });
    return;
  }
  // const { feedback_detalles = [] } = feedback_nomina;
  const params = {
    ...payload,
    feedback_nomina,
  };
  try {
    const data = yield call(feedbackApis(token).createFeedbackNomina, params);
    yield put(feedbackActions.getFeedbackNomina(payload));
    SuccessToast({ message: "Feedback realizado correctamente!" });
    yield put(push("/feedback"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al guardar el Feedback" });
  }
}

function* competenciasGeneralesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).competenciasGenerales, payload);
    yield put(feedbackActions.setCompetenciasGenerales(data));
  } catch (error) {
    yield put(feedbackActions.setCompetenciasGenerales([]));
  }
}

function* competenciasAreasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).competenciasAreas, payload);
    yield put(feedbackActions.setCompetenciasAreas(data));
  } catch (error) {
    yield put(feedbackActions.setCompetenciasAreas([]));
  }
}

function* competenciasCargosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).competenciasCargos, payload);
    yield put(feedbackActions.setCompetenciasCargos(data));
  } catch (error) {
    yield put(feedbackActions.setCompetenciasCargos([]));
  }
}

function* kpisColaboradorSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).kpisColaborador, payload);
    yield put(feedbackActions.setKpisColaborador(data));
  } catch (error) {
    yield put(feedbackActions.setKpisColaborador([]));
  }
}

function* subordinadosSeleccionados({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      feedbackApis(token).subordinadosSeleccionados,
      payload
    );
    yield put(feedbackActions.setSubordinadosSeleccionados(data.results));
  } catch (error) {
    yield put(feedbackActions.setSubordinadosSeleccionados([]));
  }
}

export function* feedbackSagas() {
  yield takeLatest(
    feedbackActions.getFeedbacksJefeDirecto.type,
    feedbacksJefeDirectoSaga
  );
  yield takeLatest(
    feedbackActions.setFeedbacksJefeDirectoFilter.type,
    feedbacksJefeDirectoSaga
  );

  yield takeLatest(
    feedbackActions.getFeedbacksColaborador.type,
    feedbacksColaboradorSaga
  );
  yield takeLatest(
    feedbackActions.setFeedbacksColaboradorFilter.type,
    feedbacksColaboradorSaga
  );

  yield takeEvery(feedbackActions.getFeedback.type, feedbackDetailSaga);

  yield takeEvery(feedbackActions.createFeedback.type, createFeedbackSaga);

  yield takeEvery(feedbackActions.getJefeDirecto.type, getJefeDirectoSaga);

  yield takeEvery(
    feedbackActions.getColaboradorFeedback.type,
    getColaboradorFeedbackSaga
  );

  yield takeEvery(
    feedbackActions.getFeedbackNomina.type,
    getFeedbackNominaSaga
  );

  yield takeEvery(
    feedbackActions.createFeedbackNomina.type,
    createFeedbackNominaSaga
  );

  yield takeEvery(
    feedbackActions.getCompetenciasGenerales.type,
    competenciasGeneralesSaga
  );

  yield takeEvery(
    feedbackActions.getCompetenciasAreas.type,
    competenciasAreasSaga
  );

  yield takeEvery(
    feedbackActions.getCompetenciasCargos.type,
    competenciasCargosSaga
  );

  yield takeEvery(feedbackActions.getKpisColaborador.type, kpisColaboradorSaga);

  yield takeEvery(
    feedbackActions.getSubordinadosSeleccionados.type,
    subordinadosSeleccionados
  );

  yield takeEvery(feedbackActions.updateFeedback.type, updateFeedbackSaga);

  yield takeEvery(
    feedbackActions.deleteFeedbackNomina.type,
    deleteFeedbackNominaSaga
  );
}
