import { createSlice } from "@reduxjs/toolkit";

const beneficioSlice =  createSlice({
    name: "beneficio",
    initialState: {
        beneficios: [],
        beneficios_loading: false,
        beneficios_count: 0,
        beneficios_filter: {}
    },
    reducers: {
        setBeneficios: (state, {payload = {}}) => {
            state.beneficios = payload
            state.beneficios_loading = false
        },
        getBeneficios: (state, {payload = {}}) => {
            state.beneficios_loading = true
        },
        setBeneficiosCount: (state, {payload={}}) => {
            state.beneficios_count = payload
        },
        setBeneficiosFilter: (state, {payload={}}) => {
            state.beneficios_filter = payload
        }
    }
})

export const beneficioActions = beneficioSlice.actions
export const beneficioReducer = beneficioSlice.reducer