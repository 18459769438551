import React from 'react'
import { ObjetivoCargoPage } from '../pages/ObjetivoCargoPage'
import { ObjetivoCargoDetailPage } from '../pages/ObjetivoCargoDetailpage'

export const objetivoCargoRoutes = [
    {
        path: "/objetivo-cargo",
        element: <ObjetivoCargoPage/>
    },
    {
        path: "/objetivo-cargo/colaborador/:id",
        element: <ObjetivoCargoDetailPage/>
    },
]