import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";

export function ComentarioBox({ comentario, onSubmit }) {
  const [comentarioLocal, setComentarioLocal] = useState(comentario);

  const textareaRef = useRef(null);

  useEffect(() => {
    // Enfocar el textarea cuando el componente se monte
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []); // El segundo argumento [] significa que esto solo se ejecuta después del montaje inicial

  const onChange =
    (field) =>
    ({ target }) => {
      const value = target.value;
      setComentarioLocal(value);
    };

  const maxLengtComentario = 10000;

  const preOnSubmit = () => {
    onSubmit(comentarioLocal);
  };

  return (
    <div>
      <textarea
        className="w-full p-2 border-gray-300 rounded-md border h-80"
        onChange={onChange("")}
        value={comentarioLocal}
        maxLength={maxLengtComentario}
        ref={textareaRef}
      />
      <div className="w-full flex justify-end">
        <span className="text-xs text-gray-500">
          {comentarioLocal ? comentarioLocal.length : 0}/{maxLengtComentario}
        </span>
      </div>
      <PrimaryButton className={"w-full mt-2"} onClick={preOnSubmit}>Agregar</PrimaryButton>
    </div>
  );
}
