import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import { kpiActions } from "../../kpi/handlers/redux";
import { colaboradorActions } from "../handlers/redux";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { formatNumber } from "src/utils/helpers";
import HistorialEvaluacionesTable from "src/modules/common/components/HistorialEvaluacionesTable";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import HistorialEvaluacionesHeader from "src/modules/common/components/HistorialEvaluacionesHeader";
import backButton from "src/modules/common/components/images/back-arrow.png";

function ColaboradorDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const usuario = getUserFromCookie();
  const { id: colaboradorId } = params;
  const colaborador =
    useSelector((state) => state.colaborador.colaborador) || {};
  const colaborador_kpis = useSelector((state) => state.kpi.subordinado);
  const { kpis_colaborador: kpisData = [] } = colaborador_kpis;
  const [anhosFiltro, setAnhosFiltro] = useState([]);
  const colaborador_evaluaciones = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones
  );
  const colaborador_evaluaciones_filter = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones_filter
  );
  const anhos_evaluaciones = useSelector(
    (state) => state.colaborador.anhos_evaluaciones
  );
  const { nombre: nombreEvaluacion, ordering } =
    colaborador_evaluaciones_filter;
  const colaborador_evaluaciones_loading = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones_loading
  );

  const showKpiMeta = (kpi) => {
    return kpi.meta
      ? `${formatNumber(kpi.meta)}`
      : kpi.rangos.length > 0
      ? "Por escala"
      : "Sin definir";
  };
  //
  useEffect(() => {
    if (colaboradorId) {
      dispatch(colaboradorActions.colaborador(colaboradorId));
      dispatch(kpiActions.subordinado({ id: colaboradorId }));
      dispatch(
        colaboradorActions.getColaboradorEvaluaciones({
          id: colaboradorId,
        })
      );
    }
  }, []);

  const onBack = () => {
    return (
      <div
        onClick={() => navigate("/colaboradores")}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };

  return (
    <>
      <Header
        titulo={"Perfil colaborador"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
        LeftComponent={onBack}
      ></Header>
      {/* ==================== DATOS PERSONALES ==================== */}
      <div className="grid grid-cols-12 justify-between gap-4 px-12 py-4">
        {/* Foto y jefe */}
        <div className="col-span-4 flex flex-col gap-3 flex-1x">
          {!colaborador.foto_empleado && (
            <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
          )}
          {!!colaborador.foto_empleado && (
            <div className="w-28 bg-slate-200 rounded-md">
              <img
                className="object-cover h-28 w-28 rounded-md"
                src={colaborador.foto_empleado}
                alt={`Foto colaborador`}
              />
            </div>
          )}
          <div>
            <div className="font-bold">Nombre:</div>
            <div>{`${colaborador.nombre} ${colaborador.apellido}`}</div>
          </div>
          <div className="">
            <div className="font-bold">Jefe directo:</div>
            <div className="flex gap-2 text-gray-700">
              {!colaborador.jefe_directo?.foto_empleado && (
                <ProfileImage className="h-12 w-12 p-2"></ProfileImage>
              )}
              {!!colaborador.jefe_directo?.foto_empleado && (
                <div className="w-12 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-12 w-12 rounded-md"
                    src={colaborador.jefe_directo?.foto_empleado}
                    alt={`Foto Jefe`}
                  />
                </div>
              )}
              <div className="flex flex-col">
                <div>{colaborador.jefe_directo?.nombre || "Sin asignar"}</div>
                <div>
                  {colaborador.jefe_directo?.cargo?.nombre || "Sin cargo"}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Datos personales */}

        {/* ==================== DATOS LABORALES ==================== */}
        <div className="col-span-8 flex flex-col gap-2 flex-1x">
          <Card bgColor="bg-white" className="gap-4 p-4 bg-blue-4 flex-1">
            <div className="flex justify-between">
              <div className="fw-bold">Datos laborales</div>
            </div>
            {/* data cols */}
            <div className="grid grid-cols-2 gap-4 text-gray-700 text-sm">
              {/* left col */}
              <div className="col-span-1 flex flex-col gap-2">
                <div>
                  <div>Cargo:</div>
                  <div className="font-medium">
                    {colaborador.cargo?.nombre || "Sin asignar"}
                  </div>
                </div>
                <div>
                  <div>Sucursal:</div>
                  <div className="font-medium">
                    {colaborador.sucursal?.nombre || "Sin asignar"}
                  </div>
                </div>
                <div>
                  <div>Departamento:</div>
                  <div className="font-medium">
                    {colaborador.departamento?.nombre || "Sin asignar"}
                  </div>
                </div>
              </div>
              {/* right col */}
              <div className="col-span-1 flex flex-col gap-2">
                <div>
                  <div>Fecha de ingreso:</div>
                  <div className="font-medium">
                    {dayjs(colaborador.fecha_ingreso?.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </div>
                <div>
                  <div>Área:</div>
                  <div className="font-medium">
                    {colaborador.area?.nombre || "Sin asignar"}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card bgColor="bg-white" className="gap-4 p-4 bg-blue-4 flex-1">
            <div className="flex flex-col gap-2 flex-1">
              <div className="text-lg font-bold">Equipo:</div>
              <div className="flex flex-row gap-2  items-center">
                {colaborador.colaboradores?.length > 0 &&
                  colaborador.colaboradores?.slice(0, 7).map((item, idx) => (
                    <>
                      {!item.foto_empleado && (
                        <ProfileImage
                          className="h-12 w-12 p-1"
                          title={`${item.nombre} ${item.apellido}`}
                        ></ProfileImage>
                      )}
                      {!!item.foto_empleado && (
                        <div className="w-12 bg-slate-200 rounded-md">
                          <img
                            className="object-cover h-12 w-12 rounded-md"
                            src={item.foto_empleado}
                            alt={`Foto Equipo`}
                            title={`${item.nombre} ${item.apellido}`}
                          />
                        </div>
                      )}
                    </>
                  ))}
                {colaborador.colaboradores?.length < 1 && (
                  <div className="text-center w-full p-1">
                    Sin subordinados asignados
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        {/* ==================== KPIS (SOLO VISIBLE SI ES JEFE) ====================  */}
        {kpisData.length > 0 && (
          <div className="col-span-12 flex flex-col gap-4 px-12x py-4">
            <Card bgColor="bg-zinc-100" className="gap-4 p-4 bg-blue-4">
              <div className="flex justify-between">
                <div className="flex flex-row gap-2 items-center">
                  <div className="flex flex-col flex-grow text-zinc-700">
                    <div className="text-sm font-bold">KPIs Asignados</div>
                    <div className="text-sm">
                      {kpisData?.length} KPIs vinculados
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                {kpisData?.length > 0 &&
                  kpisData?.map((kpi, idx) => (
                    <Card key={`kpi-${idx}`} className="gap-3 p-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <div className="text-md font-bold">{kpi.nombre}</div>
                          <div className="text-sm">
                            {kpi.descripcion || "Sin descripción"}
                          </div>
                        </div>
                        <div>
                          <div className="text-xs">Peso:</div>
                          <div className="text-sm">
                            <input
                              type="number"
                              disabled={true}
                              // onChange={onChangePeso(idx)}
                              className="border rounded-md p-1 w-1/2"
                              value={kpi.peso || 0}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="">
                          <div className="text-xs">Tiempo:</div>
                          <div className="font-semibold">
                            {kpi.periodo_display || "Sin definir"}
                          </div>
                        </div>
                        <div className="">
                          <div className="text-xs">Tipo de metrica:</div>
                          <div className="font-semibold">
                            {kpi.metrica_display}
                          </div>
                        </div>
                        <div className="">
                          <div className="text-xs">Meta:</div>
                          <div className="flex flex-row gap-1 items-center">
                            <div className="font-semibold">
                              {showKpiMeta(kpi)}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="text-xs">Creado por:</div>
                          <div className="inline-flex gap-2 items-center">
                            {!kpi.creado_por?.foto_empleado && (
                              <div className="w-[70px] rounded-lg bg-zinc-200"></div>
                            )}
                            {!!kpi.creado_por?.foto_empleado && (
                              <div className="w-[70px] bg-slate-200 rounded-md border">
                                <img
                                  className="object-cover w-full rounded-md"
                                  src={kpi.creado_por?.foto_empleado}
                                  alt={`Foto Jefe de Lider`}
                                />
                              </div>
                            )}
                            <div className="font-semibold">
                              {kpi.creado_por
                                ? `${kpi.creado_por.nombre} ${kpi.creado_por.apellido}`
                                : "Sin registro"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
              </div>
            </Card>
          </div>
        )}
        {/* ============================== */}
        {!!colaboradorId && anhos_evaluaciones.length > 0 && (
          <div className="col-span-12 flex flex-col gap-4 px-12x py-4 mt-1">
            <div className="px-4 border rounded-md">
              <HistorialEvaluacionesTable
                evaluaciones={colaborador_evaluaciones.filter(
                  (evaluacion) => evaluacion.estado.value === 2
                )}
                colaborador={{ id: colaboradorId }}
                loading={colaborador_evaluaciones_loading}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ColaboradorDetailPage;
