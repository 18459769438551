import React from "react";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { formatNumber } from "src/utils/helpers";

export const KpiInfo = ({ kpi }) => {
  const showKpiMeta = (kpi) => {
    return kpi.meta
      ? `${formatNumber(kpi.meta)}`
      : kpi.rangos.length > 0
      ? "Por escala"
      : "Sin definir";
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="text-md font-bold">{kpi.nombre}</div>
        <div className="text-sm">{kpi.descripcion || "Sin descripción"}</div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="">
          <div className="text-xs">Tiempo:</div>
          <div className="font-semibold">
            {kpi.periodo_display || "Sin definir"}
          </div>
        </div>
        <div className="">
          <div className="text-xs">Tipo de metrica:</div>
          <div className="font-semibold">{kpi.metrica_display}</div>
        </div>
        <div className="">
          <div className="text-xs">Meta:</div>
          <div className="flex flex-row gap-1 items-center">
            {/* <div className="h-3 w-3 rounded-lg bg-sky-500"></div> */}
            <div className="font-semibold">{showKpiMeta(kpi)}</div>
          </div>
        </div>
        <div className="">
          <div className="text-xs">Creado por:</div>
          <div className="flex flex-row gap-1 items-center">
            {kpi.creado_por && (
              <div>
                {!kpi.creado_por.foto_empleado && (
                  <ProfileImage className="h-8 w-8 p-1.5"></ProfileImage>
                )}
                {!!kpi.creado_por.foto_empleado && (
                  <div className="w-8 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-8 w-8 rounded-md"
                      src={kpi.creado_por?.foto_empleado}
                      alt={`Foto Jefe de Lider`}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="font-semibold">
              {kpi.creado_por
                ? `${kpi.creado_por.nombre} ${kpi.creado_por.apellido}`
                : "Sin registro"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
