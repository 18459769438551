import React from "react";
import ProfilePage from "src/modules/common/pages/ProfilePage";
import InicioPage from "src/modules/inicio/pages/InicioPage";

export const inicioRoutes = [
  {
    path: "/inicio/",
    element: <InicioPage />,
  },
  {
    path: "/mi-perfil/",
    element: <ProfilePage />,
  },
];
