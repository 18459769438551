import React from "react";
import { FeedbackPage } from "../pages/FeedbackPage";
import { FeedbackConfiguracion } from "../pages/FeedbackConfiguracion";
import { Route, Routes } from "react-router-dom";
import { FeedbackEtapasPage } from "../pages/FeedbackEtapasPage";
import { FeedbackEtapaJefeDirecto } from "../pages/FeedbackEtapaJefeDirecto";
import { FeedbackEtapaColaborador } from "../pages/FeedbackEtapaColaborador";
import { FeedbackEtapaFinalizado } from "../pages/FeedbackEtapaFinalizado";

export const feedbackRoutes = [
  {
    path: "/feedback",
    element: <FeedbackPage />,
  },
  {
    path: "/feedback/configuracion",
    element: <FeedbackConfiguracion />,
  },
  {
    path: "/feedback/configuracion/:idFeedback",
    element: <FeedbackConfiguracion />,
  },
  {
    path: "/feedback/:idFeedback/jefe-directo/:idJefeDirecto/colaborador/:idColaborador/*",
    element: <FeedbackEtapasPage />,
  },
];

export const feedbackEtapasName = ["jefe-directo", "evaluado", "finalizado"];

export const FeedbackEtapasRoutes = () => {
  const feedbackEtapasRoutes = [
    // Estos path se concatenaran al path de <FeedbackEtapasPage />
    {
      path: feedbackEtapasName[0],
      element: <FeedbackEtapaJefeDirecto />,
    },
    {
      path: feedbackEtapasName[1],
      element: <FeedbackEtapaColaborador />,
    },
    {
      path: feedbackEtapasName[2],
      element: <FeedbackEtapaFinalizado />,
    },
  ];
  return (
    <Routes>
      {feedbackEtapasRoutes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};
