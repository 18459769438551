import dayjs from "dayjs";

export const FloatPattern = /^[0-9]*(,[0-9]{0,2})?$/;
export const FloatConSeparadorPuntoYDecimalComa = /^-?(\d{1,3})(\.\d{3})*(,\d+)?$/;
export const formatDate = (date) => {
  if (!date) {
    return "Sin especificar";
  }
  return dayjs(date.slice(0, 10)).format("DD/MM/YYYY");
};

export const areArraysEqual = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
};
