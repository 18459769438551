import { createSlice } from "@reduxjs/toolkit";

const planCarreraSlice = createSlice({
  name: "PlanCarrera",
  initialState: {
    plan_carreras: [],
    plan_carreras_loading: false,
    plan_carreras_filter: {},
    plan_carreras_count: 0,
    //
    plan_carrera: {},
    plan_carrera_loading: false,
    //
    cargos: [],
    cargos_loading: false,
    cargos_filter: {},
    cargos_count: 0,
    //
    cargo: {},
    cargo_loading: false,
  },
  reducers: {
    getPlanCarrera: (state, { payload = {} }) => {
      state.plan_carrera_loading = true;
    },
    setPlanCarrera: (state, { payload = {} }) => {
      state.plan_carrera = payload;
      state.plan_carrera_loading = false;
    },
    getPlanCarreras: (state) => {
      state.plan_carreras_loading = true;
    },
    getPlanCarrerasFilter: (state, { payload = {} }) => {
      state.plan_carreras_filter = payload;
    },
    setPlanCarreras: (state, { payload = [] }) => {
      state.plan_carreras = payload;
      state.plan_carreras_loading = false;
    },
    setPlanCarrerasCount: (state, { payload = 0 }) => {
      state.plan_carreras_count = payload;
    },
    getCargos: (state) => {
      state.cargos_loading = true;
    },
    setCargosFilter: (state, { payload = {} }) => {
      state.cargos_filter = payload;
    },
    setCargos: (state, { payload = [] }) => {
      state.cargos = payload;
      state.cargos_loading = false;
    },
    setCargosCount: (state, { payload = 0 }) => {
      state.cargos_count = payload;
    },
    //
    getCargo: (state, { payload = {} }) => {
      state.cargo_loading = true;
    },
    setCargo: (state, { payload = {} }) => {
      state.cargo = payload;
      state.cargo_loading = false;
    },
  },
});

export const planCarreraActions = planCarreraSlice.actions;
export const planCarreraReducer = planCarreraSlice.reducer;
