import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import Header from "src/modules/app/components/Header";
import DataTable from "src/modules/common/components/DataTable";
import backButton from "src/modules/common/components/images/back-arrow.png";
import SearchInput from "src/modules/common/components/SearchInput";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { equipoActions } from "src/modules/equipo/handlers/redux";
import { feedbackActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { ProfileImage } from "src/modules/common/components/ProfileImage";

export function FeedbackConfiguracion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { idFeedback } = params;

  const feedback = useSelector((state) => state.feedback.feedback);
  const subordinados_seleccionados = useSelector(
    (state) => state.feedback.subordinados_seleccionados
  );
  const subordinados = useSelector((state) => state.equipo?.subordinados) || [];
  const subordinados_loading = useSelector(
    (state) => state.equipo?.subordinados_loading
  );
  const subordinados_filter =
    useSelector((state) => state.equipo?.subordinados_filter) || [];
  const subordinados_count = useSelector(
    (state) => state.equipo?.subordinados_count
  );
  const subordinados_checked = useSelector(
    (state) => state.equipo?.subordinados_checked
  );

  const [feedbackLocal, setFeedbackLocal] = useState(feedback);

  const cargarSubordinadosSeleccionados = () => {
    const colaboradoresIds = subordinados_seleccionados.map(
      (subordinado) => subordinado.colaborador
    );
    const subordinadosCopy = JSON.parse(JSON.stringify(subordinados));
    if (!subordinadosCopy) return;
    const subordinadosCheckedAux = subordinadosCopy.filter((subordinado) =>
      colaboradoresIds.includes(subordinado.id)
    );
    dispatch(equipoActions.setCheckSubordinados(subordinadosCheckedAux));
  };

  useEffect(() => {
    dispatch(equipoActions.subordinados({}));
    if (idFeedback) {
      dispatch(feedbackActions.getFeedback(idFeedback));
      dispatch(feedbackActions.getSubordinadosSeleccionados(idFeedback));
    } else {
      dispatch(feedbackActions.setFeedback({}));
      dispatch(equipoActions.setCheckSubordinados([]));
    }
  }, []);

  useEffect(() => {
    if (idFeedback) {
      cargarSubordinadosSeleccionados();
    }
  }, [subordinados_seleccionados]);

  useEffect(() => {
    setFeedbackLocal(feedback);
  }, [feedback]);

  const onBack = () => {
    return (
      <div
        onClick={() => navigate("/feedback")}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };

  const onChange = (field) => (e) => {
    const value = e.target.value;
    dispatch(
      feedbackActions.setFeedback({
        ...feedback,
        [field]: value,
      })
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(
      equipoActions.setSubordinadosFilter({
        ...subordinados_filter,
        nombre: value,
      })
    );
  };

  const datatableConfig = {
    headers: [
      {
        title: "Colaborador",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="flex flex-row gap-2">
              <div className="">
                {item.foto_empleado && (
                  <div className="bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-10 w-10 rounded-md"
                      src={item.foto_empleado}
                      alt={`Perfil`}
                    />
                  </div>
                )}
                {!item.foto_empleado && (
                  <ProfileImage className="h-10 w-10"></ProfileImage>
                )}
              </div>
              <div className="flex flex-col">
                <div className="font-semibold">
                  {item.nombre} {item.apellido}
                </div>
                <div className="text-sm">
                  {item.cargo ? item.cargo.nombre : "Sin cargo"}
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    rowActions: [
      // {
      //   label: "Eliminar",
      //   action: (item) => {
      //     onDelete(item.id);
      //   },
      // },
    ],
  };

  const onCheckSubordinado = (item) => (event) => {
    console.log(item);
    dispatch(
      equipoActions.checkSubordinados({
        subordinado: item,
        checked: event.target.checked,
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(subordinados));
    if (subordinados_checked.length > 0) {
      checkedData = [];
    }
    dispatch(equipoActions.setCheckSubordinados(checkedData));
  };

  const onSubmit = () => {
    if (!feedback.nombre) {
      ErrorToast({ message: "Debes agregar un nombre." });
      return;
    }
    const colaboradores_ids = subordinados_checked.map(
      (subordinado) => subordinado.id
    );
    if (colaboradores_ids.length === 0) {
      ErrorToast({
        message:
          "Debes selecionar al menos un colaborador para realizar el feedback.",
      });
      return;
    }
    dispatch(
      feedbackActions.setFeedback({
        ...feedback,
        fecha_inicio: null,
        fecha_fin: null,
        por_jefe_directo: true,
        colaboradores: colaboradores_ids,
      })
    );
    if (idFeedback) {
      if (feedback.por_jefe_directo) {
        dispatch(feedbackActions.updateFeedback({}));
      } else {
        // Desde lider solo se pueden modificar los feedbacks creados desde lider y no los creados desde admin
        ErrorToast({
          message:
            "Sólo pueden modificarse los feedbacks creados por un lider.",
        });
      }
    } else {
      dispatch(feedbackActions.createFeedback({}));
    }
  };

  return (
    <div>
      <Header titulo="Configuración de Feedback" LeftComponent={onBack} />
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 mx-12 px-12 py-4 w-8/12">
          <div className="border-b w-full text-xl font-semibold py-2">
            Configuración inicial
          </div>
          <div className="">
            <div>
              <h2 className="font-semibold">Nombre:</h2>
              <div className="flex justify-start mt-2">
                <input
                  type={"text"}
                  name={"nombre"}
                  value={feedbackLocal ? feedbackLocal.nombre : ""}
                  onChange={onChange("nombre")}
                  placeholder={"Sin especificar..."}
                  className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm w-full"
                />
              </div>
            </div>
            <div className="flex flex-col mt-12">
              <div className="text-xl font-semibold border-b py-2">
                Mi equipo
              </div>
              <div className="flex flex-row justify-between mt-4 gap-4">
                <SearchInput
                  containerClass={`w-full`}
                  placeholder={"Buscar por nombre..."}
                  onChange={onSearch}
                ></SearchInput>
                <ReactSelect
                  className="w-52 h-10 bg-blue-200 inline-grid"
                  value={{
                    value: "",
                    label: `(${subordinados_checked.length}) seleccionados`,
                  }}
                ></ReactSelect>
              </div>
            </div>
            <div>
              <div className="max-h-[400px] overflow-auto custom-scrollbar mt-4">
                <DataTable
                  containerClass={"p-2 bg-white"}
                  data={subordinados}
                  loading={subordinados_loading}
                  config={datatableConfig}
                  noHeaders={false}
                  //
                  totalRecords={subordinados_count}
                  outerPageSize={999}
                  //
                  checkable={true}
                  checkedItems={subordinados_checked}
                  onItemCheck={onCheckSubordinado}
                  onCheckAll={onCheckAll}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <PrimaryButton
              className="mt-6 w-10/12 "
              type="button"
              onClick={onSubmit}
            >
              <span>Continuar</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}
