import { createSlice } from "@reduxjs/toolkit";

const equipoSlice = createSlice({
  name: "equipo",
  initialState: {
    equipo: { result: [] },
    equipo_loading: false,
    subordinados: { results: [] },
    subordinados_filter: {},
    subordinados_loading: false,
    subordinados_count: 0,
    subordinados_checked: [],
    //
    subordinados_colaborador: { results: [] },
    subordinados_colaborador_filter: {},
    subordinados_colaborador_loading: false,
    subordinados_colaborador_count: 0,
  },
  reducers: {
    equipo: (state, { payload }) => {
      state.equipo_loading = true;
    },
    setEquipo: (state, { payload }) => {
      console.info("setEquipo", payload);
      state.equipo = payload;
    },
    //
    subordinados: (state, { payload = {} }) => {
      state.subordinados_loading = true;
    },
    setSubordinados: (state, { payload = {} }) => {
      state.subordinados = payload.data.results;
      state.subordinados_loading = false;
    },
    setSubordinadosFilter: (state, { payload = {} }) => {
      state.subordinados_filter = payload;
    },
    setSubordinadosCount: (state, { payload = {} }) => {
      state.subordinados_count = payload;
    },
    setCheckSubordinados: (state, { payload = {} }) => {
      state.subordinados_checked = payload;
    },
    resetChecksSubordinados: (state, { payload = {} }) => {
      state.subordinados_checked = [];
    },
    checkSubordinados: (
      state,
      { payload: { subordinado, checked = false } }
    ) => {
      state.subordinados_checked = state.subordinados_checked.filter(
        (subordinadoAux) => subordinadoAux.id !== subordinado.id
      );
      if (checked) {
        state.subordinados_checked.push(subordinado);
      }
    },
    //
    subordinadosColaborador: (state, { payload = {} }) => {
      state.subordinados_colaborador_loading = true;
    },
    setSubordinadosColaborador: (state, { payload = {} }) => {
      state.subordinados_colaborador = payload.data.results;
      state.subordinados_colaborador_loading = false;
    },
    setSubordinadosColaboradorFilter: (state, { payload = {} }) => {
      state.subordinados_colaborador_filter = payload;
    },
    setSubordinadosColaboradorCount: (state, { payload = {} }) => {
      state.subordinados_colaborador_count = payload;
    },
  },
});

export const equipoActions = equipoSlice.actions;
export const equipoReducer = equipoSlice.reducer;
