import { createRouterReducer } from "@lagunovsky/redux-react-router";
import { createSlice } from "@reduxjs/toolkit";
import { authReducer } from "src/modules/auth/handlers/redux";
import { colaboradorReducer } from "src/modules/colaborador/handlers/redux";
import { commonReducer } from "src/modules/common/handler/redux";
import { equipoReducer } from "src/modules/equipo/handlers/redux";
import { evaluacionReducer } from "src/modules/evaluacion/handlers/redux";
import { kpiReducer } from "src/modules/kpi/handlers/redux";
import { inicioReducer } from "../modules/inicio/handlers/redux";
import { planCarreraReducer } from "src/modules/plan_carrera/handlers/redux";
import { objetivoCargoReducer } from "src/modules/objetivo_cargo/handlers/redux";
import { beneficioReducer } from "src/modules/beneficio/handlers/redux";
import { feedbackReducer } from "src/modules/feedback/handlers/redux";

const navigateSlice = createSlice({
  name: "navigate",
  initialState: {
    navigate: () => {},
  },
  reducers: {
    setNavigate: (state, { payload }) => {
      state.navigate = payload;
    },
    change: (state) => {
      state.navigate("/about");
    },
  },
});

export const navigateActions = navigateSlice.actions;
export const navigateReducer = navigateSlice.reducer;

export const rootReducer = (history) => ({
  router: createRouterReducer(history),
  auth: authReducer,
  common: commonReducer,
  inicio: inicioReducer,
  equipo: equipoReducer,
  colaborador: colaboradorReducer,
  kpi: kpiReducer,
  evaluacion: evaluacionReducer,
  plan_carrera: planCarreraReducer,
  objetivoCargo: objetivoCargoReducer,
  beneficio: beneficioReducer,
  feedback: feedbackReducer,
  // navigate: navigateReducer,
  // debug: (a, b) => { console.log(a, b); return {} }
});
