import React, { useEffect, useRef, useState } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { kpiActions } from "../handlers/redux";
import { PUNTUACIONES_OBSERVACION, TIPO_METRICAS } from "../helpers/helpers";

const MetricaInput = ({ metrica, anotacion, onChange }) => {
  const metricaId = metrica;
  const metricasNumericas = [TIPO_METRICAS.NUMERICO, TIPO_METRICAS.PORCENTAJE];
  //
  if (metricasNumericas.includes(metricaId)) {
    return (
      <div className="flex flex-col flex-1">
        <label className="text-black" htmlFor="valor">
          Valor a registrar
        </label>
        <input
          type="text"
          name="valor"
          id="valor"
          value={anotacion.valor}
          onChange={onChange}
          className="border border-slate-200 rounded-md p-2"
        />
      </div>
    );
  }
  if (metricaId === TIPO_METRICAS.OBSERVACION) {
    return (
      <div className="flex flex-col flex-1">
        <label className="text-black" htmlFor="valor">
          Valor a registrar
        </label>
        <select
          onChange={onChange}
          className="p-2 bg-white border rounded-md"
          value={anotacion.valor}
        >
          <option value="">Eliga una opción</option>
          {PUNTUACIONES_OBSERVACION.map((puntuacion, idx) => (
            <option key={`opt-${idx}`} value={puntuacion.value}>
              {puntuacion.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
  if (metricaId === TIPO_METRICAS.SIMPLE) {
    return (
      <div className="flex flex-col flex-1">
        <label className="text-black" htmlFor="valor">
          Valor a registrar
        </label>
        <select
          onChange={onChange}
          className="p-2 bg-white border rounded-md"
          value={anotacion.valor}
        >
          <option value="">Eliga una opción</option>
          <option value="100">Logro</option>
          <option value="0">No logro</option>
        </select>
      </div>
    );
  }
};

const initialAnotacion = {
  id: null,
  anotacion: "",
  valor: null,
};

function AnotacionForm({ kpiColaborador, onSave, ...props }) {
  const dispatch = useDispatch();
  const [checkAll, setCheckAll] = useState(false);
  const [visibleMenuId, setVisibleMenuId] = useState(null);
  const [anotacion, setAnotacion] = useState(initialAnotacion);
  const [anotacionIndex, setAnotacionIndex] = useState(null);
  const [kpiLocal, setKpiLocal] = useState(kpiColaborador);
  const anotaciones_loading = useSelector(
    (state) => state.kpi.anotaciones_loading
  );
  const [anotaciones, setAnotaciones] = useState(kpiColaborador.anotaciones); //se usa para el search filter
  const [anotacionesAll, setAnotacionesAll] = useState(
    kpiColaborador.anotaciones
  );
  const [anotaciones_filter, setAnotaciones_filter] = useState({
    search: "",
  });
  const [anotaciones_checked, setAnotaciones_checked] = useState([]);
  const { search: valorBusqueda = "" } = anotaciones_filter;
  const metricas = useSelector((state) => state.kpi.metricas);
  const onChange =
    (name) =>
    ({ target }) => {
      setAnotacion({ ...anotacion, [name]: target?.value });
    };
  const onSubmitAnotacion = (e) => {
    e.preventDefault();
    console.log("onSubmitAnotacion", anotacion);
    let anotacionesCopy = JSON.parse(JSON.stringify(anotacionesAll));
    //
    if (anotacionIndex === null) {
      // nuevo
      anotacionesCopy.push(anotacion);
    } else {
      // editar
      anotacionesCopy[anotacionIndex] = anotacion;
    }
    setAnotaciones(anotacionesCopy);
    setAnotacionesAll(anotacionesCopy);
    if (onSave) {
      onSave(anotacionesCopy);
    }
    //
    setAnotacionIndex(null);
    setAnotacion(initialAnotacion);
  };

  const getMetricaDisplay = (metrica) => {
    const metricaObject = metricas.find((m, idx) => m.value === metrica);
    return metricaObject ? metricaObject.label : "Sin definir";
  };

  const getValorRegistrado = (metrica, valor) => {
    //
    if (metrica === TIPO_METRICAS.OBSERVACION) {
      let puntuacion = PUNTUACIONES_OBSERVACION.find(
        (p, idx) => parseInt(p.value + "") === parseInt(valor + "")
      );
      //
      return puntuacion?.label;
    }
    if (metrica === TIPO_METRICAS.SIMPLE) {
      return valor === 100 ? "Logro" : "No logro";
    }
    //
    return valor;
  };

  const checkAnotacion = ({ anotacion, index, checked = true }) => {
    let anotaciones_checkedCopy = JSON.parse(
      JSON.stringify(anotaciones_checked)
    );
    anotaciones_checkedCopy = anotaciones_checkedCopy.filter(
      (anotacion, aidx) => aidx !== index
    );
    if (!!checked) {
      // @ts-ignore
      anotaciones_checkedCopy.push(anotacion);
    }
    setAnotaciones_checked(anotaciones_checkedCopy);
  };

  const onCheckAnotacion = (e, item, index) => {
    console.info("onCheckAnotacion", item);
    checkAnotacion({
      anotacion: item,
      index: index,
      checked: e.target.checked,
    });
  };

  const onEditarAnotacion = (anotacion, index) => {
    setAnotacion(anotacion);
    setAnotacionIndex(index);
    // @ts-ignore
    formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const onDeleteAnotacion = (index) => {
    let deseaEliminar = window.confirm(
      "Desea eliminar la anotación seleccionada?"
    );
    if (deseaEliminar) {
      let anotacionesCopy = JSON.parse(JSON.stringify(anotacionesAll));
      anotacionesCopy = anotacionesCopy.filter(
        (anotacion, aidx) => aidx !== index
      );
      setAnotaciones(anotacionesCopy);
      setAnotacionesAll(anotacionesCopy);
      setAnotaciones_checked([]);
    }
  };

  const checkAllAnotaciones = () => {
    setCheckAll(!checkAll);
    if (!checkAll) {
      setAnotaciones_checked(anotaciones);
    } else {
      setAnotaciones_checked([]);
    }
  };

  const onChangeTableSelect = (v) => {
    if (v.value === "cancel") {
      setAnotaciones_checked([]);
    } else if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar las anotaciones seleccionadas?"
      );
      if (deseaEliminar) {
        // dispatch(kpiActions.deleteAnotaciones());
        // TODO ver eliminar masivo checked
      }
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const setVisibleMenu = (id) => {
    if (visibleMenuId === id) {
      setVisibleMenuId(null);
      return;
    }
    setVisibleMenuId(id);
  };

  const AnotacionItem = ({ anotacion, index }) => {
    return (
      <div
        key={`anotacion-${anotacion.id}`}
        className="flex flex-col gap-4 border-b pt-2 pb-4 mb-4"
      >
        <div className="flex items-center gap-3">
          <div>
            <input
              type="checkbox"
              className="w-4 h-4"
              name={`check-${anotacion.id}`}
              id={`check-${anotacion.id}`}
              checked={anotaciones_checked
                .map((ac, aci) => aci)
                .includes(index)}
              onChange={(e) => onCheckAnotacion(e, anotacion, index)}
            />
          </div>
          {/*  */}
          <div className="flex flex-col w-full gap-4">
            <div>{anotacion.anotacion}</div>
            <div className="flex items-center gap-2">
              <div className="flex flex-col flex-1 gap-3">
                <label className="text-black font-bold">Métrica</label>
                {getMetricaDisplay(kpiLocal.metrica)}
              </div>
              <div className="flex flex-col flex-1 gap-3">
                <label className="text-black font-bold">Valor registrado</label>
                {/* {anotacion.valor} */}
                {getValorRegistrado(kpiLocal.metrica, anotacion.valor)}
              </div>
            </div>
          </div>
          {/*  */}
          <div>
            <div
              className="hover:cursor-pointer relative"
              onClick={(e) => {
                e.stopPropagation();
                setVisibleMenu(index);
              }}
            >
              <ThreeDotsVertical></ThreeDotsVertical>
              <div
                className={`${
                  visibleMenuId !== null && visibleMenuId === index
                    ? ""
                    : "hidden"
                } bg-white border rounded-md text-left absolute top-3 right-1`}
              >
                <div
                  className="text-sky-500 hover:bg-blue-100"
                  onClick={(e) => onEditarAnotacion(anotacion, index)}
                >
                  <div className="p-2">Editar</div>
                </div>
                <div
                  className="text-red-500 hover:bg-blue-100"
                  onClick={(e) => onDeleteAnotacion(index)}
                >
                  <div className="p-2">Eliminar</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSearchTable = (e) => {
    // dispatch(kpiActions.setAnotacionesFilter({ search: e.target.value }));
    setAnotaciones_filter({ search: e.target.value });
  };

  useEffect(() => {
    let search = anotaciones_filter.search;
    let anotacionesCopy = JSON.parse(JSON.stringify(anotacionesAll));
    anotacionesCopy = anotacionesCopy.filter((anotacion, aidx) =>
      anotacion.anotacion.toUpperCase().includes(search.toLocaleUpperCase())
    );
    setAnotaciones(anotacionesCopy);
  }, [anotaciones_filter.search]);

  const AnotacionLoader = () => {
    return (
      <div className="flex flex-col gap-4 animate-pulse px-4 py-4 border-b">
        <div className="bg-slate-100 w-full rounded-md h-4"></div>
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-4">
            <span className="bg-slate-100 w-20 h-2"></span>
            <span className="bg-slate-100 w-20 h-2"></span>
          </div>
          <div className="flex flex-1 flex-col gap-4">
            <span className="bg-slate-100 w-20 h-2"></span>
            <span className="bg-slate-100 w-20 h-2"></span>
          </div>
        </div>
      </div>
    );
  };

  const AnotacionesTable = () => {
    return (
      <div className="pl-4">
        {!!anotaciones_loading &&
          [0, 1, 2].map((item, idx) => (
            <AnotacionLoader key={`l-${idx}`}></AnotacionLoader>
          ))}
        {!anotaciones_loading &&
          anotaciones.length > 0 &&
          anotaciones.map((anotacion, idx) => (
            <AnotacionItem
              key={`ai-${idx}`}
              anotacion={anotacion}
              index={idx}
            ></AnotacionItem>
          ))}
        {!anotaciones_loading && anotaciones.length < 1 && (
          <div className="text-center p-2 border-b rounded-md">
            Sin anotaciones para mostrar
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    dispatch(kpiActions.metricas());
  }, []);

  useEffect(() => {
    console.info("anotaciones_checked effect", anotaciones_checked);
    if (
      anotaciones_checked.length === anotaciones.length &&
      anotaciones_checked.length > 1
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [anotaciones_checked]);

  const formRef = useRef();
  //
  return (
    <>
      <div
        className="flex bg-slate-200 p-4 rounded-md"
        ref={formRef}
        onClick={(e) => setVisibleMenuId(null)}
      >
        {/* FORMULARIO */}
        <div className="flex flex-col gap-4 w-full">
          <div className="flex w-full">
            <textarea
              className="w-full border-2 border-gray-300 rounded-md p-2"
              name="anotacion"
              id="anotacion"
              onChange={onChange("anotacion")}
              value={anotacion.anotacion}
              // rows="3"
            ></textarea>
          </div>
          {/*  */}
          <div className="flex items-center gap-3">
            <div className="flex flex-col flex-1">
              <label className="text-black" htmlFor="metrica">
                Métrica a ser evaluada
              </label>
              <div className="py-2">
                {metricas.find((m, i) => m.value === kpiLocal.metrica)?.label}
              </div>
            </div>
            <MetricaInput
              metrica={kpiLocal.metrica}
              anotacion={anotacion}
              onChange={onChange("valor")}
            ></MetricaInput>
          </div>
          {/*  */}
          <div>
            <button
              onClick={onSubmitAnotacion}
              className="p-3 bg-sky-500 text-white text-sm rounded-md"
              type="button"
            >
              {anotacionIndex !== null
                ? "Editar anotación"
                : "Agregar anotación"}
            </button>
          </div>
        </div>
      </div>
      {/* LISTADO */}
      <div
        className="flex flex-col gap-4 mt-4"
        onClick={(e) => {
          e.stopPropagation();
          setVisibleMenuId(null);
        }}
      >
        {/* HEADER */}
        <div className="flex items-center gap-2 font-bold border-b pb-2">
          <input
            type="checkbox"
            className="w-4 h-4"
            name={`check-${anotacion.id}`}
            id={`check-${anotacion.id}`}
            key={`check-${anotacion.id}`}
            checked={checkAll}
            onChange={checkAllAnotaciones}
          />
          <span>Lista de anotaciones</span>
        </div>
        <div className="flex gap-3 w-full">
          <input
            className="w-full p-2 bg-gray-100 rounded-sm"
            placeholder="Buscar por anotación"
            type="search"
            name="buscar-anotacion"
            id="buscar-anotacion"
            key="buscar-anotacion"
            value={valorBusqueda}
            onChange={onSearchTable}
          />
          <ReactSelect
            className="w-52 h-10 bg-blue-200 inline-grid"
            value={{
              value: "",
              label: "(" + anotaciones_checked.length + ") seleccionados",
            }}
            options={[
              { value: "eliminar", label: "Eliminar" },
              { value: "cancel", label: "Cancelar" },
            ]}
            onChange={onChangeTableSelect}
          ></ReactSelect>
        </div>
        <AnotacionesTable></AnotacionesTable>
      </div>
    </>
  );
}

export default AnotacionForm;
