import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { planCarreraApi } from "./api";
import { planCarreraActions } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function* getFamiliasSaga({ payload }) {
  const token = getToken();
  const plan_carreras_filter = yield select(
    (state) => state.plan_carrera.plan_carreras_filter
  );
  //
  try {
    const data = yield call(
      planCarreraApi(token).getFamilias,
      plan_carreras_filter
    );
    yield put(planCarreraActions.setPlanCarreras(data.results));
    yield put(planCarreraActions.setPlanCarrerasCount(data.count));
  } catch (error) {
    console.log({ error });
    yield put(planCarreraActions.setPlanCarreras([]));
    yield put(planCarreraActions.setPlanCarrerasCount(0));
    ErrorToast({ message: "Ocurrió un error." });
  }
}

function* planCarreraDetailSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(planCarreraApi(token).getPlanCarrera, payload);
    console.log("planCarreraDetailSaga data", data);
    yield put(planCarreraActions.setPlanCarrera(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
function* getCargosSaga({ payload }) {
  const token = getToken();
  const cargos_filter = yield select(
    (state) => state.plan_carrera.cargos_filter
  );
  //
  try {
    const data = yield call(planCarreraApi(token).getCargos, cargos_filter);
    yield put(planCarreraActions.setCargos(data.results));
    yield put(planCarreraActions.setCargosCount(data.count));
  } catch (error) {
    console.log({ error });
    yield put(planCarreraActions.setCargos([]));
    yield put(planCarreraActions.setCargosCount(0));
    ErrorToast({ message: "Ocurrió un error." });
  }
}

function* getCargoDetail({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(planCarreraApi(token).getCargo, payload);
    yield put(planCarreraActions.setCargo(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.setCargo({}));
    ErrorToast({ message: "Ocurió un error" });
  }
}

export function* planCarreraSagas() {
  yield takeEvery(planCarreraActions.getPlanCarreras.type, getFamiliasSaga);
  yield takeEvery(
    planCarreraActions.getPlanCarrerasFilter.type,
    getFamiliasSaga
  );
  yield takeEvery(
    planCarreraActions.getPlanCarrera.type,
    planCarreraDetailSaga
  );
  yield takeEvery(planCarreraActions.getCargos.type, getCargosSaga);
  yield takeEvery(planCarreraActions.setCargosFilter.type, getCargosSaga);
  yield takeEvery(planCarreraActions.getCargo.type, getCargoDetail);
}
