import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const planCarreraApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  return {
    getFamilias: async (filter) => {
      const { nombre = "" } = filter;
      //
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/plan-carrera/?search=${nombre}`,
          {
            headers,
          }
        );
        //
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    getPlanCarrera: async (id) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/plan-carrera/${id}/detail`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("plan detail error", error);
        throw error;
      }
    },
    getCargos: async (filter) => {
      const { nombre = "", pagination = { page: 1, pageSize: 50 } } = filter;
      const { page, pageSize } = pagination;
      //
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/plan-carrera/cargos/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        //
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    getCargo: async (id) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/plan-carrera/cargos/${id}/detail`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cargo detail error", error);
        throw error;
      }
    },
  };
};
