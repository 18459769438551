import React from "react";

export const PrimaryButton = ({
  className = "",
  children = null,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`h-10 bg-sky-500 text-white text-sm px-4 rounded ${className}`}
    >
      {children}
    </button>
  );
};
