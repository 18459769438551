import React from "react";
import PlanCarreraDetalle from "../pages/PlanCarreraDetalle";
import PlanCarreraHome from "../pages/PlanCarreraHome";

export const planCarreraRoutes = [
  {
    path: "/plan-carrera/",
    // @ts-ignore
    element: <PlanCarreraHome />,
  },
  {
    path: "/plan-carrera/:planCarreraId",
    // @ts-ignore
    element: <PlanCarreraDetalle />,
  },
];
