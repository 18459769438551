import React, { useEffect, useState } from "react";
import { VictoryAnimation, VictoryLabel, VictoryPie } from "victory";

const getData = (percent) => {
  return [
    { x: 1, y: percent },
    { x: 2, y: 100 - percent },
  ];
};

function CircularProgressMediumBar({ label = 0, percent = 0 }) {
  // const [percent, setPercent] = useState(total > 0 ? (num * 100) / total : 0);

  const [data, setData] = useState(getData(0));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(getData(percent));
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [percent]);

  // useEffect(() => {
  //   setPercent(total > 0 ? (num * 100) / total : 0);
  // }, [num, total]);

  return (
    <div>
      <svg viewBox="0 0 400 400" width="96px" height="96px">
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400}
          height={400}
          data={[
            { x: 1, y: 100 },
            { x: 2, y: 0 },
          ]}
          innerRadius={180}
          radius={200}
          labels={() => null}
          style={{ data: { fill: "#eee" } }}
        ></VictoryPie>
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400}
          height={400}
          data={data}
          innerRadius={180}
          radius={200}
          labels={() => null}
          cornerRadius={60}
          style={{
            data: {
              fill: ({ datum }) => {
                let color = "red";
                if (datum.y > 30) color = "yellow";
                if (datum.y > 50) color = "blue";
                if (datum.y > 80) color = "green";

                return datum.x === 1 ? color : "transparent";
              },
            },
          }}
        />
        <VictoryAnimation duration={1000} data={{ percent, data }}>
          {(newProps) => {
            return (
              <>
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={210}
                  y={200}
                  text={`${label}`}
                  style={{ fontSize: 86, fontWeight: "bold" }}
                />
              </>
            );
          }}
        </VictoryAnimation>
      </svg>
    </div>
  );
}

export default CircularProgressMediumBar;
