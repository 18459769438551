import React from "react";
import { Route, Routes } from "react-router-dom";
import { beneficioRoutes } from "src/modules/beneficio/handlers/routes";
import { colaboradorRoutes } from "src/modules/colaborador/handlers/routes";
import { equipoRoutes } from "src/modules/equipo/handlers/routes";
import { evaluacionRoutes } from "src/modules/evaluacion/handlers/routes";
import { feedbackRoutes } from "src/modules/feedback/handlers/routes";
import { inicioRoutes } from "src/modules/inicio/handlers/routes";
import { kpiRoutes } from "src/modules/kpi/handlers/routes";
import { objetivoCargoRoutes } from "src/modules/objetivo_cargo/handlers/routes";
import { planCarreraRoutes } from "src/modules/plan_carrera/handlers/routers";

const routes = [
  ...inicioRoutes,
  ...equipoRoutes,
  ...colaboradorRoutes,
  ...evaluacionRoutes,
  ...kpiRoutes,
  ...planCarreraRoutes,
  ...objetivoCargoRoutes,
  ...beneficioRoutes,
  ...feedbackRoutes,
];

const RootRoutes = () => {
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};

export default RootRoutes;
