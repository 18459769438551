import React, { useEffect, useState } from "react";
import { FileEarmarkArrowDownFill, X } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import HeaderGoBack from "src/modules/common/components/HeaderGoBack";
import Modal from "src/modules/common/components/Modal";
import SearchInput from "src/modules/common/components/SearchInput";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import RequisitoForm from "../components/RequisitoForm";
import { planCarreraActions } from "../handlers/redux";
import {
  getToken,
  getUserFromCookie,
} from "src/modules/common/handler/helpers";
import { FINTECH_ID } from "../helpers/constants";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import axios from "axios";

const CategoriaTableRow = ({
  categoria,
  isLast,
  onEditarCategoria,
  onEditarCargos,
  setShowCargosTable,
  setSelectedCargo,
  setShowRequisitosForm,
}) => {
  const user = useSelector((state) => state.auth.user);
  const {
    nombre = "Sin nombre",
    cargos = [],
    se_encuentra = false,
    cargo_encuentra_id,
  } = categoria;
  const SLICE_AMOUNT = 4;
  const editarCargosLocal = () => {
    onEditarCargos(categoria);
  };
  //
  return (
    <tr className={`${isLast ? "" : "border-b"}`}>
      <td className="py-2 border-r">
        <div className="flex flex-col justify-center gap-1">
          <div className="flex flex-column gap-2 flex-1 mx-4">
            <div className="font-semibold">{nombre}</div>
            <div>{cargos.length} cargos aplicados</div>
          </div>
          <div>
            {!!se_encuentra && (
              <div className="w-full mt-1">
                <div className="flex flex-col lg:flex-row items-center justify-aroundx gap-2">
                  {!user?.foto_empleado && (
                    <ProfileImage className="h-10 w-10 p-2"></ProfileImage>
                  )}
                  {!!user?.foto_empleado && (
                    <div className="w-[40px] bg-slate-200 rounded-md ml-2">
                      <img
                        className="object-cover h-[40px] w-[40px] rounded-md"
                        src={user?.foto_empleado}
                        alt={`Foto Usuario`}
                      />
                    </div>
                  )}
                  <div className="flex items-center justify-center gap-1">
                    <div className="bg-sky-500 rounded-full p-1x h-2 w-2"></div>
                    <p className="m-0 text-sky-500 text-xs word-break w-1/2x">
                      Tu cargo está aquí
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </td>
      {/*  */}
      <td className="px-4 py-2">
        <div className="flex gap-2">
          {cargos.slice(0, SLICE_AMOUNT).map((cargo, cidx) => (
            <div
              key={`cargo-${cargo.id}-cargo-${cidx}`}
              className={` px-1 py-1 rounded-md  ${
                cargo.cargo.id === cargo_encuentra_id
                  ? "bg-sky-500 text-white font-semibold"
                  : "bg-slate-200"
              }`}
              onClick={() => {
                onEditarCargos(categoria);
                setShowCargosTable(false);
                setSelectedCargo(cargo.cargo);
                setShowRequisitosForm(true);
              }}
            >
              {cargo.cargo?.nombre}
            </div>
          ))}
          {cargos.slice(SLICE_AMOUNT).length > 0 && (
            <button
              onClick={editarCargosLocal}
              className="flex items-center px-2 py-1 text-sky-500 bg-white border border-sky-500 rounded-md"
            >
              +{cargos.slice(SLICE_AMOUNT).length}
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

const CategoriaTable = ({
  categorias = [],
  loading = false,
  onEditarCategoria,
  onEditarCargos,
  setShowCargosTable,
  setShowRequisitosForm,
  setSelectedCargo,
}) => {
  return (
    <table className="w-full text-sm">
      <thead>
        <tr>
          <th className="w-1/5 bg-slate-200 text-left font-semibold px-4 py-2">
            Lista de categorías
          </th>
          <th className="w-4/5 bg-slate-200"></th>
          {/* <th className="bg-slate-200"></th> */}
        </tr>
      </thead>
      <tbody>
        {!loading &&
          categorias.length > 0 &&
          categorias.map((cat, idx) => (
            <CategoriaTableRow
              categoria={cat}
              key={idx}
              isLast={idx === categorias.length - 1}
              onEditarCategoria={onEditarCategoria}
              onEditarCargos={onEditarCargos}
              setShowCargosTable={setShowCargosTable}
              setShowRequisitosForm={setShowRequisitosForm}
              setSelectedCargo={setSelectedCargo}
            />
          ))}
        {!loading && categorias.length < 1 && (
          <tr>
            <td className="w-1/3"></td>
            <td className="w-1/3 px-4 py-2 text-center">
              <div className="italic">Sin categorías para mostrar</div>
            </td>
            <td className="w-1/3"></td>
          </tr>
        )}
        {!!loading && (
          <tr>
            <td></td>
            <td>Cargando...</td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const CategoriaCargosTable = ({
  categoriaForm,
  onAgregarNuevoCargo,
  onUpdateCategoriaCargos,
  setShowCargosTable,
  setShowRequisitosForm,
  setSelectedCargo,
}) => {
  const { cargos = [] } = categoriaForm;
  const [cargosLocal, setCargosLocal] = useState(cargos);

  const mostrarRequisitos = (cargo) => {
    console.log("mostrarRequisitos", cargo);
    setShowCargosTable(false);
    setSelectedCargo(cargo);
    setShowRequisitosForm(true);
  };
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "cargo.nombre",
      },
    ],
    rowActions: [
      {
        label: "Requisitos",
        action: (item) => {
          mostrarRequisitos(item.cargo);
        },
      },
    ],
  };
  const onSearchCargos = (e) => {
    let searchText = e.target.value;
    setCargosLocal(
      cargos.filter((cargo) =>
        cargo.cargo?.nombre.toUpperCase().includes(searchText.toUpperCase())
      )
    );
  };
  //
  return (
    <div className="px-8">
      <div className="flex items-center gap-4">
        <SearchInput placeholder="Buscar cargo" onChange={onSearchCargos} />
      </div>
      <div className="max-h-[400px] overflow-auto custom-scrollbar mt-4">
        <DataTable
          containerClass={"my-8"}
          data={cargosLocal}
          loading={false}
          config={datatableConfig}
          noHeaders={false}
          totalRecords={cargosLocal.length}
          outerPageSize={999}
          checkable={false}
          // checkedItems={cargosCheckedLocal}
          // onItemCheck={onCheckCargoLocal}
          // onCheckAll={onCheckAll}
        />
      </div>
    </div>
  );
};

function PlanCarreraDetalle() {
  const dispatch = useDispatch();
  const params = useParams();
  const { planCarreraId } = params;
  const planes_carrera = useSelector(
    (state) => state.plan_carrera.plan_carreras
  );
  const plan_carrera = useSelector((state) => state.plan_carrera.plan_carrera); // CATEGORIA_EJEMPLO;
  const plan_carrera_loading = useSelector(
    (state) => state.plan_carrera.loading
  );
  const [categoriaForm, setCategoriaForm] = useState({});
  const [openCategoriaModal, setOpenCategoriaModal] = useState(false);
  const [openCargosModal, setOpenCargosModal] = useState(false);
  const [showCargosTable, setShowCargosTable] = useState(false);
  const [showRequisitosForm, setShowRequisitosForm] = useState(false);
  const [selectedCargo, setSelectedCargo] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  //
  const { nombre = "", descripcion = "", categorias = [] } = plan_carrera;
  const getSiguienteFamilia = () => {
    const currentIndex = planes_carrera.findIndex(
      (p) => p.id === plan_carrera.id
    );
    let siguienteFamilia = planes_carrera[0];
    if (currentIndex < planes_carrera.length - 1) {
      siguienteFamilia = planes_carrera[currentIndex + 1];
    }
    //
    return siguienteFamilia;
  };
  const irASiguienteFamilia = () => {
    const siguienteFamilia = getSiguienteFamilia();
    if (siguienteFamilia) {
      dispatch(planCarreraActions.getPlanCarrera(siguienteFamilia.id));
    }
  };
  const changeFamilia = (e) => {
    const id = e.target.value;
    dispatch(planCarreraActions.getPlanCarrera(id));
  };
  const RightMenu = () => {
    return (
      <div className="flex gap-4">
        <button
          onClick={irASiguienteFamilia}
          className="bg-sky-500 hover:bg-sky-400 text-white font-semibold py-2 px-4 rounded-md"
        >
          Siguiente familia
        </button>
        <select
          className="bg-white py-2 px-4 border rounded-md md:w-60"
          value={plan_carrera.id}
          onChange={changeFamilia}
        >
          {planes_carrera.map((plan, pidx) => (
            <option key={`plan-${pidx}`} value={plan.id}>
              {plan.nombre}
            </option>
          ))}
        </select>
      </div>
    );
  };
  // CATEGORIA
  const onAddCategoria = () => {
    dispatch(
      planCarreraActions.addCategoria({
        id: plan_carrera.id,
        categoria: categoriaForm,
      })
    );
    setCategoriaForm({});
    setOpenCategoriaModal(false);
  };
  const onUpdateCategoria = () => {
    dispatch(
      planCarreraActions.updateCategoria({
        id: plan_carrera.id,
        categoriaId: categoriaForm?.id,
        updatedCategoria: categoriaForm,
      })
    );
    setCategoriaForm({});
    setOpenCategoriaModal(false);
  };
  const onAgregarNuevoCargo = () => {
    setShowCargosTable(true);
  };
  const onUpdateCategoriaCargos = (cargosIds) => {
    dispatch(
      planCarreraActions.updateCategoriaCargos({
        id: plan_carrera.id,
        categoriaId: categoriaForm?.id,
        cargosIds,
      })
    );
    // setCategoriaForm({});
    setOpenCargosModal(false);
    setShowCargosTable(false);
  };
  const onEditarCategoriaTabla = (categoria) => {
    setCategoriaForm(categoria);
    setOpenCategoriaModal(true);
  };
  const onEditarCargosTabla = (categoria) => {
    setCategoriaForm(categoria);
    setOpenCargosModal(true);
    setShowCargosTable(false);
  };
  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.setTitleMenu([]));
    //
    if (planCarreraId) {
      dispatch(planCarreraActions.getPlanCarrera(planCarreraId));
    }
    //
    if (planes_carrera.length < 1) {
      dispatch(planCarreraActions.getPlanCarreras());
    }
  }, []);
  //
  const usuario = getUserFromCookie();
  if (parseInt(usuario.empresa_id) === FINTECH_ID) {
    return <div>No tiene los permisos necesarios</div>;
  }
  //
  const downloadPDF = () => {
    if (planCarreraId) {
      const token = getToken();
      const URL_BASE =
        process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
      setDownloadLoading(true);
      axios({
        url: `${URL_BASE}/plan-carrera/${planCarreraId}/exportar/detail`,
        method: "POST",
        responseType: "blob", // Indica que la respuesta será un archivo binario
        headers: {
          Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
        },
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute(
            "download",
            `colaborador-plan-carrera-${planCarreraId}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadLoading(false);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo PDF:", error);
          setDownloadLoading(false);
        });
    }
  };
  //
  return (
    <div className="px-8">
      <HeaderGoBack
        title={nombre}
        route={"/plan-carrera/"}
        RightMenu={<RightMenu />}
      />
      {/*  */}
      <div className="grid grid-cols-12 mt-8">
        <div className="col-span-12 md:col-span-10 md:col-start-2">
          <div className="flex justify-between">
            <div className="w-2/3">
              <h3 className="text-lg">
                <span className="font-semibold">Plan de carrera para:</span>{" "}
                {nombre}
              </h3>
              <p>{descripcion}</p>
            </div>
            <div>
              {!downloadLoading && (
                <button
                  onClick={downloadPDF}
                  className="bg-sky-500 rounded-md text-white px-2 py-2 flex items-center gap-2"
                >
                  {/*@ts-ignore*/}
                  <FileEarmarkArrowDownFill size={18} />
                  <span className="font-semibold">Exportar PDF</span>
                </button>
              )}
              {downloadLoading && (
                <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse text-sm font-semibold">
                  Generando informe...
                </button>
              )}
            </div>
          </div>

          {/*  */}
          <div className="border rounded-md mt-4">
            <CategoriaTable
              categorias={categorias}
              loading={plan_carrera_loading}
              onEditarCategoria={onEditarCategoriaTabla}
              onEditarCargos={onEditarCargosTabla}
              setShowCargosTable={setShowCargosTable}
              setShowRequisitosForm={setShowRequisitosForm}
              setSelectedCargo={setSelectedCargo}
            />
          </div>
        </div>
      </div>
      {/* CATEGORIA MODAL */}
      <Modal isOpen={openCategoriaModal}>
        <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
          <div className="modal-title">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-semibold text-lg">
                {!!categoriaForm.id ? "Editar Categoría" : "Agregar Categoría"}
              </h2>
              <button
                onClick={() => {
                  setOpenCategoriaModal(false);
                }}
              >
                <X size={24} />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="flex flex-col">
              <label htmlFor="" className="font-semibold">
                Nombre: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="border rounded-md w-full p-2"
                value={categoriaForm?.nombre}
                onChange={(e) =>
                  setCategoriaForm({ ...categoriaForm, nombre: e.target.value })
                }
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="flex justify-center w-full">
              <button
                className="w-full bg-sky-500 text-white text-sm px-4 py-3 rounded-md"
                onClick={
                  !!categoriaForm.id ? onUpdateCategoria : onAddCategoria
                }
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* CARGOS MODAL  */}
      <Modal isOpen={openCargosModal}>
        <div className="rounded-lg bg-white w-10/12 md:w-6/12 p-2 md:p-5 flex flex-col gap-5">
          <div className="modal-title">
            <div className="w-full flex justify-between items-center px-3 mt-2">
              <div className="flex items-center gap-4">
                {/* BACK BUTTON PARA CUANDO ES TABLA DE CARGOS */}
                {!!showCargosTable && (
                  <div
                    onClick={() => setShowCargosTable(false)}
                    className={
                      "h-full flex items-center pr-2 -mr-2 z-10 cursor-pointer"
                    }
                  >
                    <img className="back" src={backButton} alt="Regresar" />
                  </div>
                )}
                {/* BACK BUTTON PARA CUANDO ES REQUISITOS */}
                {!!showRequisitosForm && (
                  <div
                    onClick={() => setShowRequisitosForm(false)}
                    className={
                      "h-full flex items-center pr-2 -mr-2 z-10 cursor-pointer"
                    }
                  >
                    <img className="back" src={backButton} alt="Regresar" />
                  </div>
                )}
                <h2 className="font-semibold text-xl">
                  {`${
                    !!showRequisitosForm
                      ? `${selectedCargo?.nombre}`
                      : !!showCargosTable
                      ? `Agregar cargos a: `
                      : `Lista de Cargos:`
                  }`}{" "}
                  {!showRequisitosForm && (
                    <span className="text-sky-400">
                      {categoriaForm?.nombre}
                    </span>
                  )}
                </h2>
              </div>
              <button
                onClick={() => {
                  setOpenCargosModal(false);
                }}
              >
                <X size={24} />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="">
              {!showRequisitosForm && !showCargosTable && (
                <CategoriaCargosTable
                  categoriaForm={categoriaForm}
                  onAgregarNuevoCargo={onAgregarNuevoCargo}
                  onUpdateCategoriaCargos={onUpdateCategoriaCargos}
                  setShowCargosTable={setShowCargosTable}
                  setShowRequisitosForm={setShowRequisitosForm}
                  setSelectedCargo={setSelectedCargo}
                />
              )}

              {!!showRequisitosForm && (
                <div>
                  <RequisitoForm cargo={selectedCargo} readOnly={true} />
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </Modal>
    </div>
  );
}

export default PlanCarreraDetalle;
