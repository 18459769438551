import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserFromCookie } from "src/modules/common/handler/helpers";

const ProtectedRoute = () => {
  const user = getUserFromCookie();

  if (!user.colaborador_id) {
    // Si no hay un usuario logueado, redirige al login
    return <Navigate to="/auth/login" />;
  }

  // Si el usuario está logueado, renderiza las rutas hijas
  return <Outlet />;
};

export default ProtectedRoute;
