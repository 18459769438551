import dayjs from "dayjs";
import React, { memo, useEffect, useState } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import DataTable from "src/modules/common/components/DataTable";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import SearchInput from "src/modules/common/components/SearchInput";
import { commonActions } from "src/modules/common/handler/redux";
import { equipoActions } from "src/modules/equipo/handlers/redux";
import { feedbackActions } from "../handlers/redux";
import { WarningToast } from "src/modules/common/components/WarningToast";

export const FeedbackPage = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const feedbacks_jefe_directo = useSelector(
    (state) => state.feedback.feedbacks_jefe_directo
  );
  const feedbacks_jefe_directo_loading = useSelector(
    (state) => state.feedback.feedbacks_jefe_directo_loading
  );
  const feedbacks_jefe_directo_count = useSelector(
    (state) => state.feedback.feedbacks_jefe_directo_count
  );
  const feedbacks_jefe_directo_filter = useSelector(
    (state) => state.feedback.feedbacks_jefe_directo_filter
  );

  const feedbacks_colaborador = useSelector(
    (state) => state.feedback.feedbacks_colaborador
  );
  const feedbacks_colaborador_loading = useSelector(
    (state) => state.feedback.feedbacks_colaborador_loading
  );
  const feedbacks_colaborador_count = useSelector(
    (state) => state.feedback.feedbacks_colaborador_count
  );
  const feedbacks_colaborador_filter = useSelector(
    (state) => state.feedback.feedbacks_colaborador_filter
  );

  const subordinados_count = useSelector(
    (state) => state.equipo?.subordinados_count
  );

  useEffect(() => {
    dispatch(commonActions.showSidebar(true));
    dispatch(equipoActions.subordinados({}));
  }, []);

  const onChangeTextJefeDirecto = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setFeedbacksJefeDirectoFilter({
        ...feedbacks_jefe_directo_filter,
        nombre: searchText,
      })
    );
  };

  const onChangeTextColaborador = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setFeedbacksColaboradorFilter({
        ...feedbacks_colaborador_filter,
        nombre: searchText,
      })
    );
  };

  const goToNuevoFeedback = () => {
    navigate("/feedback/configuracion");
  };

  const onEditFeedback = (item) => {
    if (item.feedback.por_jefe_directo) {
      navigate(`/feedback/configuracion/${item.feedback.id}`);
    } else {
      WarningToast({
        message: "Solo puedes editar los feedbacks que has creado.",
      });
    }
  };

  const onDeleteFeedback = (item) => {
    if (item.feedback.por_jefe_directo) {
      const deseaEliminar = window.confirm(
        "¿Esta seguro de eliminar este feedback?"
      );
      if (!!deseaEliminar) {
        dispatch(feedbackActions.deleteFeedbackNomina(item.id));
      }
    } else {
      WarningToast({
        message: "Solo puedes eliminar los feedbacks que has creado.",
      });
    }
  };

  const goToDetail = (item, feedbackVencido) => {
    if (!feedbackVencido) {
      navigate(
        `/feedback/${item.feedback.id}/jefe-directo/${item.jefe_directo.id}/colaborador/${item.colaborador.id}/jefe-directo`
      );
    }
  };

  const getEstado = (estado) => {
    const descripcion =
      estado === 3 ? "Feedback Finalizado" : "Pendiente Feedback";
    let turno = <div></div>;
    if (estado === 1) {
      turno = (
        <div className="text-sm text-sky-500 font-semibold">Jefe directo</div>
      );
    } else if (estado === 2) {
      turno = (
        <div className="text-sm text-yellow-500 font-semibold">Evaluado</div>
      );
    } else if (estado === 3) {
      turno = <div></div>;
    }
    return (
      <div>
        <div className="">{descripcion}</div>
        {turno}
      </div>
    );
  };

  const datatableConfigJefeDirecto = {
    headers: [
      {
        title: "Histórico de Feedback",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="">
              <div className="font-semibold">{item.feedback.nombre}</div>
              {item.feedback.fecha_inicio && item.feedback.fecha_fin && (
                <div>
                  <span className="text-sm">
                    {dayjs(item.feedback.fecha_inicio?.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    )}{" "}
                    al{" "}
                    {dayjs(item.feedback.fecha_fin?.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </div>
              )}
              <span className="text-sm">
                Fecha conversación:{" "}
                {item.fecha_conversacion &&
                  dayjs(item.fecha_conversacion?.slice(0, 10)).format(
                    "DD/MM/YYYY"
                  )}
              </span>
            </div>
          );
        },
      },
      {
        title: "Jefe directo",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="flex flex-row gap-x-2">
              {item.jefe_directo.foto_empleado && (
                <div className="flex items-center justify-center bg-slate-200 rounded-md h-10 w-10">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={item.jefe_directo.foto_empleado}
                    alt={`Perfil`}
                  />
                </div>
              )}
              {!item.jefe_directo.foto_empleado && (
                <ProfileImage className="h-10 w-10"></ProfileImage>
              )}
              <div className="flex flex-col">
                <div className="font-semibold">
                  {item.jefe_directo.nombre} {item.jefe_directo.apellido}
                </div>
                <div className="text-sm">
                  Cargo:{" "}
                  {item.jefe_directo.cargo
                    ? item.jefe_directo.cargo.nombre
                    : "Sin cargo"}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Colaborador",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="flex flex-row gap-x-2">
              {item.colaborador.foto_empleado && (
                <div className="flex items-center justify-center bg-slate-200 rounded-md h-10 w-10">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={item.colaborador.foto_empleado}
                    alt={`Perfil`}
                  />
                </div>
              )}
              {!item.colaborador.foto_empleado && (
                <ProfileImage className="h-10 w-10"></ProfileImage>
              )}
              <div className="flex flex-col">
                <div className="font-semibold">
                  {item.colaborador.nombre} {item.colaborador.apellido}
                </div>
                <div className="text-sm">
                  Cargo:{" "}
                  {item.colaborador.cargo
                    ? item.colaborador.cargo.nombre
                    : "Sin cargo"}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Estado",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        //accessor: "nombre",
        contentRenderer: (item) => {
          return getEstado(item.estado);
        },
      },
      {
        title: "",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        //accessor: "nombre",
        contentRenderer: (item) => {
          const today = dayjs().startOf("day");
          const fecha_fin = dayjs(item.feedback?.fecha_fin);
          const feedbackVencido = today.isAfter(fecha_fin);
          return (
            <div className={`${!feedbackVencido ? "cursor-pointer" : ""}`}>
              <ChevronRight
                color={`${!feedbackVencido ? "#0ea4e9" : "gray"}`}
                onClick={() => goToDetail(item, feedbackVencido)}
              ></ChevronRight>
            </div>
          );
        },
      },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          onEditFeedback(item);
        },
      },
      {
        label: "Eliminar",
        action: (item) => {
          onDeleteFeedback(item);
        },
      },
    ],
  };

  const datatableConfigColaborador = {
    headers: [
      {
        title: "Histórico de Feedback",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="">
              <div className="font-semibold">{item.feedback.nombre}</div>
              {item.feedback.fecha_inicio && item.feedback.fecha_fin && (
                <div>
                  <span className="text-sm">
                    {dayjs(item.feedback.fecha_inicio?.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    )}{" "}
                    al{" "}
                    {dayjs(item.feedback.fecha_fin?.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </div>
              )}
              <span className="text-sm">
                Fecha conversación:{" "}
                {item.fecha_conversacion &&
                  dayjs(item.fecha_conversacion?.slice(0, 10)).format(
                    "DD/MM/YYYY"
                  )}
              </span>
            </div>
          );
        },
      },
      {
        title: "Jefe directo",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="flex flex-row gap-x-2">
              {item.jefe_directo.foto_empleado && (
                <div className="flex items-center justify-center bg-slate-200 rounded-md h-10 w-10">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={item.jefe_directo.foto_empleado}
                    alt={`Perfil`}
                  />
                </div>
              )}
              {!item.jefe_directo.foto_empleado && (
                <ProfileImage className="h-10 w-10"></ProfileImage>
              )}
              <div className="flex flex-col">
                <div className="font-semibold">
                  {item.jefe_directo.nombre} {item.jefe_directo.apellido}
                </div>
                <div className="text-sm">
                  Cargo:{" "}
                  {item.jefe_directo.cargo
                    ? item.jefe_directo.cargo.nombre
                    : "Sin cargo"}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Colaborador",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="flex flex-row gap-x-2">
              {item.colaborador.foto_empleado && (
                <div className="flex items-center justify-center bg-slate-200 rounded-md h-10 w-10">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={item.colaborador.foto_empleado}
                    alt={`Perfil`}
                  />
                </div>
              )}
              {!item.colaborador.foto_empleado && (
                <ProfileImage className="h-10 w-10"></ProfileImage>
              )}
              <div className="flex flex-col">
                <div className="font-semibold">
                  {item.colaborador.nombre} {item.colaborador.apellido}
                </div>
                <div className="text-sm">
                  Cargo:{" "}
                  {item.colaborador.cargo
                    ? item.colaborador.cargo.nombre
                    : "Sin cargo"}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Estado",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        //accessor: "nombre",
        contentRenderer: (item) => {
          return getEstado(item.estado);
        },
      },
      {
        title: "",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        //accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <div className="cursor-pointer">
              <ChevronRight
                color="#0ea4e9"
                onClick={() => goToDetail(item)}
              ></ChevronRight>
            </div>
          );
        },
      },
    ],
    rowActions: [
      // {
      //   label: "Eliminar",
      //   action: (item) => {
      //     onDeleteFeedback(item.id);
      //   },
      // },
    ],
  };

  const [
    outerPageSizeFeedbacksJefeDirecto,
    setOuterPageSizeFeedbacksJefeDirecto,
  ] = useState(50);
  const onChangePageSizeJefeDirecto = (e) => {
    let value = e.target.value;
    //
    dispatch(
      feedbackActions.setFeedbacksJefeDirectoFilter({
        ...feedbacks_jefe_directo_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSizeFeedbacksJefeDirecto(parseInt(value));
  };

  const [
    outerPageSizeFeedbacksColaborador,
    setOuterPageSizeFeedbacksColaborador,
  ] = useState(50);
  const onChangePageSizeColaborador = (e) => {
    let value = e.target.value;
    //
    dispatch(
      feedbackActions.setFeedbacksColaboradorFilter({
        ...feedbacks_colaborador_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSizeFeedbacksColaborador(parseInt(value));
  };

  const onPaginateJefeDirecto = (page, pageSize) => {
    //
    dispatch(
      feedbackActions.setFeedbacksJefeDirectoFilter({
        ...feedbacks_jefe_directo_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onPaginateColaborador = (page, pageSize) => {
    //
    dispatch(
      feedbackActions.setFeedbacksColaboradorFilter({
        ...feedbacks_colaborador_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  return (
    <div className="w-full">
      <Header titulo="Feedback" />
      <div className="flex flex-col gap-4 px-12 py-4 w-full">
        {subordinados_count > 0 && (
          <Card className="gap-4 p-4">
            <h2 className="font-semibold text-lg border-b pb-2">
              Feedbacks que debes realizar
            </h2>
            <div className="flex flex-row justify-between gap-3">
              <SearchInput
                containerClass={``}
                placeholder={"Buscar por nombre del colaborador o feedback..."}
                value={
                  feedbacks_jefe_directo_filter.nombre
                    ? feedbacks_jefe_directo_filter.nombre
                    : ""
                }
                onChange={onChangeTextJefeDirecto}
              ></SearchInput>
              <MostrarPaginas
                containerClass={"w-1/2x"}
                onChangePageSize={onChangePageSizeJefeDirecto}
                defaultOption={50}
              />
              <button
                className="bg-sky-500 text-white px-4 py-2 rounded-md"
                onClick={goToNuevoFeedback}
              >
                Nuevo Feedback
              </button>
            </div>
            <div className="max-h-[600px] custom-scrollbar overflow-auto mt-4">
              <DataTable
                containerClass={""}
                data={feedbacks_jefe_directo}
                loading={feedbacks_jefe_directo_loading}
                config={datatableConfigJefeDirecto}
                noHeaders={false}
                //
                totalRecords={feedbacks_jefe_directo_count}
                onPaginate={onPaginateJefeDirecto}
                outerPageSize={outerPageSizeFeedbacksJefeDirecto}
                //
                checkable={false}
                // checkedItems={cargosCheckedLocal}
                // onItemCheck={onCheckCargoLocal}
                // onCheckAll={onCheckAll}
              />
            </div>
          </Card>
        )}

        <Card className="gap-4 p-4">
          <h2 className="font-semibold text-lg border-b pb-2">
            Comentarios de Feedbacks que debes realizar
          </h2>
          <div className="flex flex-row justify-between gap-3">
            <SearchInput
              containerClass={``}
              placeholder={"Buscar por nombre de Feedback..."}
              value={
                feedbacks_colaborador_filter.nombre
                  ? feedbacks_colaborador_filter.nombre
                  : ""
              }
              onChange={onChangeTextColaborador}
            ></SearchInput>
            <MostrarPaginas
              containerClass={"w-1/2x"}
              onChangePageSize={onChangePageSizeColaborador}
              defaultOption={50}
            />
          </div>
          <div className="max-h-[600px] overflow-auto custom-scrollbar mt-4">
            <DataTable
              containerClass={""}
              data={feedbacks_colaborador}
              loading={feedbacks_colaborador_loading}
              config={datatableConfigColaborador}
              noHeaders={false}
              //
              totalRecords={feedbacks_colaborador_count}
              onPaginate={onPaginateColaborador}
              outerPageSize={outerPageSizeFeedbacksColaborador}
              //
              checkable={false}
              // checkedItems={cargosCheckedLocal}
              // onItemCheck={onCheckCargoLocal}
              // onCheckAll={onCheckAll}
            />
          </div>
        </Card>
      </div>
    </div>
  );
});
