import { call, put, takeEvery } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { inicioApi } from "./api";
import { inicioActions } from "./redux";

function* iniciosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(inicioApi(token).list);
    yield put(inicioActions.setInicios({ inicios: data }));
  } catch (error) {
    console.log({ error });
  }
}

export function* inicioSagas() {
  // list
  yield takeEvery(inicioActions.inicios.type, iniciosSaga);
}
