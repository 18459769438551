import React from "react";
import { Route, Routes } from "react-router-dom";
import EvaluacionesPage from "src/modules/evaluacion/pages/EvaluacionesPage";
import ColaboradorInformePage from "../pages/ColaboradorInformePage";
import EvaluacionDetailPage from "../pages/EvaluacionDetailPage";
import FormularioCalibracionPage from "../pages/FormularioCalibracionPage";
import FormularioComentarioPage from "../pages/FormularioComentarioPage";
import FormularioDevolucionPage from "../pages/FormularioDevolucionPage";
import FormularioEvaluacionPage from "../pages/FormularioEvaluacionPage";
import FormularioFinalizadoPage from "../pages/FormularioFinalizadoPage";
import FormularioPage from "../pages/FormularioPage";

export const evaluacionRoutes = [
  {
    path: "/evaluaciones/",
    element: <EvaluacionesPage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion",
    element: <EvaluacionDetailPage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/formulario/:idFormulario/*",
    element: <FormularioPage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/colaborador/:idColaborador",
    element: <ColaboradorInformePage />,
  },
];

export const FormularioRoutes = () => {
  const formularioRoutes = [
    {
      path: "/evaluacion",
      element: <FormularioEvaluacionPage />,
    },
    {
      path: "/calibracion",
      element: <FormularioCalibracionPage />,
    },
    {
      path: "/devolucion",
      element: <FormularioDevolucionPage />,
    },
    {
      path: "/comentario",
      element: <FormularioComentarioPage />,
    },
    {
      path: "/finalizado",
      element: <FormularioFinalizadoPage />,
    },
  ];
  return (
    <Routes>
      {formularioRoutes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};
