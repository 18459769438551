import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { evaluacionActions } from "../handlers/redux";
import { getEstadoLabel } from "../helpers/helpers";
import FormulariosTable from "./FormulariosTable";

function ComentariosTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;
  const comentarios = useSelector(
    (state) => state.evaluacion.comentarios_lider
  );
  const comentarios_loading = useSelector(
    (state) => state.evaluacion.comentarios_lider_loading
  );
  const comentarios_filter = useSelector(
    (state) => state.evaluacion.comentarios_lider_filter
  );
  const { nombre = "" } = comentarios_filter;
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = comentarios_filter;
      ////
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      console.info("S==== setOrdering", ordering, orderingArray, fieldIndex);
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        evaluacionActions.setComentariosLiderFilter({
          ...comentarios_filter,
          id: idEvaluacion,
          ordering: ordering,
        })
      );
    };
  const headers = [
    { text: "Colaborador", onOrder: false, onFilter: false },

    {
      text: "Relación",
      onOrder: setOrdering("relacion"),
    },
    {
      text: "Formulario",
      onOrder: setOrdering("configuracion__nombre"),
    },
    {
      text: "Puntaje",
      onOrder: setOrdering("puntaje_obtenido"),
    },
    // {
    //   text: "Estado",
    //   onOrder: setOrdering("estado"),
    // },
    { text: "Acción", onOrder: setOrdering("estado"), onFilter: false },
  ];
  const onChangeText = (event) => {
    dispatch(
      evaluacionActions.setComentariosLiderFilter({
        id: idEvaluacion,
        nombre: event.target.value,
      })
    );
  };
  const renderActionButton = (formulario) => {
    const { estado } = formulario;
    const label = getEstadoLabel(estado);
    //
    if (estado === formulario.estado_para_comentario) {
      return (
        <>
          <button
            onClick={(e) => {
              // e.stopPropagation();
            }}
            className="bg-sky-500 text-white py-1 px-2 border rounded-md border-black text-sm"
          >
            Comentarios
          </button>
        </>
      );
    }
    return (
      <>
        <div className="py-1 px-2 bg-slate-200 border rounded-md border-black text-sm">
          {label}
        </div>
      </>
    );
  };
  const goToComentario = (formulario) => (e) => {
    const { estado } = formulario;
    const idFormulario = formulario.id;
    const evaluarRoute = `/evaluaciones/${idEvaluacion}/formulario/${idFormulario}/comentario`;
    if (estado === formulario.estado_para_comentario) {
      navigate(evaluarRoute);
    }
  };
  //
  useEffect(() => {
    dispatch(evaluacionActions.getComentariosLider({ id: idEvaluacion }));
  }, []);
  //
  return (
    <FormulariosTable
      data={comentarios}
      headers={headers}
      extraClass={"mt-8"}
      searchValue={nombre}
      onSearch={onChangeText}
      loading={comentarios_loading}
      renderActionButton={renderActionButton}
      onRowClick={goToComentario}
    ></FormulariosTable>
  );
}
export default ComentariosTable;
